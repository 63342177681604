import { TConnectedUser } from '../../../../types/jmrTypes';
import Tooltip from '../../../Util/Tooltip';
import { useAppSelector } from '../../../../hooks/hooks';

interface UserAvatarProps {
  user: TConnectedUser;
}

const UserAvatar = ({ user: { fname, lname, color, email } }: UserAvatarProps) => {
  const { email: currentUser } = useAppSelector((state) => state.auth);

  const isYou = () => {
    if (email === currentUser) return '(you)';
  };

  return (
    <div
      className='user_avatar'
      style={{
        backgroundColor: `${color}80`,
      }}>
      {email ? (
        fname.substring(0, 1).toLocaleUpperCase()
      ) : (
        <span className='material-icons anon_user'>person_outline</span>
      )}
      <Tooltip>
        <h5>
          {`${fname} ${lname}`} {isYou()}
        </h5>
        {email ? <span>{email}</span> : <span>Anonymous User</span>}
      </Tooltip>
    </div>
  );
};

export default UserAvatar;

interface ExtraUsersAvatarProps {
  extraUsers: TConnectedUser[];
}

export const ExtraUsersAvatar = ({ extraUsers }: ExtraUsersAvatarProps) => {
  const numUsers = extraUsers.length;
  return (
    <div className='user_avatar extra_users_avatar'>
      <span>+{numUsers}</span>
      <Tooltip>
        <div className='extra_users_tooltip'>
          {extraUsers.map(({ fname, lname, email, color }, i) => (
            <>
              <div className='extra_users_entry'>
                <div className='extra_users_color_wrapper'>
                  <div
                    className='extra_users_color'
                    style={{
                      backgroundColor: color,
                    }}
                  />
                </div>
                <div className='extra_users_data'>
                  <h5>{`${fname} ${lname}`}</h5>
                  <span>{email}</span>
                </div>
              </div>
              {i !== numUsers - 1 && <hr className='tooltip_hr' />}
            </>
          ))}
        </div>
      </Tooltip>
    </div>
  );
};
