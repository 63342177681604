//including code written by Eric Meier from the slate-yjs-example
// @refresh reset
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { createEditor, Descendant, Editor, Node } from 'slate';
import { withReact } from 'slate-react';
import { withCursors, withYHistory, withYjs, YjsEditor } from '@slate-yjs/core';
import * as encoding from 'lib0/encoding';
import { isReady, MESSAGE_AWARENESS_DATA, WebsocketProvider } from '../../../utils/y-websocket';
import * as Y from 'yjs';
import { WEBSOCKET_ENDPOINT } from '../../../utils/constants';
import JmrEditor from '../Slate/JmrEditor';
import { JmrContext } from './JMR';
import { CustomEditor, JmrTitleElement, ObjectField, ObjectType, TextElement } from '@jambr/collab-util';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getElementMetaContainer } from '../Slate/util';
import { TParticipant } from '../../../types/participantTypes';
import { updatePreview } from '../../../store/previewSlice';
import _ from 'lodash';
import withShortcuts from '../plugins/withShortcuts';
import randomColor from 'randomcolor';
import { updateParticipant } from '../../../store/participantSlice';
import store from '../../..';
import { eMessage } from '../../../types/errorTypes';
import { setError } from '../../../store/errorSlice';
import { withCopyPaste } from '../plugins/withCopyPaste';
import withLists from '../plugins/withLists';
import withPersistence from '../plugins/withPersistence';

export const YjsSocketContext = createContext<WebSocket>({} as WebSocket);
export const EDITOR_NODE_LENGTH = 10;

const Client = () => {
  const { email, fname, lname } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { currentParticipant, jmrId, shareId } = useContext(JmrContext);
  const [value, setValue] = useState<Descendant[]>([]);
  // If the current participant is an empty object, the user may be attempting to connect with a shareId.
  // In this case, certain features will be turned off (like real-time updates to JMR previews)
  const isCurrentParticipantEmpty = Object.keys(currentParticipant).length === 0;

  const awarenessData = useMemo(
    () => {
      const color = randomColor({
        luminosity: 'dark',
        format: 'hex',
        alpha: 1,
      });

      if (!email) {
        const adjectiveIndex = Math.floor(Math.random() * AnonymousAdjectives.length);
        const animalIndex = Math.floor(Math.random() * AnonymousAnimals.length);
        return {
          email,
          color,
          fname: AnonymousAdjectives[adjectiveIndex],
          lname: AnonymousAnimals[animalIndex],
        };
      }

      return {
        email,
        color,
        fname,
        lname,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const provider: WebsocketProvider = useMemo(() => {
    const doc = new Y.Doc();
    // Provider should attempt to connect using the shareId for auth if the current participant is an empty object
    return new WebsocketProvider(
        WEBSOCKET_ENDPOINT,
        jmrId,
        doc,
        shareId,
        isCurrentParticipantEmpty
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jmrId]);

  const editor: CustomEditor = useMemo(() => {
    const sharedType = provider.doc.get('content', Y.XmlText) as Y.XmlText;

    return withPersistence(
      withCopyPaste(
        withLists(
          withShortcuts(
            withReact(
              withYHistory(
                withCursors(withYjs(createEditor(), sharedType), provider.awareness, {
                  data: awarenessData,
                })
              )
            )
          )
        )
      )
    );
  }, [provider.awareness, provider.doc, awarenessData]);

  // Disconnect YjsEditor on unmount in order to free up resources
  useEffect(() => {
    return () => {
      YjsEditor.disconnect(editor);
      return;
    };
  }, [editor]);

  useEffect(() => {
    provider.awareness.setLocalState({
      color: awarenessData.color,
      name: fname,
    });

    provider.on('synced', () => {
      if (provider.ws && isReady(provider.ws)) {
        const encoder = encoding.createEncoder();
        encoding.writeVarUint(encoder, MESSAGE_AWARENESS_DATA);
        encoding.writeAny(encoder, awarenessData);
        provider.ws.send(encoding.toUint8Array(encoder));
      }
    });

    // disable right-click on editor DOM document.
    const handleRightClick = (event: MouseEvent) => {
      event.preventDefault();
    };
    // Bind the event listener
    document.addEventListener('contextmenu', handleRightClick);

    return () => {
      provider.disconnect();
      return;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateNodeArray = (newVal: Descendant[]) => {
    //catch any unhandled double doc occurrences or possibly other slate errors.
    if (newVal.length > EDITOR_NODE_LENGTH) {
      dispatch(setError(eMessage.unknownDD));
    }
    //do not render on initial doc creation
    if (newVal.length !== EDITOR_NODE_LENGTH) return;

    setValue(newVal);
    syncPreview();
  };

  // This may need to be moved to a useDebounce to improve performance.
  const syncPreview = () => {
    // If the current participant is empty, the user is connected via shareId and there is no preview to update
    if (isCurrentParticipantEmpty) return;

    const titleNodeEntry = Array.from(
      Editor.nodes<JmrTitleElement>(editor, {
        at: [],
        match: (node: any) =>
          node.artifactId &&
          node.artifactId.type === ObjectType.Jmr &&
          node.artifactId.field === ObjectField.Title,
      })
    )[0];
    const [metaContainerEl] = getElementMetaContainer(editor, []);
    const { startDate, location } = metaContainerEl.artifactMetaData;
    const jmrPurposeEntry = Array.from(
      Editor.nodes<TextElement>(editor, {
        at: [],
        match: (node: any) =>
          node.artifactId &&
          node.artifactId.type === ObjectType.Jmr &&
          node.artifactId.field === ObjectField.Purpose,
      })
    )[0];
    if (!titleNodeEntry || !jmrPurposeEntry) return;

    const title = Node.string(titleNodeEntry[0]);
    const purpose = JSON.stringify(jmrPurposeEntry[0].children);

    const updatedParticipant: TParticipant = {
      ...currentParticipant,
      title,
      startDate: startDate ? startDate : '',
      location: location ? location : '',
      purpose,
      id: currentParticipant.id,
    };

    // Get the currentPreview directly from the store without hooks as we only need it
    // for this check and this component does not need to rerender when it changes
    const { entities } = store.getState().previews;
    const currentPreview = entities[jmrId];

    if (currentPreview && !_.isEqual(currentPreview, updatedParticipant)) {
      dispatch(updatePreview(updatedParticipant));
    }

    if (currentParticipant.location !== updatedParticipant.location) {
      dispatch(updateParticipant(updatedParticipant));
    }
  };

  const throwErrors = () => {
    if (value.length > EDITOR_NODE_LENGTH) {
      throw new Error(
        `Unexpected document structure: too many nodes (${value.length}):\n ${JSON.stringify(
          value,
          null,
          2
        )}`
      );
    }
  };

  return (
    <YjsSocketContext.Provider value={provider.ws!}>
      {throwErrors()}
      <JmrEditor editor={editor} value={value} onChange={updateNodeArray} />
    </YjsSocketContext.Provider>
  );
};

export default Client;

const AnonymousAnimals = [
  'Leopard',
  'Cricket',
  'Falcon',
  'Bear',
  'Cobra',
  'Goat',
  'Snail',
  'Elephant',
  'Shark',
  'Zebra',
  'Amoeba',
  'Moose',
  'Porcupine',
  'Raccoon',
  'Crocodile',
  'Stork',
  'Loon',
  'Goose',
  'Gorilla',
  'Spider',
  'Scorpion',
  'Fungi',
  'Whale',
  'Sea Lion',
  'Seahorse',
  'Armadillo',
  'Alligator',
  'Triceratops',
  'Brontosaur',
  'Gecko',
  'Cow',
  'Rooster',
  'Crawdad',
  'Termite',
  'Squid',
  'Quahog',
  'Pterodactyl',
  'Albatross',
  'Opossum',
  'Raven',
  'Giraffe',
  'Starfish',
  'Mantis',
  'Aardvark',
];

const AnonymousAdjectives = [
  'Ginormous',
  'Eloquent',
  'Astute',
  'Incontestable',
  'Undefeated',
  'Proud',
  'Keen',
  'Inquisitive',
  'Prepared',
  'Muscular',
  'Extreme',
  'Dignified',
  'Worldly',
  'Sensitive',
  'Intense',
  'Flying',
  'Sprinting',
  'Exquisite',
  'Leaping',
  'Voracious',
  'Organized',
  'Focused',
  'Friendly',
  'Studious',
  'Unscrupulous',
  'All-knowing',
  'Well-mannered',
  'Quirky',
  'Remarkable',
  'Sharp',
  'Rebellious',
  'Jubilant',
  'Tolerant',
  'Unrelenting',
  'Innovative',
  'Suave',
  'Zesty',
  'Captivating',
  'Omniscient',
  'Courageous',
  'Dependable',
  'Prancing',
];
