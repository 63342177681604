import { useContext } from 'react';
import { RenderElementProps, useSlateStatic } from 'slate-react';
import { JmrPermissions } from '../../../../types/participantTypes';
import { JmrContext } from '../../JMR/JMR';
import { insertActionItem } from './util';
import Tooltip from '../../../Util/Tooltip';
import {
  ActionItemElement,
  ActionListElement,
  CustomElementType,
  ItemStatus,
} from '@jambr/collab-util';

interface Props {
  element: ActionListElement;
}

const ActionList = ({ children, element }: RenderElementProps & Props) => {
  const { permissions } = useContext(JmrContext);
  const editor = useSlateStatic();

  const isEmptyList = () => {
    return !element.children.some(
      (actionItem: ActionItemElement) =>
        actionItem.type === CustomElementType.ActionItem &&
        actionItem.itemStatus !== ItemStatus.Deleted
    );
  };

  return (
    <div className='action_list'>
      <div className='list_header' contentEditable={false}>
        <h2 className='jmr_header'>Action Items</h2>
        {permissions !== JmrPermissions.READ && (
          // Only show the button to create new agenda items to participants with
          // permission to do so
          <div
            className='list_add_wrapper list_add action_add'
            onClick={() => insertActionItem(editor)}>
            <span contentEditable={false} style={{ userSelect: 'none' }} className='material-icons'>
              add_task
              <Tooltip tooltip='add action item' />
            </span>
          </div>
        )}
      </div>
      {isEmptyList() && (
        <div className='placeholder_element' contentEditable={false}>
          Your action items will appear here
        </div>
      )}
      {children}
    </div>
  );
};

export default ActionList;
