import { TActionItem } from './actionItemTypes';
import { TAgendaItem } from './agendaTypes';
import { TUtterance } from './transcriptTypes';
import { TParticipant } from './participantTypes';

export interface TJmrApi {
  id: string;
  owner: string;
  purpose: string;
  startDate: string;
  duration: string;
  actualDuration: string;
  location: string;
  title: string;
  meetingStatus: string;
  actionItems?: TActionItem[];
  agendaItems?: TAgendaItem[];
  notes: string;
  transcriptItems?: TUtterance[];
  participants?: TParticipant[];
}

export interface TJmrPreview {
  id: string;
  owner: string;
  purpose: string;
  startDate: string;
  duration: string;
  actualDuration: string;
  location: string;
  title: string;
  meetingStatus: string;
  // TODO: Should we have a notes prop here?
}

export interface TConnectedUser {
  email: string;
  fname: string;
  lname: string;
  color: string;
}

export enum IS_EXTERNAL {
  YES = 'Y',
  NO = 'N',
}
