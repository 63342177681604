import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { clearAuthError, setAuthError } from '../../store/authSlice';
import { STAGE } from '../../utils/constants';
import Form from '../Forms/Form';
import { TextInput } from '../Forms/Inputs';
import { EMAIL_VALIDATION, PW_VALIDATION } from '../Forms/_form_util';
import AccountTemplate from './AccountTemplate';

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAuthError());
  }, [dispatch]);
  const onSubmit = (data: {
    fname: string;
    lname: string;
    email: string;
    password: string;
    passwordConfirm: string;
  }) => {
    const { fname, lname, email, password } = data;
    setLoading(true);
    const trimmedEmail = email.replace('@', '-');
    Auth.signUp({
      username: trimmedEmail,
      password,
      attributes: {
        email,
        family_name: lname,
        given_name: fname,
        'custom:workspace': 'User',
        'custom:instance': STAGE,
      },
      autoSignIn: {
        enabled: true,
      },
    })
      .then(() => {
        history.push({ pathname: '/verify-code', search: `?email=${email}` });
      })
      .catch((err) => {
        dispatch(setAuthError(err));
        setLoading(false);
      });
  };

  return (
    <AccountTemplate>
      <Form onSubmit={onSubmit} showButton buttonText='SIGN UP' loading={loading}>
        <div className='adjacent_fields'>
          <TextInput
            name={'fname'}
            label={'first'}
            validation={{
              required: 'first name is required',
              maxLength: {
                value: 25,
                message: 'first name cannot exceed 25 characters',
              },
            }}
          />
          <TextInput
            name={'lname'}
            label={'last'}
            validation={{
              required: 'last name is required',
              maxLength: {
                value: 25,
                message: 'last name cannot exceed 25 characters',
              },
            }}
          />
        </div>
        <TextInput name={'email'} label={'email'} validation={EMAIL_VALIDATION} />
        <TextInput password name={'password'} label={'password'} validation={PW_VALIDATION} />
        <TextInput password name={'passwordConfirm'} label={'confirm password'} confirmPassword />
      </Form>
      <div className='account_links'>
        Have an account already? <Link to='/login'>Login</Link>
      </div>
    </AccountTemplate>
  );
};

export default SignUp;
