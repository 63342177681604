import React from 'react';

// Custom Button component that resembles a link
interface ButtonProps {
	onClick: () => void;
	text: string;
}
const ButtonAsLink = ({ onClick, text }: ButtonProps) => (
	<button className="button-as-link" onClick={onClick}>
		{text}
	</button>
);

export default ButtonAsLink;
