import React from 'react';
import { useAppSelector } from '../../hooks/hooks';

const ErrorNotification = () => {
  const { error } = useAppSelector((state) => state.auth);

  const calcStyle = () => {
    if (error.message) {
      return 'error_card show_error';
    } else {
      return 'error_card';
    }
  };

  return <div className={calcStyle()}>{error.message}</div>;
};

export default ErrorNotification;
