import { useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { postActionItem } from '../../../middlewares/actionRequests';
import { TActionItem, TActionStatus } from '../../../types/actionItemTypes';
import Tooltip from '../../Util/Tooltip';
import ActionItem from './ActionItem';

const ActionList = () => {
  const { fetchLoading, entities, ids, patchingId, patchedId, selectedId } = useAppSelector(
    (state) => state.actionItems
  );
  const { email } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const sortedEntities = useMemo(() => {
    const entityArray = ids.map((id) => entities[id]!);
    return entityArray.sort(sortComparer);
  }, [entities, ids]);
  const [showCompleted, setShowCompleted] = useState(false);

  const createActionItem = () => {
    dispatch(postActionItem({ assignee: email, id: v4() }));
  };

  const renderActionList = () => {
    const actionListElements: JSX.Element[] = [];

    let pastActionFound = false;
    let todayActionFound = false;
    let tomorrowActionFound = false;
    let laterActionFound = false;
    let noDueDateFound = false;
    let completedFound = false;

    // Get today's and tomorrow's dates for comparing
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    sortedEntities.forEach((actionItem) => {
      const { dueDate: dueDateString } = actionItem;
      const dueDate = new Date(dueDateString);
      dueDate.setHours(0, 0, 0, 0);

      if (actionItem.status === TActionStatus.Completed) {
        if (!completedFound) {
          completedFound = true;
          actionListElements.push(
            <h4 className='action_list_completed' key='completed'>
              Completed
              <div
                className='action_list_showhide'
                onClick={() => setShowCompleted(!showCompleted)}>
                <span className='material-icons'>
                  {showCompleted ? 'expand_more' : 'expand_less'}
                </span>
                <Tooltip
                  tooltip={showCompleted ? 'hide completed actions' : 'show completed actions'}
                />
              </div>
            </h4>
          );
        }
      } else if (dueDate < today && !pastActionFound) {
        // If this is meeting is in the past and the pastMeetingFound flag is
        // false, this meeting must be the first. Add a sticky header that says
        // "Past Meetings" and set the pastMeeting flag to true.

        pastActionFound = true;
        actionListElements.push(
          <h4 className='action_list_overdue' key='overdue'>
            Overdue
          </h4>
        );
      } else if (
        dueDate.getDate() === today.getDate() &&
        dueDate.getMonth() === today.getMonth() &&
        dueDate.getFullYear() === today.getFullYear() &&
        !todayActionFound
      ) {
        // If this is the first meeting with today's date
        // add a "Today's Meetings" header

        todayActionFound = true;
        actionListElements.push(<h4 key='today'>Due Today</h4>);
      } else if (
        dueDate.getDate() === tomorrow.getDate() &&
        dueDate.getMonth() === tomorrow.getMonth() &&
        dueDate.getFullYear() === tomorrow.getFullYear() &&
        !tomorrowActionFound
      ) {
        // If this is the first meeting with tomorrow's date,
        // add a "Tomorrow's Meetings" header
        tomorrowActionFound = true;
        actionListElements.push(<h4 key='tomorrow'>Due Tomorrow</h4>);
      } else if (dueDate > tomorrow && !laterActionFound) {
        // If this is the first meeting with tomorrow's date,
        // add a "Tomorrow's Meetings" header
        laterActionFound = true;
        actionListElements.push(<h4 key='later'>Due Later</h4>);
      } else if (!dueDateString && !noDueDateFound) {
        noDueDateFound = true;
        actionListElements.push(<h4 key='no_deadline'>No Deadline</h4>);
      }

      if (showCompleted || (!showCompleted && actionItem.status !== TActionStatus.Completed))
        actionListElements.push(
          <ActionItem
            key={actionItem.id}
            actionItem={actionItem}
            patched={patchedId === actionItem.id}
            patching={patchingId === actionItem.id}
            selected={selectedId === actionItem.id}
          />
        );
    });

    return actionListElements;
  };

  return (
    <div className='dash_action_list'>
      <div className='list_header' contentEditable={false}>
        <h2 className='jmr_header'>Action Items</h2>
        {fetchLoading ? (
          <div className='list_add_wrapper spin_loader' />
        ) : (
          <div className='list_add_wrapper list_add action_add' onClick={() => createActionItem()}>
            <span contentEditable={false} style={{ userSelect: 'none' }} className='material-icons'>
              add_task
              <Tooltip tooltip='add action item' />
            </span>
          </div>
        )}
      </div>
      {renderActionList()}
    </div>
  );
};

export default ActionList;

const statusPriority = {
  N: 3, // NotStarted
  I: 2, // InProgress
  C: 1, // Completed
};

const sortComparer = (a: TActionItem, b: TActionItem): number => {
  // Sort by status
  if (statusPriority[a.status] !== statusPriority[b.status]) {
    return statusPriority[b.status] - statusPriority[a.status];
  }

  // Sort by due date
  if (a.dueDate < b.dueDate) return -1;
  else if (a.dueDate > b.dueDate) return 1;

  // Sort by title
  if (a.title < b.title) return -1;
  else if (a.title > b.title) return 1;

  // If all else is equal
  return 0;
};
