import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { clearAuthError, setAuthError, setCurrentUser } from '../../store/authSlice';
import Form from '../Forms/Form';
import { TextInput } from '../Forms/Inputs';
import { EMAIL_VALIDATION, PW_VALIDATION } from '../Forms/_form_util';
import AccountTemplate from './AccountTemplate';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(clearAuthError());
    dispatch({
      type: 'CLEAR_APP_STATE',
    });
  }, [dispatch]);

  const onSubmit = (data: { email: string; password: string }) => {
    const { email, password } = data;
    setLoading(true);

    Auth.signIn(email, password)
      .then((user) => {
        const { email, given_name: fname, family_name: lname, name } = user.attributes;
        dispatch(
          setCurrentUser({
            fname: fname ? fname : name,
            lname,
            email,
          })
        );
        history.push('/home/actions');
      })
      .catch((err) => {
        dispatch(setAuthError(err));
        setLoading(false);
      });
  };

  return (
    <AccountTemplate>
      <Form showButton buttonText='LOGIN' onSubmit={onSubmit} loading={loading}>
        <TextInput name='email' label='email' validation={EMAIL_VALIDATION} />
        <TextInput name='password' label='password' password={true} validation={PW_VALIDATION} />
      </Form>
      <div className='account_links'>
        Don't have an account? <Link to='/signup'>Sign up</Link>
        <br />
        Forgot your password? <Link to='/forgot-password'>Reset it</Link>
      </div>
    </AccountTemplate>
  );
};

export default Login;
