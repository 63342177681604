import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { createBlock } from '@jambr/collab-util';
import { TParticipant } from '../types/participantTypes';
import { AxiosResponse } from "axios";

export const postJmrItems = createAsyncThunk('postJmrItems', async () => {
  let timezone: string;
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (e) {
    console.error("Error getting the timezone.", e);
    timezone = "UTC";
  }
  const response: AxiosResponse = await axiosInstance.post('/JmrItems', {
    startDate: new Date().toISOString(),
    timezone: encodeURIComponent(timezone),
    purpose: encodeURIComponent(JSON.stringify([createBlock('')])),
    notes: encodeURIComponent(JSON.stringify([createBlock('')])),
  });
  return response.data;
});

export const deleteJmr = createAsyncThunk(
  'deleteJmr',
  async (args: { jmrId: string; jmrParticipants: TParticipant[] }) => {
    const { jmrId } = args;
    const response = await axiosInstance.delete(`/Jmr/${jmrId}`);
    return response.data;
  }
);
