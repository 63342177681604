import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import SubmitButton from './SubmitButton';

interface Props {
  children: React.ReactNode;
  onSubmit: (data: any) => any;
  buttonText?: string;
  showButton: boolean;
  loading: boolean;
}

const Form = (props: Props) => {
  const { children, onSubmit, buttonText, showButton, loading } = props;
  const methods = useForm();

  const renderButton = () => {
    if (showButton && buttonText) {
      return (
        <SubmitButton
          loading={loading}
          buttonText={buttonText}
          onClick={methods.handleSubmit(onSubmit)}
        />
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
        {renderButton()}
      </form>
    </FormProvider>
  );
};

export default Form;
