import { useMemo } from 'react';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { isEmptyTextField, ObjectField, ObjectType } from '@jambr/collab-util';
import { TextElement } from '@jambr/collab-util';
import RestrictedElement from '../Slate/RestrictedElement';

interface Props {
  element: TextElement;
}

const TextField = ({ attributes, element, children }: RenderElementProps & Props) => {
  const editor = useSlateStatic();
  const isEmpty = useMemo(
    () => isEmptyTextField(ReactEditor.findPath(editor, element), editor),
    [element, editor]
  );

  const isAgendaTitle =
    element.artifactId.type === ObjectType.Agenda && element.artifactId.field === ObjectField.Title;

  return (
    <RestrictedElement
      element={
        <div {...attributes} className={isAgendaTitle ? 'jmr_field agenda_title' : 'jmr_field'}>
          {children}
          {isEmpty && (
            <div
              contentEditable={false}
              style={{
                userSelect: 'none',
              }}
              className='placeholder_base placeholder_field'>
              {element.placeholder}
            </div>
          )}
        </div>
      }
    />
  );
};

export default TextField;
