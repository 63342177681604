import { Editor, Range, Transforms } from 'slate';
import store from '../../../..';
import {
  ActionListElement,
  createActionItemElement,
  CustomEditor,
  CustomElementType,
  ItemStatus,
} from '@jambr/collab-util';
import { getTextField } from '../../Slate/util';

export const insertActionItem = (editor: CustomEditor) => {
  const [actionListEntry] = Editor.nodes<ActionListElement>(editor, {
    at: [],
    match: (node: any) => node.type === CustomElementType.ActionList,
  });
  if (!actionListEntry) return;
  const actionListPath = actionListEntry[1];

  // If there is a valid selection, get the text from it so we can insert it
  // into the action item
  let title;
  const { selection } = editor;
  if (selection && Range.isExpanded(selection)) {
    const textNode = getTextField(editor);
    if (textNode) {
      title = Editor.string(editor, selection);
    }
  }

  // Get the current user
  const { email: currentUser } = store.getState().auth;

  // Insert a new action item node
  Transforms.insertNodes(
    editor,
    createActionItemElement(ItemStatus.Created, {
      title,
      defaultAssignee: currentUser,
    }),
    {
      at: [...actionListPath, 0],
    }
  );

  // Place the cursor on the title of the new item
  const [start] = Editor.edges(editor, actionListPath);
  Transforms.select(editor, {
    path: start.path,
    offset: title ? title.length : 0,
  });
};
