import { useEffect, useRef } from 'react';

export default function useConditionalInterval(
  startCondition: boolean,
  callback: () => any,
  delay: number
) {
  const savedCallback = useRef(() => null);
  const id = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (startCondition) {
      id.current = setInterval(tick, delay);

      return () => {
        if (id.current) clearInterval(id.current);
      };
    }
  }, [startCondition, delay]);
}
