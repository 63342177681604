import React from 'react';

type Props = {
  error: Error;
  errorInfo: React.ErrorInfo;
};

const AppError = ({ error, errorInfo }: Props) => {
  // You can render any custom fallback UI
  return (
    <div className='error_app'>
      <section>
        <h1 className='error_uhoh'>Uh oh... :( </h1>
        <div className='error_details'>
          <span className='error_text'>
            Something went wrong on our end.
            <br />
            Refreshing will probably fix it!
          </span>
          <details
            style={{
              whiteSpace: 'pre-wrap',
            }}>
            <summary>Details</summary>
            <br />
            {error && error.toString()}
            {errorInfo.componentStack}
          </details>
        </div>
      </section>
    </div>
  );
};

export default AppError;
