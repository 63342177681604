import _ from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { TActionStatus } from '../../../../types/actionItemTypes';
import { JmrPermissions } from '../../../../types/participantTypes';
import { JmrContext } from '../../JMR/JMR';
import { isEmptyTextField, ActionItemElement, CustomEditor } from '@jambr/collab-util';
import { deleteStatusElement, getElementMetaContainer } from '../../Slate/util';

interface Props {
  element: ActionItemElement;
}

const ActionItem = ({ element, children }: RenderElementProps & Props) => {
  const editor: CustomEditor = useSlateStatic();
  const { permissions } = useContext(JmrContext);
  const { selection } = editor;

  const elementPath = ReactEditor.findPath(editor, element);

  const descriptionEmpty = useMemo(
    () => isEmptyTextField([...elementPath, 0], editor),
    [elementPath, editor]
  );

  const [metaContainerEl] = getElementMetaContainer(editor, elementPath);
  const { artifactMetaData } = metaContainerEl;

  useEffect(() => {
    if (selection) {
      // Determine which css classes should be applied based on which
      // action item is currently selected.
      const pathToSelectionParent = selection.anchor.path.slice(0, 2);
      const actionElement = document.getElementById(element.id);

      if (_.isEqual(elementPath, pathToSelectionParent)) {
        actionElement?.classList.add('action_selected');
      } else {
        actionElement?.classList.remove('action_selected');
      }
    }
  }, [selection, element, elementPath]);

  const handleClickComplete = () => {
    if (descriptionEmpty) return;
    const elementPath = ReactEditor.findPath(editor, element);
    const [metaContainerEl, metaContainerLoc] = getElementMetaContainer(editor, elementPath);
    const { artifactMetaData } = metaContainerEl;
    switch (artifactMetaData.status) {
      case TActionStatus.NotStarted:
        Transforms.setNodes(
          editor,
          {
            artifactMetaData: {
              ...artifactMetaData,
              status: TActionStatus.Completed,
            },
          },
          {
            at: metaContainerLoc,
          }
        );
        break;
      default:
        Transforms.setNodes(
          editor,
          {
            artifactMetaData: {
              ...artifactMetaData,
              status: TActionStatus.NotStarted,
            },
          },
          {
            at: metaContainerLoc,
          }
        );
        break;
    }
  };

  const renderByPerms = () => {
    if (permissions !== JmrPermissions.READ) {
      // If the current user is not a read-only participant, they should
      // be able to delete/clear, edit, and mark complete
      return (
        <div id={element.id} className='action_item'>
          <div
            contentEditable={false}
            style={{
              userSelect: 'none',
            }}
            className={
              descriptionEmpty
                ? 'action_button_base action_button_disabled noclick'
                : artifactMetaData.status === TActionStatus.Completed
                ? 'action_button_base action_button_active action_button_complete action_button_hover pointer'
                : 'action_button_base action_button_active action_button_hover pointer'
            }
            onClick={handleClickComplete}
          />
          {children}
          <span
            contentEditable={false}
            style={{ userSelect: 'none' }}
            className='material-icons delete_button'
            onClick={() => deleteStatusElement(editor, element)}>
            delete_outline
          </span>
        </div>
      );
    } else {
      // If the current user is a read-only participant, they should not
      // be able to delete/clear the action item, edit it, or mark it complete
      return (
        <div
          contentEditable={false}
          style={{
            userSelect: 'none',
          }}
          id={element.id}
          className='action_item'>
          <div
            contentEditable={false}
            style={{
              userSelect: 'none',
            }}
            className={
              descriptionEmpty
                ? 'action_button_base action_button_disabled noclick'
                : artifactMetaData.status === TActionStatus.Completed
                ? 'action_button_base action_button_active action_button_complete noclick'
                : 'action_button_base action_button_active noclick'
            }
          />
          {children}
        </div>
      );
    }
  };

  return renderByPerms();
};

export default ActionItem;
