export interface TUtterance {
  id: string;
  startDate: string;
  dialogue: string;
  partial: string;
  speaker?: string;
  endDate?: string;
  jmrId?: string;
  agendaItemId?: string;
}

export enum TranscriptState {
  Connected = 'CONNECTED',
  SubscribedToStatus = 'SUBSCRIBED',
  Authenticating = 'AUTHENTICATING',
  Authorizing = 'AUTHORIZING',
  Launching = 'LAUNCHING',
  Launched = 'LAUNCHED',
  Starting = 'STARTING',
  Started = 'STARTED',
  Stopping = 'STOPPING',
  Stopped = 'STOPPED',
  Disconnected = 'DISCONNECTED',
  Errored = 'ERROR',
  Resetting = 'RESETTING',
  Paused = 'PAUSED',
  Pausing = 'PAUSING',
  Transcribing = 'TRANSCRIBING',
  Terminated = 'TERMINATED',
}

export enum TranscriptButtonType {
  Launch,
  Start,
  Stop,
  Loading,
  Error,
}

export const getTranscriptButtonType = (status: TranscriptState) => {
  switch (status) {
    // Error state
    case TranscriptState.Errored:
      return TranscriptButtonType.Error;
    // Launchable state
    case TranscriptState.SubscribedToStatus:
    case TranscriptState.Terminated:
    case TranscriptState.Connected:
      return TranscriptButtonType.Launch;
    // Startable state
    case TranscriptState.Launched:
    case TranscriptState.Stopped:
      return TranscriptButtonType.Start;
    // Stopable state
    case TranscriptState.Started:
      return TranscriptButtonType.Stop;
    // Loading state
    case TranscriptState.Disconnected:
    case TranscriptState.Authenticating:
    case TranscriptState.Authorizing:
    case TranscriptState.Launching:
    case TranscriptState.Starting:
    case TranscriptState.Stopping:
    case TranscriptState.Resetting:
      return TranscriptButtonType.Loading;
  }
};
