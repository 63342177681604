/* eslint-env browser */

import * as Y from 'yjs'; // eslint-disable-line
import * as bc from 'lib0/broadcastchannel';
import * as time from 'lib0/time';
import * as encoding from 'lib0/encoding';
import * as decoding from 'lib0/decoding';
import * as syncProtocol from 'y-protocols/sync';
import * as awarenessProtocol from 'y-protocols/awareness';
import * as mutex from 'lib0/mutex';
import { Observable } from 'lib0/observable';
import * as math from 'lib0/math';
import * as url from 'lib0/url';

//local imports
import { wsCloseCodes, eMessage } from '../types/errorTypes';
import { waitCommand, tokenRequest, authenticated, failed } from '../types/authType';
import store from '../index';
import { fetchJmrParticipants } from '../middlewares/participantRequests';
import { removeMessageType } from './participantMessage';
import { yjsDeleteJmr } from '../store/participantSlice';
import { setConnectedUsers } from '../store/awarenessSlice';
import { setError } from '../store/errorSlice';
import { Auth } from 'aws-amplify';
import { setActionGenerationState } from '../store/transcriptSlice';
import { setShareData, setShareLoading } from '../store/shareSlice';
import { JmrPermissions } from '../types/participantTypes';
import { ShareMessageType } from '../components/Collaboration/JMR/JmrOptions/JmrShareModal';

export type MessageHandler = (
  encoder: encoding.Encoder,
  decoder: decoding.Decoder,
  provider: WebsocketProvider,
  emitSynced: boolean,
  messageType: number
) => void;

const MESSAGE_SYNC = 0;
const MESSAGE_AWARENESS = 1;
const MESSAGE_TOKEN_AUTH = 2;
const MESSAGE_QUERY_AWARENESS = 3;
export const MESSAGE_PARTICIPANT = 4;
const MESSAGE_DOC_EXISTS = 5;
export const MESSAGE_AWARENESS_DATA = 6;
export const MESSAGE_AI = 12;
export const DELETE_JMR = 9;
const MESSAGE_SHAREID_AUTH = 10;
export const MESSAGE_CONTROL_SHAREID = 11;

enum MessageAI {
  Processing = 'processing',
  Completed = 'completed',
  Noop = 'noop',
  Error = 'error',
}

const messageHandlers: MessageHandler[] = [];

messageHandlers[MESSAGE_SYNC] = (encoder, decoder, provider, emitSynced, messageType) => {
  encoding.writeVarUint(encoder, MESSAGE_SYNC);
  const syncMessageType = syncProtocol.readSyncMessage(decoder, encoder, provider.doc, provider);
  if (emitSynced && syncMessageType === syncProtocol.messageYjsSyncStep2 && !provider.synced) {
    provider.synced = true;
  }
};

messageHandlers[MESSAGE_QUERY_AWARENESS] = (
  encoder,
  decoder,
  provider,
  emitSynced,
  messageType
) => {
  encoding.writeVarUint(encoder, MESSAGE_AWARENESS);
  encoding.writeVarUint8Array(
    encoder,
    awarenessProtocol.encodeAwarenessUpdate(
      provider.awareness,
      Array.from(provider.awareness.getStates().keys())
    )
  );
};

messageHandlers[MESSAGE_AWARENESS] = (encoder, decoder, provider, emitSynced, messageType) => {
  awarenessProtocol.applyAwarenessUpdate(
    provider.awareness,
    decoding.readVarUint8Array(decoder),
    provider
  );
};

messageHandlers[MESSAGE_TOKEN_AUTH] = (encoder, decoder, provider, emitSynced, messageType) => {
  const authType = decoding.readVarUint(decoder);
  switch (authType) {
    case tokenRequest:
      sendToken(provider.ws!);
      break;
    case waitCommand:
      //TODO make sure that the client doesn't try and reconnect while we wait for authentication
      break;
    case authenticated:
      onAuthenticated(provider);
      break;
    case failed:
      const reason = decoding.readVarString(decoder);
      permissionDeniedHandler(provider, reason);
      break;
    default:
  }
};

messageHandlers[MESSAGE_SHAREID_AUTH] = (encoder, decoder, provider, emitSynced, messageType) => {
  const authType = decoding.readVarUint(decoder);
  switch (authType) {
    case authenticated:
      const shareId = decoding.readVarString(decoder);
      const sharePerms = decoding.readVarString(decoder);
      if (sharePerms === JmrPermissions.MODIFY || sharePerms === JmrPermissions.READ) {
        store.dispatch(setShareData({ shareId, sharePerms, shareLoading: false }));
      } else {
        // throw an error
      }
      onAuthenticated(provider);

      break;
    case failed:
      const reason = decoding.readVarString(decoder);
      permissionDeniedHandler(provider, reason);
      break;
    default:
  }
};
messageHandlers[MESSAGE_CONTROL_SHAREID] = (
  encoder,
  decoder,
  provider,
  emitSynced,
  messageType
) => {
  const shareId = decoding.readVarString(decoder);
  const sharePerms = decoding.readVarString(decoder) as JmrPermissions;
  store.dispatch(setShareData({ shareId, sharePerms, shareLoading: false }));
};

messageHandlers[MESSAGE_PARTICIPANT] = (encoder, decoder, provider, emitSynced, messageType) => {
  const partType = decoding.readVarUint(decoder);
  if (partType === removeMessageType) {
    const jmrId = decoding.readVarString(decoder);
    store.dispatch(yjsDeleteJmr(jmrId));
  } else {
    store.dispatch(
      fetchJmrParticipants({
        jmrId: provider.roomname,
        refreshRequired: false,
      })
    );
  }
};

messageHandlers[DELETE_JMR] = (encoder, decoder, provider, emitSynced, messageType) => {
  const jmrId = decoding.readVarString(decoder);
  store.dispatch(yjsDeleteJmr(jmrId));
};

messageHandlers[MESSAGE_DOC_EXISTS] = (encoder, decoder, provider, emitSynced, messageType) => {
  if (provider.shareId && provider.connectViaShareId) {
    sendShareId(provider.ws!, provider.shareId);
  } else {
    sendToken(provider.ws!);
  }
};

messageHandlers[MESSAGE_AWARENESS_DATA] = (encoder, decoder, provider, emitsynced, messageType) => {
  store.dispatch(setConnectedUsers(decoding.readAny(decoder)));
};

messageHandlers[MESSAGE_AI] = (encoder, decoder, provider, emitsynced, messageType) => {
  const type = decoding.readVarString(decoder);
  switch (type) {
    case MessageAI.Processing: {
      // Set the actionsLoading state to true.
      store.dispatch(setActionGenerationState({ actionsLoading: true }));
      break;
    }
    case MessageAI.Completed: {
      // Reset actionsLoading and actionsRequester state.
      store.dispatch(setActionGenerationState({ actionsLoading: false, actionsRequester: false }));
      break;
    }
    case MessageAI.Noop: {
      // Reset actionsLoading state and set actionsRequester
      // so that client can display the noop warning.
      store.dispatch(setActionGenerationState({ actionsLoading: false, actionsNoop: true }));
      break;
    }
    case MessageAI.Error: {
      // Decode the error message and set the actionsError state.
      const error = decoding.readVarString(decoder);
      store.dispatch(setActionGenerationState({ actionsLoading: false, actionsError: error }));
      break;
    }
  }
};

export const isReady = (ws: WebSocket) => {
  return ws.readyState === ws.OPEN;
};

const reconnectTimeoutBase = 1200;
const maxReconnectTimeout = 2500;
// @todo - this should depend on awareness.outdatedTime
const messageReconnectTimeout = 60000 * 60; //1 hour timeout

const permissionDeniedHandler = (provider: WebsocketProvider, reason: string) => {
  // console.warn(`Permission denied to access ${provider.url}.\n${reason}`);
};

const readMessage = (provider: WebsocketProvider, buf: Uint8Array, emitSynced: boolean) => {
  const decoder = decoding.createDecoder(buf);
  const encoder = encoding.createEncoder();
  const messageType = decoding.readVarUint(decoder);
  const messageHandler = provider.messageHandlers[messageType];
  if (messageHandler) {
    messageHandler(encoder, decoder, provider, emitSynced, messageType);
  } else {
    // console.error("Unable to compute message")
  }
  return encoder;
};

const syncStep1 = (provider: WebsocketProvider, websocket: WebSocket) => {
  const encoder = encoding.createEncoder();
  encoding.writeVarUint(encoder, MESSAGE_SYNC);
  syncProtocol.writeSyncStep1(encoder, provider.doc);
  websocket.send(encoding.toUint8Array(encoder));
};

const sendToken = async (websocket: WebSocket) => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const authEncoder = encoding.createEncoder();
  encoding.writeVarUint(authEncoder, MESSAGE_TOKEN_AUTH);
  encoding.writeVarString(authEncoder, token);
  websocket.send(encoding.toUint8Array(authEncoder));
};

export const sendShareIdControl = (
  websocket: WebSocket,
  type: ShareMessageType,
  perms?: JmrPermissions
) => {
  store.dispatch(setShareLoading(true));
  const shareEncoder = encoding.createEncoder();
  encoding.writeVarUint(shareEncoder, MESSAGE_CONTROL_SHAREID);
  encoding.writeVarString(shareEncoder, type);
  if (perms) {
    encoding.writeVarString(shareEncoder, perms);
  }
  websocket.send(encoding.toUint8Array(shareEncoder));
};

const sendShareId = (websocket: WebSocket, shareId: string) => {
  const authEncoder = encoding.createEncoder();
  encoding.writeVarUint(authEncoder, MESSAGE_SHAREID_AUTH);
  encoding.writeVarString(authEncoder, shareId);
  websocket.send(encoding.toUint8Array(authEncoder));
};

const setupWS = (provider: WebsocketProvider) => {
  if (provider.shouldConnect && provider.ws === null) {
    const websocket = new provider._WS(provider.url);
    websocket.binaryType = 'arraybuffer';

    websocket.onmessage = (event: MessageEvent<any>) => {
      provider.wsLastMessageReceived = time.getUnixTime();
      const encoder = readMessage(provider, new Uint8Array(event.data), true);
      if (encoding.length(encoder) > 1) {
        websocket.send(encoding.toUint8Array(encoder));
      }
    };
    websocket.onclose = (closeEvent: CloseEvent) => {
      if (closeEvent.code === wsCloseCodes.abnormalClosure) {
        store.dispatch(setError(eMessage.yAbnormalClosureMessage));
      } else if (closeEvent.code === wsCloseCodes.unauthorized) {
        store.dispatch(setError(eMessage.unauthorizedMessage));
      }
      provider.ws = null;
      provider.wsconnecting = false;
      if (provider.wsconnected) {
        provider.wsconnected = false;
        provider.synced = false;
        // update awareness (all users except local left)
        awarenessProtocol.removeAwarenessStates(
          provider.awareness,
          Array.from(provider.awareness.getStates().keys()).filter(
            (client) => client !== provider.doc.clientID
          ),
          provider
        );
        provider.emit('status', [
          {
            status: 'disconnected',
          },
        ]);
      } else {
        provider.wsUnsuccessfulReconnects++;
      }
      // Start with no reconnect timeout and increase timeout by
      // log10(wsUnsuccessfulReconnects).
      // The idea is to increase reconnect timeout slowly and have no reconnect
      // timeout at the beginning (log(1) = 0)
      setTimeout(
        setupWS,
        math.min(
          math.log10(provider.wsUnsuccessfulReconnects + 1) * reconnectTimeoutBase,
          maxReconnectTimeout
        ),
        provider
      );
    };
    websocket.onopen = () => {
      if (provider.shouldClose) {
        websocket.close(wsCloseCodes.normalClosure);
      } else {
        provider.wsLastMessageReceived = time.getUnixTime();
        provider.wsconnecting = false;
        provider.wsconnected = true;
        provider.wsUnsuccessfulReconnects = 0;
        provider.emit('status', [
          {
            status: 'connected',
          },
        ]);
      }
      //send auth before sync step 1
      // sendToken(provider, websocket);
    };

    provider.ws = websocket;
    provider.wsconnecting = true;
    provider.wsconnected = false;
    provider.synced = false;
    provider.emit('status', [
      {
        status: 'connecting',
      },
    ]);
  }
};

const broadcastMessage = (provider: WebsocketProvider, buf: Uint8Array) => {
  if (provider.wsconnected && provider.ws) {
    provider.ws.send(buf);
  }
  if (provider.bcconnected) {
    provider.mux(() => {
      bc.publish(provider.bcChannel, buf);
    });
  }
};

//function needs to run after the authenticated handler comes back.
const onAuthenticated = (provider: WebsocketProvider) => {
  const websocket = provider.ws;
  provider.emit('status', [
    {
      status: 'authenticated',
    },
  ]);
  // always send sync step 1 when connected
  syncStep1(provider, websocket!);

  // broadcast local awareness state
  if (provider.awareness.getLocalState() !== null) {
    const encoderAwarenessState = encoding.createEncoder();
    encoding.writeVarUint(encoderAwarenessState, MESSAGE_AWARENESS);
    encoding.writeVarUint8Array(
      encoderAwarenessState,
      awarenessProtocol.encodeAwarenessUpdate(provider.awareness, [provider.doc.clientID])
    );
    websocket!.send(encoding.toUint8Array(encoderAwarenessState));
  }
};

/**
 * Websocket Provider for Yjs. Creates a websocket connection to sync the shared document.
 * The document name is attached to the provided url. I.e. the following example
 * creates a websocket connection to http://localhost:1234/my-document-name
 *
 * @example
 *   import * as Y from 'yjs'
 *   import { WebsocketProvider } from 'y-websocket'
 *   const doc = new Y.Doc()
 *   const provider = new WebsocketProvider(token, 'http://localhost:1234', 'my-document-name', doc)
 */
export class WebsocketProvider extends Observable<any> {
  bcChannel: string;
  url: string;
  roomname: string;
  doc: Y.Doc;
  _WS: any;
  awareness: awarenessProtocol.Awareness;
  wsconnected: boolean;
  wsconnecting: boolean;
  bcconnected: boolean;
  wsUnsuccessfulReconnects: number;
  messageHandlers: MessageHandler[];
  mux: mutex.mutex;
  _synced: boolean;
  shouldConnect: boolean;
  ws: WebSocket | null;
  _bcSubscriber: (data: ArrayBuffer) => void;
  wsLastMessageReceived: number;
  _awarenessUpdateHandler: (args: {
    added: number[];
    updated: number[];
    removed: number[];
  }) => void;
  _updateHandler: (update: Uint8Array, origin: WebsocketProvider) => void;
  _resyncInterval: number;
  _checkInterval: number;
  _beforeUnloadHandler: () => void;
  // flag used to close ws before it is open to avoid abnormalClosure.
  shouldClose: boolean;
  shareId: string;
  connectViaShareId: boolean;

  constructor(
    serverUrl: string,
    roomname: string,
    doc: Y.Doc,
    shareId: string,
    connectViaShareId: boolean,
    {
      connect = true,
      awareness = new awarenessProtocol.Awareness(doc),
      params = {},
      WebSocketPolyfill = WebSocket,
      resyncInterval = -1,
    } = {}
  ) {
    super();
    // ensure that url is always ends with /
    while (serverUrl[serverUrl.length - 1] === '/') {
      serverUrl = serverUrl.slice(0, serverUrl.length - 1);
    }
    const encodedParams = url.encodeQueryParams(params);
    this.bcChannel = serverUrl + '/' + roomname;
    this.url = serverUrl + '/' + roomname + (encodedParams.length === 0 ? '' : '?' + encodedParams);
    this.roomname = roomname;
    this.doc = doc;
    this._WS = WebSocketPolyfill;
    this.awareness = awareness;
    this.wsconnected = false;
    this.wsconnecting = false;
    this.bcconnected = false;
    this.wsUnsuccessfulReconnects = 0;
    this.messageHandlers = messageHandlers.slice();
    this.mux = mutex.createMutex();
    this._synced = false;
    this.ws = null;
    this.wsLastMessageReceived = 0;
    /**
     * Whether to connect to other peers or not
     */
    this.shouldConnect = connect;
    this.shouldClose = false;
    // If shareId is present, the server will use it to authenticate the connection instead of the client's JWT token
    this.shareId = shareId;
    this.connectViaShareId = connectViaShareId;

    this._resyncInterval = 0;
    if (resyncInterval > 0) {
      this._resyncInterval = setInterval(() => {
        if (this.ws) {
          // resend sync step 1
          const encoder = encoding.createEncoder();
          encoding.writeVarUint(encoder, MESSAGE_SYNC);
          syncProtocol.writeSyncStep1(encoder, doc);
          this.ws.send(encoding.toUint8Array(encoder));
        }
      }, resyncInterval) as unknown as number;
    }

    /**
     * @param {ArrayBuffer} data
     */
    this._bcSubscriber = (data) => {
      this.mux(() => {
        const encoder = readMessage(this, new Uint8Array(data), false);
        if (encoding.length(encoder) > 1) {
          bc.publish(this.bcChannel, encoding.toUint8Array(encoder));
        }
      });
    };

    this._updateHandler = (update, origin) => {
      if (origin !== this) {
        const encoder = encoding.createEncoder();
        encoding.writeVarUint(encoder, MESSAGE_SYNC);
        syncProtocol.writeUpdate(encoder, update);
        broadcastMessage(this, encoding.toUint8Array(encoder));
      }
    };
    this.doc.on('update', this._updateHandler);

    this._awarenessUpdateHandler = ({ added, updated, removed }) => {
      const changedClients = added.concat(updated).concat(removed);
      const encoder = encoding.createEncoder();
      encoding.writeVarUint(encoder, MESSAGE_AWARENESS);
      encoding.writeVarUint8Array(
        encoder,
        awarenessProtocol.encodeAwarenessUpdate(awareness, changedClients)
      );
      broadcastMessage(this, encoding.toUint8Array(encoder));
    };
    this._beforeUnloadHandler = () => {
      awarenessProtocol.removeAwarenessStates(this.awareness, [doc.clientID], 'window unload');
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', this._beforeUnloadHandler);
    } else if (typeof process !== 'undefined') {
      process.on('exit', () => this._beforeUnloadHandler);
    }
    awareness.on('update', this._awarenessUpdateHandler);
    this._checkInterval = setInterval(() => {
      if (
        this.wsconnected &&
        messageReconnectTimeout < time.getUnixTime() - this.wsLastMessageReceived
      ) {
        // no message received in a long time - not even your own awareness
        // updates (which are updated every 15 seconds)

        store.dispatch(setError(eMessage.yTimeoutMessage));
        this.ws!.close();
      }
    }, messageReconnectTimeout) as unknown as number;
    if (connect) {
      this.connect();
    }
  }

  get synced() {
    return this._synced;
  }

  set synced(state) {
    if (this._synced !== state) {
      this._synced = state;
      this.emit('synced', [state]);
      this.emit('sync', [state]);
    }
  }

  destroy() {
    if (this._resyncInterval !== 0) {
      clearInterval(this._resyncInterval);
    }
    clearInterval(this._checkInterval);
    this.disconnect();
    if (typeof window !== 'undefined') {
      window.removeEventListener('beforeunload', this._beforeUnloadHandler);
    } else if (typeof process !== 'undefined') {
      process.off('exit', () => this._beforeUnloadHandler);
    }
    this.awareness.off('update', this._awarenessUpdateHandler);
    this.doc.off('update', this._updateHandler);
    super.destroy();
  }

  connectBc() {
    if (!this.bcconnected) {
      bc.subscribe(this.bcChannel, this._bcSubscriber);
      this.bcconnected = true;
    }
    // send sync step1 to bc
    this.mux(() => {
      // write sync step 1
      const encoderSync = encoding.createEncoder();
      encoding.writeVarUint(encoderSync, MESSAGE_SYNC);
      syncProtocol.writeSyncStep1(encoderSync, this.doc);
      bc.publish(this.bcChannel, encoding.toUint8Array(encoderSync));
      // broadcast local state
      const encoderState = encoding.createEncoder();
      encoding.writeVarUint(encoderState, MESSAGE_SYNC);
      syncProtocol.writeSyncStep2(encoderState, this.doc);
      bc.publish(this.bcChannel, encoding.toUint8Array(encoderState));
      // write queryAwareness
      const encoderAwarenessQuery = encoding.createEncoder();
      encoding.writeVarUint(encoderAwarenessQuery, MESSAGE_QUERY_AWARENESS);
      bc.publish(this.bcChannel, encoding.toUint8Array(encoderAwarenessQuery));
      // broadcast local awareness state
      const encoderAwarenessState = encoding.createEncoder();
      encoding.writeVarUint(encoderAwarenessState, MESSAGE_AWARENESS);
      encoding.writeVarUint8Array(
        encoderAwarenessState,
        awarenessProtocol.encodeAwarenessUpdate(this.awareness, [this.doc.clientID])
      );
      bc.publish(this.bcChannel, encoding.toUint8Array(encoderAwarenessState));
    });
  }

  disconnectBc() {
    // broadcast message with local awareness state set to null (indicating disconnect)
    const encoder = encoding.createEncoder();
    encoding.writeVarUint(encoder, MESSAGE_AWARENESS);
    encoding.writeVarUint8Array(
      encoder,
      awarenessProtocol.encodeAwarenessUpdate(this.awareness, [this.doc.clientID], new Map())
    );
    broadcastMessage(this, encoding.toUint8Array(encoder));
    if (this.bcconnected) {
      bc.unsubscribe(this.bcChannel, this._bcSubscriber);
      this.bcconnected = false;
    }
  }

  disconnect() {
    this.shouldConnect = false;
    this.disconnectBc();
    if (this.ws !== null) {
      // closing while connecting throws error, so we use shouldClose flag on provider
      if (this.ws.readyState === this.ws.CONNECTING) {
        this.shouldClose = true;
      } else {
        this.ws.close(wsCloseCodes.normalClosure);
      }
    }
  }

  connect() {
    this.shouldConnect = true;
    if (!this.wsconnected && this.ws === null) {
      setupWS(this);
      this.connectBc();
    }
  }
}
