import { createContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useHistory, useParams } from 'react-router-dom';
import Client from './Client';
import { JmrPermissions, TParticipant } from '../../../types/participantTypes';
import _ from 'lodash';
import { fetchJmrParticipants } from '../../../middlewares/participantRequests';
import { positionJmrOptions } from './positionUtil';
import ShareJmr from './ShareJmr';
import { fetchCurrentUser } from '../../../middlewares/userRequests';

interface TJmrContext {
  currentParticipant: TParticipant;
  jmrParticipants: TParticipant[];
  jmrId: string;
  shareId: string;
  permissions: JmrPermissions;
}

// We must initialize the context with empty objects
export const JmrContext = createContext<TJmrContext>({
  currentParticipant: {} as TParticipant,
  jmrParticipants: [],
  jmrId: '',
  shareId: '',
  permissions: JmrPermissions.READ,
});

const JMR = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { jmrId, shareId } = useParams<{
    jmrId: string;
    shareId: string;
  }>();

  const { email: currentUser } = useAppSelector((state) => state.auth);
  const { error } = useAppSelector((state) => state.error);
  const {
    entities: participants,
    ids: participantIds,
    hardLoading,
    fetchedId,
    fetchFailed,
  } = useAppSelector((state) => state.participants);

  const [goHome, setGoHome] = useState(false);
  if (goHome) {
    history.push('/home');
  }

  useEffect(() => {
    dispatch(
      fetchJmrParticipants({
        jmrId,
        refreshRequired: true,
      })
    );
    dispatch(fetchCurrentUser());
  }, [dispatch, jmrId]);

  const handleScroll = () => {
    positionJmrOptions();
  };

  const throwErrors = () => {
    if (error) {
      throw new Error(error);
    }
  };

  const renderClient = () => {
    if (hardLoading || fetchedId !== jmrId) {
      if (fetchFailed && shareId) {
        return <ShareJmr noParticipant />;
      } else {
        return <div className='spin_loader spin_loader_middle_fixed' />;
      }
    }

    let currentParticipant = {} as TParticipant;
    let jmrParticipants = [] as TParticipant[];
    participantIds.forEach((id) => {
      const part = participants[id];
      if (!part) return;
      if (part.jmrId === jmrId) {
        jmrParticipants.push(part);
        if (part.participant === currentUser) {
          currentParticipant = part;
        }
      }
    });

    if (_.isEqual(currentParticipant, {})) {
      if (!goHome) setGoHome(true);
      return <></>;
    }

    // This ensures the current user's participant will always
    // appear first in the list of participants
    jmrParticipants.sort((partA, partB) => {
      const { participant: cp } = currentParticipant;
      const a = partA.participant;
      const b = partB.participant;
      if (a === cp) return -1;
      if (b === cp) return 1;
      return a.localeCompare(b);
    });

    const contextValue: TJmrContext = {
      currentParticipant,
      jmrParticipants,
      jmrId: jmrId,
      shareId: '',
      permissions: currentParticipant.jmrPerms,
    };

    return (
      <div className='editor_container' id='editor_container' onScroll={handleScroll}>
        {throwErrors()}
        <JmrContext.Provider value={contextValue}>
          <Client />
        </JmrContext.Provider>
      </div>
    );
  };

  return renderClient();
};

export default JMR;
