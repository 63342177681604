import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import { rootReducer } from './store';
import { Amplify } from 'aws-amplify';
import { configureStore } from '@reduxjs/toolkit';
import { REGION, USER_POOL_ID, USER_POOL_WEB_CLIENT_ID } from './utils/constants';

Amplify.configure({
  Auth: {
    region: REGION,

    userPoolId: USER_POOL_ID,

    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
  },
});

const store = configureStore({
  reducer: rootReducer,
});
export default store;

// infer the 'RootState' and 'AppDispatch' types from store itself.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
