import { useRouteMatch } from 'react-router-dom';
import JMR from '../Collaboration/JMR/JMR';
import ProtectedRoute from './ProtectedRoute';
import NavBar from './NavBar';
import Dashboard from '../Dashboard/Dashboard';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { clearError } from '../../store/errorSlice';
import ErrorBoundaryWithRouter from '../Util/ErrorBoundary';
import { useEffect } from 'react';
import { fetchCurrentUser } from '../../middlewares/userRequests';
import { isSubscriptionActiveOrTrialing } from "../../utils/authUtil";
import { STRIPE_PAYMENT_LINK } from '../../utils/constants';
const Home = () => {
  const { subStatus, email } = useAppSelector((state) => state.auth);
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  dispatch(clearError());
  useEffect(() => {
    dispatch(clearError());
    if (!subStatus) {
      dispatch(fetchCurrentUser());
    }
  }, [subStatus, dispatch]);
  return (
    <>
      <NavBar />
      <div id='app_content' className='app_content'>
        <ErrorBoundaryWithRouter>
          <ProtectedRoute path={`${path}/jmr/:jmrId/:shareId?`} component={JMR} />
        </ErrorBoundaryWithRouter>
        <ProtectedRoute path={`${path}/actions`} component={Dashboard} />
        {!isSubscriptionActiveOrTrialing(subStatus) && (
          <div id='sign_up_prompt' className='sign_up_prompt_slide_up'>
            <span className='sign_up_prompt_dismiss material-icons' onClick={handleDismiss}>
              close
            </span>
            <span>Subscribed users can add Jambr assistants to their calls</span>
            <span className='material-icons sign_up_prompt_arrow'>arrow_forward</span>
            <a href={`${STRIPE_PAYMENT_LINK}?prefilled_email=${email}`}>
              <button>Subscribe</button>
            </a>
          </div>
        )}
      </div>
    </>
  );
  function handleDismiss() {
    const promptElement = document.getElementById('sign_up_prompt');
    if (!promptElement) return;
    promptElement.classList.remove('sign_up_prompt_slide_up');
  }
};
export default Home;