import '../styles/main.scss';
import Login from './Account/Login';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './Navigation/ProtectedRoute';
import ResetPassword from './Account/ResetPassword';
import ForgotPassword from './Account/ForgotPassword';
import VerifyCode from './Account/VerifyCode';
import Home from './Navigation/Home';
import ErrorBoundaryWithRouter from './Util/ErrorBoundary';
import ShareJmr from './Collaboration/JMR/ShareJmr';
import SignUp from './Account/SignUp';
import CheckoutComplete from './Account/CheckoutComplete';

const App = () => {
  return (
    <div className='App'>
      <Router>
        <ErrorBoundaryWithRouter>
          <Switch>
            <Route
              exact
              path='/'
              render={() => {
                return (
                  <Redirect
                    to={{
                      pathname: '/home',
                    }}
                  />
                );
              }}
            />
            <Route exact path='/login' component={Login} />
            <Route exact path='/signup' component={SignUp} />
            <Route exact path='/verify-code' component={VerifyCode} />
            <Route exact path='/reset-password' component={ResetPassword} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route exact path='/share/:jmrId/:shareId' component={ShareJmr} />
            <ProtectedRoute path='/home' component={Home} />
            {/* Checkout is deprecated due to switching to Stripe-hosted payment page*/}
            {/*<ProtectedRoute path='/checkout' component={CheckoutPage} />*/}
            <ProtectedRoute path='/checkout-complete' component={CheckoutComplete} />
            <Route
              path='*'
              render={() => {
                return (
                  <Redirect
                    to={{
                      pathname: '/home',
                    }}
                  />
                );
              }}
            />
          </Switch>
        </ErrorBoundaryWithRouter>
      </Router>
    </div>
  );
};

export default App;
