import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MetaInputType } from '@jambr/collab-util';
import { MetaAssignee } from './MetaCustomInput/MetaAssignee';
import MetaDatePicker from './MetaCustomInput/MetaDatePicker';
import MetaDuration from './MetaCustomInput/MetaDuration';
import { MetaParticipants } from './MetaCustomInput/MetaParticipants';
import MetaTimePicker from './MetaCustomInput/MetaTimePicker';
import OutsideClickDetector from '../../../Util/OutsideClickDetector';
import { MetaContext } from './Meta';
import MetaLocation from './MetaCustomInput/MetaLocation';
import { JmrContext } from '../../JMR/JMR';
import { debounceWithAnimationFrame } from '../../../Util/debounceWithAnimationFrame';

const SCROLL_BAR_WIDTH = 10;

interface Props {
  inputType: MetaInputType;
}

const MetaModal = ({ inputType }: Props) => {
  const { showModal, closeModal, metaId } = useContext(MetaContext);
  const { currentParticipant } = useContext(JmrContext);
  const isCurrentParticipantEmpty = Object.keys(currentParticipant).length === 0;

  // Adjusting the modal overflow on the screen.
  const modalRef = useRef<HTMLDivElement>(null);
  const [transform, setTransform] = useState<string | undefined>();

  const placeModal = useCallback(() => {
    if (!showModal || !modalRef.current) {
      return;
    }
    const { left, right, width, height } = modalRef.current.getBoundingClientRect();
    if (width > window.innerWidth || height > window.innerHeight) {
      console.error('modal is larger than screen');
      return;
    }
    // TODO : Currently only adjusting horizontal overflow, since I didn't find any vertical overflow and it's hard to handle the triangle.
    if (left < 0) {
      setTransform(`translateX(calc(-50% + ${-left + 5}px))`);
    } else if (right > window.innerWidth) {
      setTransform(`translateX(calc(-50% - ${right - window.innerWidth + SCROLL_BAR_WIDTH}px))`);
    }
    return () => setTransform(undefined);
  }, [showModal]);

  useEffect(() => {
    const debouncedPlaceModal = debounceWithAnimationFrame(placeModal);
    debouncedPlaceModal();
    window.addEventListener('resize', debouncedPlaceModal);
    return () => window.removeEventListener('resize', debouncedPlaceModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const renderModalContent = () => {
    switch (inputType) {
      case MetaInputType.datePicker:
        return <MetaDatePicker />;
      case MetaInputType.timePicker:
        return <MetaTimePicker />;
      case MetaInputType.assignee:
        return <MetaAssignee />;
      case MetaInputType.duration:
        return <MetaDuration />;
      case MetaInputType.location:
        return <MetaLocation />;
      case MetaInputType.participants:
        if (!isCurrentParticipantEmpty) {
          return <MetaParticipants />;
        }
    }
  };
  return (
    <OutsideClickDetector callback={closeModal}>
      <div
        ref={modalRef}
        id={metaId + '.modal'}
        style={{ transform }}
        className='meta_modal'
        onClick={(e) => e.stopPropagation()}>
        {renderModalContent()}
      </div>
    </OutsideClickDetector>
  );
};

export default MetaModal;
