import { useContext } from 'react';
import { MetaContext as CollabMetaContext } from '../Meta';
import MetaInput from '../MetaInput';

const MetaDatePicker = () => {
  const { closeModal, setMetaData, getMetaData } = useContext(CollabMetaContext);
  const onSubmit = (data: { newData: string }) => {
    const { newData } = data;

    const dateParts = newData.split('-');
    let year, month, date;
    year = parseInt(dateParts[0]);
    month = parseInt(dateParts[1]);
    date = parseInt(dateParts[2]);

    const startDate = getMetaData();

    const newStartDate = new Date(startDate);
    newStartDate.setFullYear(year);
    newStartDate.setMonth(month - 1);
    newStartDate.setDate(date);

    setMetaData(newStartDate.toISOString());
    closeModal();
  };

  return (
    <MetaInput
      validation={{
        required: 'date is required',
      }}
      type='date'
      label=''
      customSubmit={onSubmit}
    />
  );
};

export default MetaDatePicker;
