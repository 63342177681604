import React, { useContext, useState } from 'react';
import { useAppDispatch } from '../../../../../hooks/hooks';
import { postParticipant } from '../../../../../middlewares/participantRequests';
import { EMAIL_VALIDATION } from '../../../../Forms/_form_util';
import { JmrContext } from '../../../JMR/JMR';
import { v4 as uuidv4 } from 'uuid';
import {
  ParticipantStatus,
  ParticipantType,
  TParticipant,
} from '../../../../../types/participantTypes';
import { MetaContext } from '../Meta';
import MetaInput from '../MetaInput';

export const MetaAssignee = () => {
  const { closeModal, setMetaData } = useContext(MetaContext);
  const { jmrParticipants, jmrId } = useContext(JmrContext);
  const dispatch = useAppDispatch();
  const [newParticipant, setNewParticipant] = useState({
    new: false,
    email: '',
  });

  const onSubmit = (data: { newData: string }) => {
    const { newData } = data;
    setMetaData(newData);
    if (newParticipant.new) {
      // Define the new participant to be added
      const participantToPost: TParticipant = {
        id: uuidv4(),
        participant: newParticipant.email,
        // TODO: Choose which status based on... what exactly?
        participantStatus: ParticipantStatus.ACCEPTED,
        jmrPerms: 'R',
        jmrId,
        participantType: ParticipantType.MEETING,
      } as TParticipant;
      // Dispatch the participant
      dispatch(postParticipant(participantToPost));
    }
    setNewParticipant({
      new: false,
      email: '',
    });
    closeModal();
  };

  const checkExists = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      if (!jmrParticipants.find((part) => part.participant.toUpperCase() === email.toUpperCase())) {
        setNewParticipant({
          new: true,
          email,
        });
      } else {
        setNewParticipant({
          new: false,
          email: '',
        });
      }
    } else {
      setNewParticipant({
        new: false,
        email: '',
      });
    }
  };

  return (
    <MetaInput
      validation={EMAIL_VALIDATION}
      type='text'
      label='new assignee'
      customSubmit={onSubmit}
      onChange={checkExists}
      datalistOptions={jmrParticipants.map((p) => p.participant)}>
      {newParticipant.new && (
        <div className='warning'>You are about to invite {newParticipant.email} to this JMR</div>
      )}
    </MetaInput>
  );
};
