import { TActionItem, TActionStatus } from '@jambr/collab-util/dist/types/actionItemTypes';
import { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import Meta from '../Meta/Meta';
import TextareaAutosize from 'react-textarea-autosize';
import useDebounce from '../../../hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { deleteActionItem, patchActionItem } from '../../../middlewares/actionRequests';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import OutsideClickDetector from '../../Util/OutsideClickDetector';
import { clearSelectedId, setSelectedId } from '../../../store/actionSlice';
import React from 'react';

interface Props {
  actionItem: TActionItem;
  patching: boolean;
  patched: boolean;
  selected: boolean;
}

const ActionItem = React.memo(({ actionItem, patching, patched, selected }: Props) => {
  const { jmrId } = actionItem;
  const { entities } = useAppSelector((state) => state.previews);
  const [title, setTitle] = useState(actionItem.title);
  const [completed, setCompleted] = useState(
    actionItem.status === TActionStatus.Completed
  );
  const [deadline, setDeadline] = useState(actionItem.dueDate);
  const [pastedSelection, setPastedSelection] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const titleInput = useRef<HTMLTextAreaElement>(null);

  const handleDelete = () => {
    dispatch(deleteActionItem(actionItem));
  };

  const handlePatch = () => {
    const trimmedTitle = title.trim();
    const newStatus = completed ? TActionStatus.Completed : TActionStatus.NotStarted;
    if (
      trimmedTitle === actionItem.title &&
      newStatus === actionItem.status &&
      deadline === actionItem.dueDate
    )
      return;

    dispatch(
      patchActionItem({
        oldAction: actionItem,
        newAction: {
          ...actionItem,
          title: trimmedTitle,
          status: completed ? TActionStatus.Completed : TActionStatus.NotStarted,
          dueDate: deadline,
        },
      })
    );
  };

  const renderMetaChips = () => {
    const metaChips = [];
    metaChips.push(
      <Meta
        metaData={deadline}
        inputType='date'
        icon='event'
        tooltip='deadline'
        placeholder='no deadline'
        onChange={setDeadline}
        key={v4()}
      />
    );
    if (jmrId) {
      metaChips.push(
        <Meta
          inputType='link'
          icon='place'
          tooltip='loading meeting...'
          placeholder='go to meeting'
          preview={entities[jmrId]}
          key={v4()}
        />
      );
    }
    return metaChips;
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const clipboardText = e.clipboardData.getData('text');
    if (!clipboardText) {
      return;
    }
    const noLinebreaks = clipboardText.replace(/(?:\r\n|\r|\n)/g, ' ');
    const noExcessSpaces = noLinebreaks.replace(/\s+/g, ' ').trim();

    const {
      currentTarget: { selectionStart, selectionEnd },
    } = e;

    const textBefore = title.substring(0, selectionStart);
    const textAfter = title.substring(selectionEnd, title.length);
    const mergedText = textBefore + noExcessSpaces + textAfter;

    setTitle(mergedText);
    setPastedSelection(selectionStart + noExcessSpaces.length);
  };

  useUpdateEffect(handlePatch, [completed, deadline]);

  useUpdateEffect(() => {
    titleInput.current?.setSelectionRange(pastedSelection, pastedSelection);
  }, [pastedSelection]);

  useDebounce(handlePatch, 1500, [title]);

  const renderPatchIndicator = () => {
    if (title && title.trim() !== actionItem.title)
      return <div className='action_item_different' />;

    if (patching) return <div className='action_item_different action_item_patching' />;

    if (patched)
      return (
        <div className='action_item_patched'>
          <span className='material-icons'>done</span>
        </div>
      );

    return <></>;
  };

  useEffect(() => {
    if (selected && titleInput.current) {
      titleInput.current.focus();
    }
  }, [selected]);

  return (
    <OutsideClickDetector
      callback={() => {
        if (selected) dispatch(clearSelectedId());
      }}>
      <div id={actionItem.id} className={selected ? 'action_item action_selected' : 'action_item'}>
        {renderPatchIndicator()}
        <div
          contentEditable={false}
          style={{
            userSelect: 'none',
          }}
          className={
            completed
              ? 'action_button_base action_button_active action_button_complete action_button_hover pointer'
              : 'action_button_base action_button_active action_button_hover pointer'
          }
          onClick={() => setCompleted(!completed)}
        />
        <TextareaAutosize
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onPaste={handlePaste}
          className='dash_action_title'
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          onFocus={() => dispatch(setSelectedId(actionItem.id))}
          ref={titleInput}
        />
        {!title && (
          <span className='placeholder_base dash_action_placeholder'>
            Start typing an action item...
          </span>
        )}
        <div className='meta_container' contentEditable={false}>
          {renderMetaChips()}
        </div>
        <span
          contentEditable={false}
          style={{ userSelect: 'none' }}
          className='material-icons delete_button'
          onClick={() => {
            handleDelete();
          }}>
          delete_outline
        </span>
      </div>
    </OutsideClickDetector>
  );
});

export default ActionItem;
