import React from "react";

interface Props {
  tooltip?: string;
  children?: React.ReactNode;
  showInstantly?: boolean;
  customContainerClasses?: string[];
  customPopupClasses?: string[];
}
const Tooltip = ({
  tooltip,
  children,
  showInstantly,
  customContainerClasses,
  customPopupClasses,
}: Props) => {
  return (
    <div className={getContainerClasses()}>
      <div className={getPopupClasses()}>{tooltip ? tooltip : children}</div>
    </div>
  );

  function getContainerClasses() {
    const classes = ['tooltip_container'];
    showInstantly && classes.push('show_instantly');
    customContainerClasses && classes.push(...customContainerClasses);
    return classes.join(' ');
  }

  function getPopupClasses() {
    if (customPopupClasses) return ['tooltip_popup', ...customPopupClasses].join(' ');
    return 'tooltip_popup';
  }
};

export default Tooltip;
