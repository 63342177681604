import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { JmrPermissions } from '../../../../types/participantTypes';
import { JmrContext } from '../../JMR/JMR';
import {
  AgendaItemElement,
  CustomEditor,
  ItemStatus,
  AgendaElement,
  isEmptyTextField,
  ObjectType,
  MetaField,
} from '@jambr/collab-util';
import { deleteStatusElement, getElementMetaContainer } from '../../Slate/util';
import { Editor, NodeEntry, Transforms } from 'slate';
import { insertAgendaItem } from './util';
import React from 'react';
import Tooltip from '../../../Util/Tooltip';

interface Props {
  element: AgendaItemElement;
}

const AgendaItem = ({ element, children }: RenderElementProps & Props) => {
  const editor: CustomEditor = useSlateStatic();
  const { selection } = editor;
  const pathToSelectionParent = selection?.anchor.path.slice(0, 2);
  const { permissions } = useContext(JmrContext);

  const elementPath = ReactEditor.findPath(editor, element);

  const titleEmpty = isEmptyTextField([...elementPath, 0], editor);

  const notesEmpty = isEmptyTextField([...elementPath, 2], editor);

  useEffect(() => {
    if (selection && permissions !== JmrPermissions.READ) {
      // Determine which css classes should be applied based on which
      // agenda item is currently selected.
      const assigneeMetaElement = document.getElementById(
        `${ObjectType.Agenda}.${MetaField.Assignee}.${element.id}`
      );
      const durationMetaElement = document.getElementById(
        `${ObjectType.Agenda}.${MetaField.Duration}.${element.id}`
      );
      const sequenceNumberElement = document.getElementById(element.id + '_sequence_number');

      if (!sequenceNumberElement || !assigneeMetaElement || !durationMetaElement) return;

      if (elementSelected()) {
        sequenceNumberElement.classList.remove('sequence_number_inactive');
        assigneeMetaElement.classList.remove('meta_colors_inactive');
        durationMetaElement.classList.remove('meta_colors_inactive');

        sequenceNumberElement.classList.add('sequence_number_active');
        assigneeMetaElement.classList.add('meta_colors_active');
        durationMetaElement.classList.add('meta_colors_active');
      } else {
        sequenceNumberElement.classList.remove('sequence_number_active');
        assigneeMetaElement.classList.remove('meta_colors_active');
        durationMetaElement.classList.remove('meta_colors_active');

        sequenceNumberElement.classList.add('sequence_number_inactive');
        assigneeMetaElement.classList.add('meta_colors_inactive');
        durationMetaElement.classList.add('meta_colors_inactive');
      }
    }

    // Disable the exhaustive deps warning because elementSelected() does
    // not need to be included in the deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection, element, permissions, elementPath]);

  const [elementMetaCont, elementMetaContLoc] = getElementMetaContainer(editor, elementPath);
  const elementSeqNumString = elementMetaCont?.artifactMetaData.seqNum;
  if (!elementMetaCont || !elementMetaContLoc || !elementSeqNumString) return <></>;
  const elementSeqNum = parseInt(elementSeqNumString);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const deleteAgendaItem = () => {
    const [agendaElement, agendaPath] = getAgendaEntry();
    const agendaItems = agendaElement.children;

    (agendaItems as AgendaItemElement[]).forEach((item, index) => {
      const [itemMetaCont, itemMetaContLoc] = getElementMetaContainer(editor, [
        ...agendaPath,
        index,
      ]);
      const itemSeqNumString = itemMetaCont?.artifactMetaData.seqNum;
      if (!itemMetaCont || !itemMetaContLoc || !itemSeqNumString) return;
      const itemSeqNum = parseInt(itemSeqNumString);
      if (itemSeqNum > elementSeqNum && item.itemStatus !== ItemStatus.Deleted) {
        Transforms.setNodes(
          editor,
          {
            artifactMetaData: {
              ...elementMetaCont.artifactMetaData,
              seqNum: itemSeqNum - 1 + '',
            },
          },
          {
            at: itemMetaContLoc,
          }
        );
      }
    });

    deleteStatusElement(editor, element);
  };

  const clearAgendaItem = () => {
    const [, agendaPath] = getAgendaEntry();
    deleteStatusElement(editor, element);
    insertAgendaItem(editor, agendaPath);
  };

  const elementSelected = () => {
    if (selection && permissions !== JmrPermissions.READ) {
      if (_.isEqual(pathToSelectionParent, elementPath)) return true;
    }
    return false;
  };

  const setSelection = (event: React.MouseEvent<HTMLDivElement>) => {
    // Triggers the above useEffect to run, because it listens for
    // changes to the current selection. CSS classes will update.
    Transforms.select(editor, {
      anchor: Editor.start(editor, elementPath),
      focus: Editor.start(editor, elementPath),
    });
  };

  const isClear = () => {
    // Check text of agenda title
    if (!titleEmpty) {
      return false;
    }
    // Check text of agenda notes
    if (!notesEmpty) {
      return false;
    }

    const [metaContainerEl] = getElementMetaContainer(editor, elementPath);

    // Check agenda meta data
    const { duration } = metaContainerEl.artifactMetaData;
    if (duration) return false;
    return true;
  };

  // Returns a count of the AgendaItems that are not marked deleted. We use it to
  // determine whether or not we should render the "delete" button.
  const numValidItems = () => {
    return (getAgendaEntry()[0].children as AgendaItemElement[]).filter(
      (child) => child.itemStatus !== ItemStatus.Deleted
    ).length;
  };

  const getAgendaEntry: () => NodeEntry<AgendaElement> = () => {
    return Editor.node(editor, elementPath.slice(0, -1)) as NodeEntry<AgendaElement>;
  };

  const renderByPerms = () => {
    if (permissions !== JmrPermissions.READ) {
      return (
        <Draggable key={element.id} draggableId={element.id} index={elementSeqNum}>
          {(provided) => {
            return (
              <div
                {...provided.draggableProps}
                ref={provided.innerRef}
                key={element.id}
                onClick={handleClick}
                className='agenda_item'
                id={element.id}>
                <div
                  {...provided.dragHandleProps}
                  contentEditable={false}
                  style={{
                    userSelect: 'none',
                  }}
                  id={element.id + '_sequence_number'}
                  onMouseDown={setSelection}
                  className='sequence_number sequence_number_inactive'>
                  {elementSeqNum + 1}
                  <div className='drag_handle'>
                    <span className='material-icons'>drag_handle</span>
                  </div>
                  <Tooltip tooltip='drag to reorder' />
                </div>
                {titleEmpty && (
                  <div
                    contentEditable={false}
                    style={{
                      userSelect: 'none',
                    }}
                    className='placeholder_base placeholder_title'>
                    Agenda Item {elementSeqNum + 1}
                  </div>
                )}
                {children}
                {(numValidItems() !== 1 || !isClear()) && (
                  <span
                    contentEditable={false}
                    style={{
                      userSelect: 'none',
                    }}
                    className='material-icons delete_button'
                    onClick={() => {
                      numValidItems() === 1 ? clearAgendaItem() : deleteAgendaItem();
                    }}>
                    delete_outline
                  </span>
                )}
              </div>
            );
          }}
        </Draggable>
      );
    } else {
      return (
        <div onClick={handleClick} className='agenda_item' id={element.id}>
          <div
            contentEditable={false}
            style={{
              userSelect: 'none',
            }}
            className='sequence_number sequence_number_active'>
            {elementSeqNum + 1}
          </div>
          {titleEmpty && (
            <div
              contentEditable={false}
              style={{
                userSelect: 'none',
              }}
              className='placeholder_base placeholder_title'>
              Agenda Item {elementSeqNum + 1}
            </div>
          )}
          {children}
        </div>
      );
    }
  };

  return renderByPerms();
};

export default AgendaItem;
