import {
  CaretPosition,
  CursorOverlayState,
  useRemoteCursorOverlayPositions,
} from '@slate-yjs/react';
import clsx from 'clsx';
import { CSSProperties, PropsWithChildren, useRef } from 'react';
import { CursorData } from '@jambr/collab-util';

type CaretProps = {
  position: CaretPosition;
  data: CursorData;
};

/**
 * This component render's cursors from awareness data. They are positioned absolutely, relative to
 * a div that wraps the editor
 */
const Caret = ({ position, data }: CaretProps) => {
  const caretStyle: CSSProperties = {
    ...position,
    background: data.color,
  };

  const caretBubbleColor = {
    borderColor: data.color,
  } as any;

  return (
    <div style={caretStyle} className='caret'>
      <div className='caret_bubble_wrapper'>
        <div style={caretBubbleColor} className='caret_bubble' />
      </div>
    </div>
  );
};

/**
 * This component renders cursors and highlights for remote selection ranges. Because selections
 * can span multiple lines, there may be many rectangles to be rendered for a single user's
 * awareness data. Selection range rectangles and cursors are positioned absolutely, relative to
 * RemoteCursorOverlay, which is a wrapper on the editor
 */
const RemoteSelection = ({
  data,
  selectionRects,
  caretPosition,
}: CursorOverlayState<CursorData>) => {
  if (!data) {
    return null;
  }

  const selectionStyle: CSSProperties = {
    // Add a opacity to the background color
    backgroundColor: `${data.color}40`,
  };

  return (
    <>
      {selectionRects.map((position, i) => (
        <div
          style={{
            ...selectionStyle,
            ...position,
          }}
          className='remote_selection'
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        />
      ))}
      {caretPosition && <Caret position={caretPosition} data={data} />}
    </>
  );
};

type RemoteCursorsProps = PropsWithChildren<{
  className?: string;
}>;

/**
 * Provides a relative context for positioning cursors and selection range rectangles
 */
export const RemoteCursorOverlay = ({ className, children }: RemoteCursorsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { cursors } = useRemoteCursorOverlayPositions<CursorData>({
    containerRef,
  });
  return (
    <div className={clsx('relative', className)} ref={containerRef}>
      {children}
      {cursors.map((cursor) => (
        <RemoteSelection key={cursor.clientId} {...cursor} />
      ))}
    </div>
  );
};
