import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TranscriptState } from '../types/transcriptTypes';
interface TranscriptServerState {
  status: TranscriptState;
  subscription: SubscriptionState;
  error: null | string;
  actionGenerationState: TActionGenerationState;
}

export interface TActionGenerationState {
  actionsLoading: boolean;
  actionsNoop: boolean;
  actionsRequester: boolean;
  actionsError: string;
}

export enum SubscriptionState {
  Subscribed,
  NotSubscribed,
  Pending,
}

export const initialState: TranscriptServerState = {
  status: TranscriptState.Disconnected,
  subscription: SubscriptionState.NotSubscribed,
  error: null,
  actionGenerationState: {
    actionsLoading: false,
    actionsNoop: false,
    actionsRequester: false,
    actionsError: '',
  },
};

const transcriptSlice = createSlice({
  name: 'transcriptSocketSlice',
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<TranscriptState>) {
      return {
        ...state,
        status: action.payload,
      };
    },
    setSubscription(state, action: PayloadAction<SubscriptionState>) {
      const subscription = action.payload;
      return {
        ...state,
        subscription,
      };
    },
    setError(state, action: PayloadAction<string>) {
      return {
        ...state,
        status: TranscriptState.Errored,
        error: action.payload,
      };
    },
    clearError(state) {
      return {
        ...state,
        error: null,
      };
    },
    setActionGenerationState(state, action: PayloadAction<Partial<TActionGenerationState>>) {
      return {
        ...state,
        actionGenerationState: { ...state.actionGenerationState, ...action.payload },
      };
    },
  },
});

export const { setStatus, setSubscription, setError, clearError, setActionGenerationState } =
  transcriptSlice.actions;

export default transcriptSlice.reducer;
