import { useContext } from 'react';
import { useAppSelector } from '../../../../hooks/hooks';
import { JmrContext } from '../JMR';
import UserAvatar, { ExtraUsersAvatar } from './UserAvatar';
import JmrDeleteModal from './JmrDeleteModal';
import { JmrPermissions, ParticipantType } from '../../../../types/participantTypes';
import { RenderElementProps } from 'slate-react';
import JmrShareModal from './JmrShareModal';
import Tooltip from '../../../Util/Tooltip';
import { IS_EXTERNAL } from '../../../../types/jmrTypes';

const JmrAccessTab = ({ children }: RenderElementProps) => {
  const { currentParticipant, permissions } = useContext(JmrContext);
  const { connectedUsers } = useAppSelector((state) => state.awareness);
  const { email: currentUser } = useAppSelector((state) => state.auth);
  const sortedUsers = [...connectedUsers].sort((a, b) => {
    if (a.email === currentUser) return 1;
    if (b.email === currentUser) return -1;
    return 0;
  });
  const isCurrentParticipantEmpty = Object.keys(currentParticipant).length === 0;
  const { isExternal } = currentParticipant;
  const originatesExternally = isExternal === IS_EXTERNAL.YES;

  const renderAvatars = () => {
    if (sortedUsers.length > 5) {
      const avatars: JSX.Element[] = [];
      avatars.push(<ExtraUsersAvatar extraUsers={sortedUsers.slice(0, sortedUsers.length - 5)} />);
      avatars.push(...sortedUsers.slice(-5).map((user, i) => <UserAvatar user={user} key={i} />));
      return avatars;
    }
    return sortedUsers.map((user, i) => <UserAvatar user={user} key={i} />);
  };

  return (
    <div
      contentEditable={false}
      style={{
        userSelect: 'none',
      }}
      id='jmr_options'>
      {renderAvatars()}
      {originatesExternally && (
        <div className='garbage_can is_external_icon'>
          <span className='material-icons'>today</span>
          <Tooltip
            tooltip='This meeting comes from an external calendar'
            showInstantly
            customPopupClasses={['offset_left']}
          />
        </div>
      )}
      {!isCurrentParticipantEmpty && permissions !== JmrPermissions.READ && <JmrShareModal />}
      {!isCurrentParticipantEmpty &&
        currentParticipant.participantType === ParticipantType.OWNER && <JmrDeleteModal />}
      {children}
    </div>
  );
};

export default JmrAccessTab;
