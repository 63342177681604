import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch } from '../../../../../hooks/hooks';
import { postParticipant } from '../../../../../middlewares/participantRequests';
import {
  JmrPermissions,
  ParticipantStatus,
  ParticipantType,
  TParticipant,
} from '../../../../../types/participantTypes';
import { EMAIL_VALIDATION } from '../../../../Forms/_form_util';
import { JmrContext } from '../../../JMR/JMR';
import { MetaParticipantRow } from './MetaParticipantRow';

export const MetaParticipants = () => {
  const dispatch = useAppDispatch();
  const { currentParticipant, jmrParticipants, jmrId, permissions } = useContext(JmrContext);

  const { register, handleSubmit, reset, errors } = useForm();
  const [emailPopulated, setEmailPopulated] = useState(false);


  const onSubmit = (data: { email: string; perms: string }) => {
    const { email, perms } = data;
    const newParticipant: TParticipant = {
      ...currentParticipant,
      id: uuidv4(),
      participant: email,
      // TODO: Choose which status based on... what exactly?
      participantStatus: ParticipantStatus.ACCEPTED,
      jmrPerms: perms,
      jmrId,
      participantType: ParticipantType.MEETING,
    } as TParticipant;
    dispatch(postParticipant(newParticipant));
    reset({
      email: '',
      errors: [],
    });
    setEmailPopulated(false);
  };

  // Custom validation rule to check that the new participant's email
  // does not already exist on the JMR. Return false if it already exists,
  // return true otherwise
  const existingParticipant = (email: string) => {
    let retVal: boolean | string = true;
    jmrParticipants.forEach((part) => {
      if (part.participant === email) {
        retVal = 'participant already exists';
      }
    });
    return retVal;
  };

  return (
    <div id='meta_participants'>
      {jmrParticipants.map((participant) => (
        <MetaParticipantRow participant={participant} key={participant.id} />
      ))}
      {permissions !== JmrPermissions.READ && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='participant_row new_participant'>
            <div className='new_participant_email'>
              <div className='meta_field'>
                <input
                  type='text'
                  name='email'
                  ref={register({
                    ...EMAIL_VALIDATION,
                    validate: existingParticipant,
                  })}
                  onChange={(e) =>
                    e.target.value === '' ? setEmailPopulated(false) : setEmailPopulated(true)
                  }
                  autoComplete='off'
                  required
                />
                <label htmlFor='email'>
                  <span>+ new participant</span>
                </label>
              </div>
              {errors.email && <div className='error'>{errors.email.message}</div>}
            </div>
            {emailPopulated && (
              <>
                <div className='participant_permission_selector'>
                  <select name='perms' ref={register} className='permission_selector'>
                    <option value={JmrPermissions.FULL}>{JmrPermissions.FULL_LONG}</option>
                    <option value={JmrPermissions.READ}>{JmrPermissions.READ_LONG}</option>
                  </select>
                </div>
                <div className='new_participant_button'>
                  <div
                    className='control_button_base control_button_confirm'
                    onClick={handleSubmit(onSubmit)}>
                    invite
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      )}
    </div>
  );
};
