import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { clearAuthError, setAuthError } from '../../store/authSlice';
import Form from '../Forms/Form';
import { TextInput } from '../Forms/Inputs';
import { PW_VALIDATION, VCODE_VALIDATION } from '../Forms/_form_util';
import AccountTemplate from './AccountTemplate';
import ButtonAsLink from "../Forms/ButtonAsLink";

interface Props {
  location: any;
}

const ResetPassword = ({ location }: Props) => {
  const [redirect, setRedirect] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState('/login');
  const [loading, setLoading] = useState(false);
    const [email] = useState(() => location.search.split('=')[1]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAuthError());
  }, [dispatch]);

  const onSubmit = (data: { code: string; password: string; passwordConfirm: string }) => {
    setLoading(true);
    const { code, password } = data;

    Auth.forgotPasswordSubmit(email.trim(), code.trim(), password.trim())
      .then(() => {
        setRedirect(true);
      })
      .catch((err) => {
        dispatch(setAuthError(err));
        setLoading(false);
      });
  };

  const resendCode = () => {
    setLoading(true);

    Auth.forgotPassword(email)
      .then(() => {
        setRedirectLocation('/reset-password');
        setLoading(false);
        setRedirect(true);
      })
      .catch((err) => {
        dispatch(setAuthError(err));
        setLoading(false);
      });
  };

  return (
    <AccountTemplate>
      <Form showButton buttonText='CHANGE PASSWORD' onSubmit={onSubmit} loading={loading}>
        <TextInput name='code' label='code' validation={VCODE_VALIDATION} />
        <TextInput name='password' label='password' password validation={PW_VALIDATION} />
        <TextInput name='passwordConfirm' label='passwordConfirm' password confirmPassword />
      </Form>
      <div className='account_links'>
        Didn't receive a code?  <ButtonAsLink onClick={resendCode} text={"Send another one"} />
        <br />
        Oh wait, I remember! <Link to='/login'>Login</Link>
      </div>
      {redirect && (
        <Redirect
          to={{
            pathname: redirectLocation,
            search: `?email=${email}`,
          }}
        />
      )}
    </AccountTemplate>
  );
};

export default ResetPassword;
