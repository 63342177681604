interface Props {
  loading: boolean;
  buttonText: string;
  onClick: any;
}

const SubmitButton = ({ loading, buttonText, onClick }: Props) => {
  return loading ? (
    <div className='spin_loader'></div>
  ) : (
    <input type='submit' value={buttonText} className='submit_button' onClick={onClick} />
  );
};

export default SubmitButton;
