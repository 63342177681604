//including code written by Eric Meier from the slate-yjs-example
// @refresh reset
import { Editor, Element, Point, Range, Transforms } from 'slate';
import { getTextField, SHORTCUTS } from '../Slate/util';
import {
  BulletedListElement,
  CustomEditor,
  CustomElement,
  NumberedListElement,
  CustomElementType,
} from '@jambr/collab-util';

const withShortcuts = (editor: CustomEditor) => {
  const { deleteBackward, insertText } = editor;

  editor.insertText = (text) => {
    const { selection } = editor;
    const textParent = getTextField(editor);

    if (textParent && textParent[0].type !== CustomElementType.RichText) {
      insertText(text);
      return;
    }

    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: (n: any) => Editor.isBlock(editor, n),
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = {
        anchor,
        focus: start,
      };
      const beforeText = Editor.string(editor, range);
      const ordered = beforeText === '1.';
      const type = SHORTCUTS[beforeText];

      if (type) {
        Editor.withoutNormalizing(editor, () => {
          Transforms.select(editor, range);
          Transforms.delete(editor);
          const newProperties: Partial<CustomElement> = {
            type,
          };
          Transforms.setNodes<CustomElement>(editor, newProperties, {
            match: (n) => Editor.isBlock(editor, n),
          });

          if (type === CustomElementType.ListItem) {
            const list: BulletedListElement | NumberedListElement = ordered
              ? {
                  type: CustomElementType.NumberedList,
                  children: [],
                }
              : {
                  type: CustomElementType.BulletedList,
                  children: [],
                };
            Transforms.wrapNodes(editor, list, {
              match: (n) =>
                Element.isElement(n) &&
                n.type === CustomElementType.ListItem &&
                !Editor.isEditor(n),
            });
          }
        });

        return;
      }
    }

    insertText(text);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      });

      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);

        if (
          Element.isElement(block) &&
          block.type !== CustomElementType.TextBlock &&
          // !Editor.isEditor(block) &&
          Point.equals(selection.anchor, start)
        ) {
          const newProperties: Partial<CustomElement> = {
            type: CustomElementType.TextBlock,
          };
          Transforms.setNodes(editor, newProperties);

          if (block.type === CustomElementType.ListItem) {
            Transforms.unwrapNodes(editor, {
              match: (n) =>
                Element.isElement(n) &&
                (n.type === CustomElementType.BulletedList ||
                  n.type === CustomElementType.NumberedList) &&
                !Editor.isEditor(n),
              split: true,
            });
          }

          return;
        }
      }

      deleteBackward(...args);
    }
  };

  return editor;
};

export default withShortcuts;
