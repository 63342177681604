import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { eMessage } from '../types/errorTypes';

interface TErrorState {
  error: eMessage;
}

const initialState: TErrorState = {
  error: eMessage.empty,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<eMessage>) {
      return { ...state, error: action.payload };
    },
    clearError(state) {
      return { ...state, error: eMessage.empty };
    },
  },
});

export const { setError, clearError } = errorSlice.actions;
export default errorSlice.reducer;
