import { useContext } from 'react';
import { MetaContext } from '../Meta';
import MetaInput from '../MetaInput';

const MetaTimePicker = () => {
  const { setMetaData, closeModal, getMetaData } = useContext(MetaContext);

  const onSubmit = (data: { newData: string }) => {
    const { newData } = data;
    const startDate = getMetaData();
    if (!startDate) {
      closeModal();
      return;
    } else {
      const timeParts = newData.split(':');
      let hour, minute;
      hour = parseInt(timeParts[0]);
      minute = parseInt(timeParts[1]);

      const newStartDate = new Date(startDate);
      newStartDate.setHours(hour);
      newStartDate.setMinutes(minute);

      setMetaData(newStartDate.toISOString());
      closeModal();
    }
  };

  return (
    <MetaInput
      validation={{
        required: 'time is required',
      }}
      type='time'
      label=''
      customSubmit={onSubmit}
    />
  );
};

export default MetaTimePicker;
