import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (ref: React.MutableRefObject<any>, callback: () => void) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

/**
 * Component that alerts if you click outside of it
 */
const OutsideClickDetector = (props: {
  children: React.ReactNode;
  callback: () => void;
  className?: string;
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.callback);

  return (
    <div ref={wrapperRef} className={props.className}>
      {props.children}
    </div>
  );
};

OutsideClickDetector.propTypes = {
  children: PropTypes.element.isRequired,
};

export default OutsideClickDetector;
