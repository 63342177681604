import { TActionItem } from '@jambr/collab-util/dist/types/actionItemTypes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';

export const fetchActionItems = createAsyncThunk('fetchActionItems', async () => {
  const response = await axiosInstance.get(`/ActionItems`);
  return response.data;
});

export const postActionItem = createAsyncThunk(
  'postActionItem',
  async (arg: { assignee: string; id: string }) => {
    const response = await axiosInstance.post('/ActionItems', arg);
    return response.data;
  }
);

export const patchActionItem = createAsyncThunk(
  'patchActionItem',
  async (arg: { oldAction: TActionItem; newAction: TActionItem }) => {
    const { oldAction, newAction } = arg;

    const response = await axiosInstance.patch(`/ActionItems/${oldAction.id}`, {
      ...newAction,
      title: encodeURIComponent(newAction.title),
      // When we have notes, they will also need to be URIEncoded
    });
    return response.data;
  }
);

export const deleteActionItem = createAsyncThunk('deleteActionItem', async (arg: TActionItem) => {
  const response = await axiosInstance.delete(`/ActionItems/${arg.id}`);
  return response.data;
});
