import { MetaInputType } from '@jambr/collab-util';
import { useState } from 'react';
import { v4 } from 'uuid';
import { TParticipant } from '../../../types/participantTypes';
import { MetaContext } from '../../Collaboration/elements/Meta/Meta';
import MetaModal from '../../Collaboration/elements/Meta/MetaModal';
import Preview from '../../Navigation/Previews/Preview';
import { convertDate } from '../../Util/dateUtil';
import Tooltip from '../../Util/Tooltip';

interface Props {
  metaData?: string;
  inputType: 'date' | 'link';
  icon: string;
  tooltip: string;
  placeholder: string;
  preview?: TParticipant;
  onChange?: (newData: any) => void;
}

const Meta = ({ metaData, inputType, icon, tooltip, placeholder, preview, onChange }: Props) => {
  const [showModal, setShowModal] = useState(false);

  // The API object which will be provided as context to children
  const metaApi = {
    showModal,
    closeModal: () => {
      setShowModal(false);
    },
    getMetaData: () => {
      if (metaData) return metaData;
      return '';
    },
    setMetaData: (newData: string) => {
      if (onChange) onChange(newData);
    },
    metaId: v4(),
  };

  const renderMetaData = () => {
    if (!metaData) return placeholder;

    let processedMetaData;
    switch (inputType) {
      case 'date':
        processedMetaData = convertDate(new Date(metaData));
        break;
    }
    return processedMetaData;
  };

  const handleClick = () => {
    if (inputType !== 'link') setShowModal(true);
  };

  const renderMetaModal = () => {
    if (!showModal) return;

    return (
      <MetaContext.Provider value={metaApi}>
        <MetaModal inputType={MetaInputType.datePicker} />
      </MetaContext.Provider>
    );
  };

  const renderTooltip = () => {
    if (inputType === 'link' && preview) {
      return (
        <Tooltip showInstantly>
          <Preview previewEntity={preview} renderActive />
        </Tooltip>
      );
    }
    return <Tooltip tooltip={tooltip} />;
  };

  return (
    <div className='meta_base meta_hover meta_small meta_colors_active' onClick={handleClick}>
      <span className='material-icons'>{icon}</span>
      {renderMetaData()}
      {renderTooltip()}
      {renderMetaModal()}
    </div>
  );
};

export default Meta;
