import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TConnectedUser } from '../types/jmrTypes';

interface TAwarenessState {
  connectedUsers: TConnectedUser[];
}

const initialState: TAwarenessState = {
  connectedUsers: [],
};

const awarenessSlice = createSlice({
  name: 'awareness',
  initialState,
  reducers: {
    setConnectedUsers(state, action: PayloadAction<TConnectedUser[]>) {
      return {
        ...state,
        connectedUsers: action.payload,
      };
    },
    clearConnectedUsers(state) {
      return {
        ...state,
        connectedUsers: [],
      };
    },
  },
});

export const { setConnectedUsers, clearConnectedUsers } = awarenessSlice.actions;
export default awarenessSlice.reducer;
