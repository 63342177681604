import React from 'react';
import { useAppDispatch } from '../../../hooks/hooks';
import { clearError } from '../../../store/errorSlice';
import { clearFetchedId } from '../../../store/participantSlice';
import { STAGE } from '../../../utils/constants';

type Props = {
  error: Error;
  errorInfo: React.ErrorInfo;
  handler: () => void;
};

const JmrError = ({ error, errorInfo, handler }: Props) => {
  const dispatch = useAppDispatch();
  const retryError = () => {
    dispatch(clearError());
    dispatch(clearFetchedId());
    handler();
  };
  // You can render any custom fallback UI
  return (
    <div className='error_component'>
      <section>
        <h1 className='error_uhoh'>Error</h1>
        <div className='error_details'>
          <span className='error_text'>
            {error.message}
            <div>If the error persists please contact support.</div>
            <br />
            <button type='button' className='error_button' onClick={() => retryError()}>
              <div>Refresh</div>
              <span className='material-icons'>refresh</span>
            </button>
          </span>
          {STAGE === 'dev' && (
            <details
              style={{
                whiteSpace: 'pre-wrap',
              }}>
              <summary>Details</summary>
              <br />
              {error && error.toString()}
              {errorInfo.componentStack}
            </details>
          )}
        </div>
      </section>
    </div>
  );
};
export default JmrError;
