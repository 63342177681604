import { TestConfig } from '../dev-utils/config';

export interface TAuth {
  config: TestConfig;
  jwtToken: string;
}

export interface TAuthError {
  code?: string;
  name?: string;
  message?: string;
}

export enum TSubStatus {
  Unsubscribed = 'unsubscribed',
  Expired = 'expired',
  Active = 'active',
  Trialing = 'trialing',
}

//constants for auth messaging in y-websocket
export const tokenRequest = 0;
export const waitCommand = 1;
export const authenticated = 2;
export const failed = 3;
