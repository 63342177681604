import { createEntityAdapter, createSlice, EntityId } from '@reduxjs/toolkit';
import { deleteJmr } from '../middlewares/jmrRequests';
import {
  deleteParticipant,
  fetchJmrParticipants,
  fetchParticipants,
  patchParticipant,
  postParticipant,
} from '../middlewares/participantRequests';
import { TParticipant } from '../types/participantTypes';

const participantAdapter = createEntityAdapter<TParticipant>({
  sortComparer: (a, b) => {
    const dateOrder = Date.parse(a.startDate) - Date.parse(b.startDate);
    // If dates are not the same, sort by ascending date
    if (dateOrder !== 0) return dateOrder;
    // Sort jmrs with the same start date lexicographically
    if (a.title.localeCompare(b.title) > 0) return 1;
    return -1;
  },
});

export const initialState = participantAdapter.getInitialState({
  loading: false,
  hardLoading: false,
  yjsUpdateRequired: false,
  fetchFailed: false,
  yjsParticipantRemoved: '',
  postedId: '',
  fetchedId: '',
});

const participantSlice = createSlice({
  name: 'participants',
  initialState,
  reducers: {
    setYjsUpdateRequired(state) {
      state.yjsUpdateRequired = false;
    },
    setYjsParticipantRemoved(state) {
      state.yjsParticipantRemoved = '';
    },
    yjsDeleteJmr(state, action) {
      const { ids, entities } = state;
      const jmrId = action.payload;
      const removeIds: EntityId[] = [];

      ids.forEach((id) => {
        let p = entities[id];
        if (p?.jmrId === jmrId) {
          removeIds.push(id);
        }
      });
      participantAdapter.removeMany(state, removeIds);
    },
    clearPostedId(state) {
      state.postedId = '';
    },
    clearFetchedId(state) {
      state.fetchedId = '';
    },
    updateParticipant(
      state,
      action: {
        payload: TParticipant;
        type: string;
      }
    ) {
      participantAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchParticipants.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchParticipants.fulfilled, (state, action) => {
        participantAdapter.addMany(state, action.payload);
        state.loading = false;
      })
      .addCase(fetchParticipants.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchJmrParticipants.pending, (state, action) => {
        const { refreshRequired } = action.meta.arg;
        state.fetchFailed = false;
        if (refreshRequired) {
          state.hardLoading = true;
        } else {
          state.loading = true;
        }
      })
      .addCase(fetchJmrParticipants.fulfilled, (state, action) => {
        const { ids, entities } = state;
        const { jmrId, refreshRequired } = action.meta.arg;
        // removes participants belonging to this user. these will be fetched by jmrPreview
        let myParticipants: EntityId[] = [];
        ids?.forEach((id) => {
          if (entities[id]?.jmrId === jmrId) {
            myParticipants.push(id);
          }
        });
        participantAdapter.removeMany(state, myParticipants);
        participantAdapter.addMany(state, action.payload);
        state.fetchedId = action.meta.arg.jmrId;
        state.fetchFailed = false;
        if (refreshRequired) {
          state.hardLoading = false;
        } else {
          state.loading = false;
        }
      })
      .addCase(fetchJmrParticipants.rejected, (state, action) => {
        const { refreshRequired } = action.meta.arg;
        state.fetchFailed = true;
        if (refreshRequired) {
          state.hardLoading = false;
        } else {
          state.loading = false;
        }
      })
      .addCase(patchParticipant.pending, (state, action) => {
        const { newParticipant } = action.meta.arg;
        const { id } = newParticipant;
        participantAdapter.updateOne(state, {
          id,
          changes: newParticipant,
        });
        state.loading = true;
      })
      .addCase(patchParticipant.fulfilled, (state) => {
        state.yjsUpdateRequired = true;
        state.loading = false;
      })
      .addCase(patchParticipant.rejected, (state, action) => {
        const { oldParticipant } = action.meta.arg;
        const { id } = oldParticipant;
        participantAdapter.updateOne(state, {
          id,
          changes: oldParticipant,
        });
        state.loading = false;
      })
      .addCase(postParticipant.pending, (state, action) => {
        participantAdapter.addOne(state, action.meta.arg);
        state.loading = true;
      })
      .addCase(postParticipant.fulfilled, (state, action) => {
        const { id: oldId } = action.meta.arg;
        const { id: newId } = action.payload;
        participantAdapter.updateOne(state, {
          id: oldId,
          changes: {
            id: newId,
          },
        });
        state.yjsUpdateRequired = true;
        state.loading = false;
      })
      .addCase(postParticipant.rejected, (state, action) => {
        const participant = action.meta.arg;
        participantAdapter.removeOne(state, participant.id);
        state.loading = false;
      })
      .addCase(deleteParticipant.pending, (state, action) => {
        // Do not optimistically remove the participant because
        // YJS depends on up-to-date data
        state.loading = true;
      })
      .addCase(deleteParticipant.fulfilled, (state, action) => {
        const { participant, deleteJmr } = action.meta.arg;
        participantAdapter.removeOne(state, participant.id);
        if (deleteJmr) {
          // delete all participants on that JMR
          const jmrParticipantIds = state.ids.filter(
            (id) => state.entities[id]?.jmrId === participant.jmrId
          );
          participantAdapter.removeMany(state, jmrParticipantIds);
        } else {
          state.yjsParticipantRemoved = participant.id;
        }
        state.loading = false;
      })
      .addCase(deleteParticipant.rejected, (state, action) => {
        state.loading = false;
      })
      // Secondary reducers
      .addCase(deleteJmr.pending, (state, action) => {
        const { jmrParticipants } = action.meta.arg;
        const ids = jmrParticipants.map((participant) => participant.id);
        participantAdapter.removeMany(state, ids);
        state.loading = true;
      })
      .addCase(deleteJmr.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteJmr.rejected, (state, action) => {
        const { jmrParticipants } = action.meta.arg;
        participantAdapter.addMany(state, jmrParticipants);
        state.loading = false;
      });
  },
});

export const {
  setYjsUpdateRequired,
  setYjsParticipantRemoved,
  yjsDeleteJmr,
  clearFetchedId,
  clearPostedId,
  updateParticipant,
} = participantSlice.actions;

export default participantSlice.reducer;
