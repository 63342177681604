import { UtteranceElement } from '@jambr/collab-util';
import { useContext } from 'react';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { deleteStatusElement, getElementMetaContainer } from '../../Slate/util';
import { JmrContext } from '../../JMR/JMR';
import { JmrPermissions } from '../../../../types/participantTypes';

interface Props {
  element: UtteranceElement;
}

const Utterance = ({ element, children }: RenderElementProps & Props) => {
  const editor = useSlateStatic();
  const elementPath = ReactEditor.findPath(editor, element);
  const { containsKeyword } = element;
  const [elementMetaCont] = getElementMetaContainer(editor, elementPath);
  const {
    artifactMetaData: { startDate },
  } = elementMetaCont;
  const { permissions } = useContext(JmrContext);

  const renderUtterance = () => {
    if (!startDate)
      return (
        <div
          className='hidden'
          contentEditable={false}
          style={{
            userSelect: 'none',
          }}>
          {children}
        </div>
      );
    return (
      <div className={containsKeyword ? 'utterance utterance_keyword' : 'utterance'}>
        {permissions !== JmrPermissions.READ && (
          <span
            className={'material-icons garbage_can'}
            onClick={() => deleteStatusElement(editor, element)}
            contentEditable={false}>
            delete_outline
          </span>
        )}
        {children}
      </div>
    );
  };

  return renderUtterance();
};

export default Utterance;
