import { useContext } from 'react';
import { JmrPermissions } from '../../../types/participantTypes';
import { JmrContext } from '../JMR/JMR';

interface RestrictedElementProps {
  element: JSX.Element;
}
const RestrictedElement = ({ element }: RestrictedElementProps) => {
  const { permissions } = useContext(JmrContext);
  if (permissions !== JmrPermissions.READ) {
    return element;
  }
  return <div contentEditable={false}>{element}</div>;
};

export default RestrictedElement;
