import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { AgendaElement, CustomEditor } from '@jambr/collab-util';
import { getElementMetaContainer } from '../../Slate/util';
import { JmrContext } from '../../JMR/JMR';
import { useContext } from 'react';
import { JmrPermissions } from '../../../../types/participantTypes';
import { insertAgendaItem } from './util';
import Tooltip from '../../../Util/Tooltip';

interface Props {
  element: AgendaElement;
}

const Agenda = ({ element, children }: RenderElementProps & Props) => {
  const editor: CustomEditor = useSlateStatic();
  const { permissions } = useContext(JmrContext);
  const agendaPath = ReactEditor.findPath(editor, element);

  // This function updates the sequence numbers of the agenda items after a drag and drop.
  // It does this by mapping the element's index to its sequence number, so it assumes that
  // deleted items are at the end of the list. Bugs may arise if deleted items are not consistently
  // located at the end of the list
  const handleDragEnd = (result: DropResult) => {
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination?.index;

    if (destinationIndex === undefined) return;

    const pathToSource = [...agendaPath, sourceIndex];
    const pathToDestination = [...agendaPath, destinationIndex];

    Transforms.moveNodes(editor, {
      at: pathToSource,
      to: pathToDestination,
    });

    const lesserIndex = sourceIndex >= destinationIndex ? destinationIndex : sourceIndex;
    const greaterIndex = sourceIndex >= destinationIndex ? sourceIndex : destinationIndex;

    for (let i = lesserIndex; i <= greaterIndex; i++) {
      // Update each agenda item between source and destination so that its seqNum matches its array index
      // in the slate doc
      const [itemMetaCont, itemMetaContLoc] = getElementMetaContainer(editor, [...agendaPath, i]);

      if (!itemMetaCont || !itemMetaContLoc) return;

      Transforms.setNodes(
        editor,
        {
          artifactMetaData: {
            ...itemMetaCont.artifactMetaData,
            seqNum: i + '',
          },
        },
        {
          at: itemMetaContLoc,
        }
      );
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className='list_header' contentEditable={false}>
        <h2 className='jmr_header'>Agenda</h2>
      </div>
      <Droppable droppableId='agenda'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className='agenda'>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {permissions !== JmrPermissions.READ && (
        // Only show the button to create new agenda items to participants with
        // permission to do so
        <div
          className='list_add_wrapper list_add agenda_add'
          onClick={() => insertAgendaItem(editor, agendaPath)}>
          <span contentEditable={false} style={{ userSelect: 'none' }} className='material-icons'>
            playlist_add
            <Tooltip tooltip='add agenda item' />
          </span>
        </div>
      )}
    </DragDropContext>
  );
};

export default Agenda;
