import { capitalize } from "lodash";

export type MeetingProvider = "zoom" | "teams";

export const getMeetingProviderFromUrl = (location: string): MeetingProvider | undefined => {
    const trimLink = location.trim();
    const zoomRegex: RegExp = /^https?:\/\/(\w+\.)?zoom\.\w+\/(?:j|my)\/[0-9]+(\?pwd=\w+)?(\.\d+)?/;

    const teamsLinkRegex: RegExp = /^https?:\/\/teams\.microsoft\.com\/l\/meetup-join\//;
    const teamsLinkRegex2: RegExp = /^https?:\/\/teams\.live\.com\/(meet|dl)\//;


    if (zoomRegex.test(trimLink)) {
        return "zoom";
    } else if (teamsLinkRegex.test(trimLink) || teamsLinkRegex2.test(trimLink)) {
        return "teams";
    } else {
        return undefined;
    }
};

export const checkMeetingLinkType = (link: string): string | false => {
    const meetingProvider = getMeetingProviderFromUrl(link);
    if (!meetingProvider) return false;

    return `${capitalize(meetingProvider)} call`;
}
