export interface TActionItem {
  id: string;
  title: string;
  dueDate: string;
  assignee: string;
  percentComplete: string;
  status: TActionStatus;
  completedDate: string;
  jmrId: string;
}

export enum TActionStatus {
  Completed = 'C',
  InProgress = 'I',
  NotStarted = 'N',
}
