import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { deleteJmr, postJmrItems } from '../middlewares/jmrRequests';
import { deleteParticipant, fetchParticipants } from '../middlewares/participantRequests';
import { TParticipant } from '../types/participantTypes';
import { yjsDeleteJmr } from './participantSlice';

const previewAdapter = createEntityAdapter<TParticipant>({
  selectId: (part) => part.jmrId,
  sortComparer: (a, b) => {
    const dateOrder = Date.parse(a.startDate) - Date.parse(b.startDate);
    // If dates are not the same, sort by ascending date
    if (dateOrder !== 0) return dateOrder;
    // Sort jmrs with the same start date lexicographically
    if (a.title.localeCompare(b.title) > 0) return 1;
    return -1;
  },
});

export const initialState = previewAdapter.getInitialState({
  loading: false,
  postedId: '',
});

const previewSlice = createSlice({
  name: 'previews',
  initialState,
  reducers: {
    updatePreview(
      state,
      action: {
        payload: TParticipant;
        type: string;
      }
    ) {
      previewAdapter.updateOne(state, {
        id: action.payload.jmrId,
        changes: action.payload,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(yjsDeleteJmr, (state, action) => {
        const jmrId = action.payload;
        previewAdapter.removeOne(state, jmrId);
      })
      .addCase(fetchParticipants.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchParticipants.fulfilled, (state, action) => {
        previewAdapter.addMany(state, action.payload);
        state.loading = false;
      })
      .addCase(fetchParticipants.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteParticipant.fulfilled, (state, action) => {
        const {
          participant: { jmrId },
          deleteJmr,
        } = action.meta.arg;
        if (deleteJmr) previewAdapter.removeOne(state, jmrId);
      })
      // Secondary reducers
      .addCase(postJmrItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(postJmrItems.fulfilled, (state, action) => {
        state.postedId = action.payload.id;
        previewAdapter.addMany(state, action.payload.participants);
        state.loading = false;
      })
      .addCase(postJmrItems.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteJmr.pending, (state, action) => {
        const { jmrId } = action.meta.arg;
        previewAdapter.removeOne(state, jmrId);
        state.loading = true;
      })
      .addCase(deleteJmr.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteJmr.rejected, (state, action) => {
        // const { jmrParticipants } = action.meta.arg;
        // previewAdapter.addMany(state, jmrParticipants);
        state.loading = false;
      });
  },
});

export const { updatePreview } = previewSlice.actions;

export default previewSlice.reducer;
