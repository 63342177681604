import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';

export const searchQuery = createAsyncThunk(
  'searchQuery',
  async (args: { query: string; fields: string[] }) => {
    const { query, fields } = args;
    const encodedQuery = encodeURIComponent(query);
    const joinedFields = fields.join('-');
    const response = await axiosInstance.get('/Search/', {
      params: {
        query: encodedQuery,
        fields: joinedFields,
      },
    });
    return response.data;
  }
);
