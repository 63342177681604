import { Editor, Transforms } from 'slate';
import store from '../../../..';
import {
  AgendaItemElement,
  createAgendaItemElement,
  CustomEditor,
  CustomElementType,
  ItemStatus,
} from '@jambr/collab-util';

export const insertAgendaItem = (editor: CustomEditor, agendaPath: number[]) => {
  const { email: currentUser } = store.getState().auth;
  const validAgendaItems = Array.from(
    Editor.nodes<AgendaItemElement>(editor, {
      at: agendaPath,
      match: (node: any) =>
        node.type === CustomElementType.AgendaItem && node.itemStatus !== ItemStatus.Deleted,
    })
  );

  // The new item's sequence number will be the number of
  // agenda items in the editor that are not deleted
  const numValidItems = validAgendaItems.length;

  // Insert a new agenda node
  Transforms.insertNodes(
    editor,
    createAgendaItemElement({
      status: ItemStatus.Created,
      seqNum: numValidItems + '',
      defaultAssignee: currentUser,
    }),
    {
      at: [...agendaPath, numValidItems],
    }
  );

  // Place the cursor on the title of the new item
  const newItemStart = Editor.start(editor, [...agendaPath, numValidItems]);
  Transforms.select(editor, newItemStart);
};
