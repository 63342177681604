import { useCallback, useEffect, useState } from 'react';
import Form from '../Forms/Form';
import { TextInput } from '../Forms/Inputs';
import AccountTemplate from './AccountTemplate';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { clearAuthError, setAuthError, setCurrentUser } from '../../store/authSlice';
import { VCODE_VALIDATION } from '../Forms/_form_util';
import useConditionalInterval from '../../hooks/useConditionalInterval';
import ButtonAsLink from "../Forms/ButtonAsLink";
import { STRIPE_PAYMENT_LINK } from '../../utils/constants';
import { useHistory } from 'react-router-dom';

interface Props {
  location: any;
}

const VerifyCode = ({ location }: Props) => {
  const [loading, setLoading] = useState(false);
  const [startPolling, setStartPolling] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [email] = useState(() => location.search.split('=')[1]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(clearAuthError());
  }, [dispatch]);

  const onSubmit = (data: { code: string }) => {
    const { code } = data;
    setLoading(true);
    const trimmedEmail = email.replace('@', '-');
    Auth.confirmSignUp(trimmedEmail, code)
      .then(() => {
        setStartPolling(true);
      })
      .catch((err) => {
        dispatch(setAuthError(err));
        setLoading(false);
      });
  };

  const resendCode = () => {
    setLoading(true);
    const trimmedEmail = email.replace('@', '-');
    Auth.resendSignUp(trimmedEmail)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setAuthError(err));
        setLoading(false);
      });
  };

  const pollCurrentUser = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const { email, family_name: lname, given_name: fname, name } = user.attributes;
        dispatch(setCurrentUser({ email, fname: fname ? fname : name, lname }));
        setRedirect(true);
      })
      // Do nothing in the catch as it is expected that the first few attempts will fail
      .catch(() => {});
  }, [dispatch]);

  useConditionalInterval(startPolling, pollCurrentUser, 100);

  // Redirect to external URL when `redirect` state is true
  useEffect(() => {
    if (redirect) {
      const newWindow = window.open(`${STRIPE_PAYMENT_LINK}?prefilled_email=${email}`, '_blank');
      if (newWindow) newWindow.focus();
      history.push('/home');
    }
  }, [redirect, email, history]);

  return (
    <AccountTemplate>
      <Form showButton buttonText='VERIFY' onSubmit={onSubmit} loading={loading}>
        <TextInput name='code' label='code' validation={VCODE_VALIDATION} />
      </Form>
      <div className='account_links'>
        Didn't receive the code? <ButtonAsLink onClick={resendCode} text={"Resend it."} />
      </div>
    </AccountTemplate>
  );
};

export default VerifyCode;
