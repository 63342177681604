import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BooleanString,
  TActionItem,
  TActionStatus,
} from '@jambr/collab-util/dist/types/actionItemTypes';
import {
  deleteActionItem,
  fetchActionItems,
  patchActionItem,
  postActionItem,
} from '../middlewares/actionRequests';

const actionAdapter = createEntityAdapter<TActionItem>();

const initialState = actionAdapter.getInitialState({
  loading: false,
  fetchLoading: false,
  fetchComplete: false,
  patchingId: '',
  patchedId: '',
  selectedId: '',
});

const actionSlice = createSlice({
  name: 'actionItems',
  initialState,
  reducers: {
    setSelectedId(state, action: PayloadAction<string>) {
      state.selectedId = action.payload;
    },
    clearSelectedId(state) {
      state.selectedId = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActionItems.pending, (state, action) => {
        state.fetchLoading = true;
        state.patchedId = '';
        state.patchingId = '';
        state.selectedId = '';
      })
      .addCase(fetchActionItems.fulfilled, (state, action) => {
        actionAdapter.addMany(state, action.payload);
        state.fetchLoading = false;
        state.fetchComplete = true;
      })
      .addCase(fetchActionItems.rejected, (state) => {
        state.fetchLoading = false;
      })
      .addCase(postActionItem.pending, (state, action) => {
        const newAction = {
          title: '',
          dueDate: '',
          percentComplete: '',
          status: TActionStatus.NotStarted,
          completedDate: '',
          jmrId: '',
          isGenerated: BooleanString.False,
          ...action.meta.arg,
        };
        actionAdapter.addOne(state, newAction);
        state.loading = true;
        state.selectedId = newAction.id;
      })
      .addCase(postActionItem.fulfilled, (state, action) => {
        const oldId = action.meta.arg.id;
        const newId = action.payload.id;
        actionAdapter.updateOne(state, { id: oldId, changes: { id: newId } });
        state.loading = false;
        if (state.selectedId === oldId) state.selectedId = newId;
      })
      .addCase(postActionItem.rejected, (state, action) => {
        actionAdapter.removeOne(state, action.meta.arg.id);
        state.loading = false;
      })
      .addCase(patchActionItem.pending, (state, action) => {
        const {
          oldAction: { id },
          newAction,
        } = action.meta.arg;
        actionAdapter.updateOne(state, { id, changes: newAction });
        state.loading = true;
        state.patchedId = '';
        state.patchingId = id;
      })
      .addCase(patchActionItem.fulfilled, (state, action) => {
        state.loading = false;
        state.patchedId = action.payload.id;
        state.patchingId = '';
      })
      .addCase(patchActionItem.rejected, (state, action) => {
        const {
          newAction: { id },
          oldAction,
        } = action.meta.arg;
        actionAdapter.updateOne(state, { id, changes: oldAction });
        state.loading = false;
        state.patchedId = '';
        state.patchingId = '';
      })
      .addCase(deleteActionItem.pending, (state, action) => {
        actionAdapter.removeOne(state, action.meta.arg.id);
        state.loading = true;
      })
      .addCase(deleteActionItem.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteActionItem.rejected, (state, action) => {
        actionAdapter.addOne(state, action.meta.arg);
      });
  },
});

export default actionSlice.reducer;

export const { setSelectedId, clearSelectedId } = actionSlice.actions;
