export interface TParticipant {
  id: string;
  participant: string;
  participantType: ParticipantType;
  teamspaceId: string;
  purpose: string;
  startDate: string;
  duration: string;
  location: string;
  title: string;
  participantStatus: ParticipantStatus;
  jmrId: string;
  jmrPerms: JmrPermissions;
  isExternal: string;
}

export enum ParticipantType {
  MEETING = 'M',
  COLLABORATOR = 'C',
  OWNER = 'O',
  OWNER_LONG = 'organizer',
}

export enum ParticipantStatus {
  PENDING = 'P',
  INVITED = 'I',
  ACCEPTED = 'A',
  UNINVITED = 'U',
  DECLINED = 'D',
}

export enum JmrPermissions {
  // Permissions as they are stored in the DB
  FULL = 'F',
  MODIFY = 'M',
  READ = 'R',
  // Permissions as they are shown to the user
  FULL_LONG = 'editor',
  MODIFY_LONG = 'modifier',
  READ_LONG = 'viewer',
}

export const getTestParticipant = (data?: Partial<TParticipant>): TParticipant => {
  return {
    id: 'test-participant',
    participant: 'test@test.com',
    participantType: ParticipantType.MEETING,
    teamspaceId: '',
    purpose: 'The purpose of this meeting is to help unit test the UI',
    startDate: new Date().toISOString(),
    duration: '60',
    location: 'zoom',
    title: 'Test Meeting',
    participantStatus: ParticipantStatus.ACCEPTED,
    jmrId: 'test-jmr',
    jmrPerms: JmrPermissions.FULL,
    isExternal: 'N',
    ...data,
  };
};
