import { CustomEditor, ItemStatus } from '@jambr/collab-util';
import { BaseOperation, Editor, Location, Operation, Transforms } from 'slate';

const withPersistence = (editor: CustomEditor) => {
  const oldApply = editor.apply;

  editor.apply = (op: BaseOperation) => {
    // We do not mark elements "changed" for selection changes
    if (Operation.isSelectionOperation(op)) {
      oldApply(op);
      return;
    }

    const { path } = op;

    if (path.length <= 1) {
      oldApply(op);
      return;
    }

    let node = 'node' in op ? op.node : Editor.node(editor, op.path);

    // If the node contains the itemStatus property, we do not perform the Editor.above search
    // We also do not care about remove_node or insert_node operations on itemStatus nodes, as
    // their statuses are handled by the code in deleteStatusElement and the various insertXXXElement
    // functions
    if (Operation.isNodeOperation(op) && 'itemStatus' in node) {
      if (node.itemStatus === ItemStatus.Existed && op.type !== 'insert_node') {
        // this is a root level itemStatus node op
        setItemStatus(editor, op.path, ItemStatus.Changed);
      }
    } else {
      // We start the search for the itemStatus element at one level above the operation path
      // to account for insert_node and remove_node ops, which refer to paths which may not exist
      // in the editor. The parent path will always lead to a valid node.
      // First we look at the node at the parent path, and if it isn't an itemStatus node, we look
      // above it.
      const parentPath = op.path.slice(0, -1);
      const [node, nodePath] = Editor.node(editor, parentPath);
      if ('itemStatus' in node && node.itemStatus === ItemStatus.Existed) {
        setItemStatus(editor, nodePath, ItemStatus.Changed);
      } else {
        // Look for a parent element with status "existed"
        const nodeEntry = Editor.above(editor, {
          match: (n: any) => {
            return n.itemStatus === ItemStatus.Existed;
          },
          at: op.path.slice(0, -1),
        });

        if (nodeEntry) {
          // If there is such an element, change its status to "changed"
          setItemStatus(editor, nodeEntry[1], ItemStatus.Changed);
        }
      }
    }
    oldApply(op);
  };

  return editor;
};

export default withPersistence;

const setItemStatus = (editor: CustomEditor, at: Location, itemStatus: ItemStatus) => {
  Transforms.setNodes(
    editor,
    { itemStatus },
    {
      at,
    }
  );
};
