import { CognitoTokens } from '@jambr/auth-cognito';
import { Auth } from 'aws-amplify';
import { io, Socket } from 'socket.io-client';
import store from '../../../../index';
import {
  setError,
  setStatus,
  setSubscription,
  SubscriptionState,
} from '../../../../store/transcriptSlice';
import { TranscriptState } from '../../../../types/transcriptTypes';
import { TRANSCRIPT_ENDPOINT } from '../../../../utils/constants';
import { getMeetingProviderFromUrl } from '../../../Util/meetingProvider';

export interface TranscriptionLaunchInfo {
  jmrid: string;
  user: string;
  tokens: CognitoTokens | null;
  provider: string;
  params: string[];
}
// Roomstate
export const spawned: string = 'spawned'; // bot client is in room

// global messages
export const pub_error: string = 'pub_error'; // message indicating error from publisher such as zoom-bot

//zoombot messages
export const register_pub: string = 'register_pub'; //request
export const pub_registered: string = 'pub_registered'; // response
export const pub_message: string = 'pub_message'; // zoom-bot sends transcription message
export const pub_message_failed: string = 'pub_message_failed'; // bad message from publisher
export const pub_failed: string = 'pub_failed';

//client side message applicable to all clients
export const join_room: string = 'join_room';
export const user_joined: string = 'user_joined';
export const user_left: string = 'user_left';

//client side messages to start a transcription + responses
export const launch_transcription: string = 'launch_transcription'; // request
export const transcription_launching: string = 'transcription_launching'; // response
export const transcription_launched: string = 'transcription_launched'; // response
export const transcription_launch_failed: string = 'transcription_launch_failed'; // response
export const start_transcription: string = 'start_transcription'; // request
export const start_transcription_awaiting_registration =
  'start_transcription_awaiting_registration';
export const start_transcription_failed: string = 'start_transcription_failed'; // response
export const transcription_started: string = 'transcription_started'; // response
export const stop_transcription: string = 'stop_transcription'; // request
export const stop_transcription_failed: string = 'stop_transcription_failed'; // response
export const transcription_stopped: string = 'transcription_stopped'; // response

export const transcript_disconnected: string = 'transcript_disconnected';
export const terminate: string = 'terminate'; // message terminating the transcription
export const terminate_failed: string = 'terminate_failed'; // response if termination request fails
export const terminated: string = 'terminated'; // bot terminated

export interface ProviderData {
  jmrId: string;
  location: string;
  email: string;
  duration: string;
}

export interface RoomState {
  users: Set<string>; // map of user ids
  //*** yjs client ***
  transcriptArray: string[];
  yjsClient: any | null;
  // *** bot (publisher) ***
  transcriptionStatus: boolean;
  botClient: Socket | null;
  provider: any | null;
  // *** cleanup room ***
  teardownTimeout: any;
  teardownEmitter: any;
  // Add more state variables as needed
  launching: boolean;
}

export class TranscriptSocketProvider {
  socket: Socket | null;
  providerData: any;

  constructor(providerData: ProviderData) {
    const { jmrId, location, email, duration } = providerData;
    const provider = getMeetingProviderFromUrl(location);

    this.providerData = {
      jmrid: jmrId,
      user: email,
      tokens: null,
      provider: provider,
      params: [
        '--jmr_id',
        jmrId,
        '--url',
        !!provider ? location : '',
        '--duration',
        duration,
      ],
    };

    this.socket = null;
  }

  async init() {
    this.socket = await SetupWs(this);
  }

  sendMessage(eventType: string) {
    if (!this.socket) {
      return new Error('socket is not initialized on sendMessage');
    }
    if (!this.providerData || Object.keys(this.providerData).length === 0) {
      return new Error('providerData not initialized');
    }
    this.socket.emit(eventType, this.providerData);
  }

  sendActionMessage(message: string) {
    if (!this.socket) {
      return new Error('socket is not initialized on sendActionMessage');
    }
    this.socket.emit(message, {
      jmrid: this.providerData.jmrid,
      defaultAssignee: this.providerData.user,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  setProviderData(providerData: ProviderData) {
    const { jmrId, location, email, duration } = providerData;
    const provider = getMeetingProviderFromUrl(location);

    this.providerData = {
      jmrid: jmrId,
      user: email,
      tokens: this.providerData.tokens,
      provider: provider,
      params: [
        '--jmr_id',
        jmrId,
        '--url',
        !!provider ? location : '',
        '--duration',
        duration,
      ],
    };
  }

  disconnectProvider() {
    if (!this.socket) {
      return new Error('socket is not initialized on disconnect');
    }
    this.socket.disconnect();
  }
}

const SetupWs = async (provider: TranscriptSocketProvider) => {
  const { dispatch } = store;
  let { socket, providerData } = provider;
  const session = await Auth.currentSession();
  providerData.tokens = {
    idToken: session.getIdToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
    accessToken: session.getAccessToken().getJwtToken(),
  };
  socket = io(TRANSCRIPT_ENDPOINT, {
    timeout: 4000000, // time after which the connection is considered timed-out (same as ALB).
    auth: providerData,
    withCredentials: true,
  });
  socket.on('connect', () => {
    dispatch(setStatus(TranscriptState.Connected));
  });
  // dispatch the correct transcript status when subscribed
  socket.on(user_joined, (transcriptServerState: RoomState) => {
    let status = TranscriptState.SubscribedToStatus;

    // bot has been launched
    if (transcriptServerState.botClient) {
      // transcription is active
      if (transcriptServerState.transcriptionStatus) {
        status = TranscriptState.Started;
      } else if (transcriptServerState.launching) {
        status = TranscriptState.Launching;
      } else {
        status = TranscriptState.Stopped;
      }
    }

    dispatch(setSubscription(SubscriptionState.Subscribed));
    dispatch(setStatus(status));
  });

  socket.on('connect_error', async (error) => {
    if (error.message === 'invalid credentials') {
      const session = await Auth.currentSession();
      providerData.tokens = {
        idToken: session.getIdToken().getJwtToken(),
        refreshToken: session.getRefreshToken().getToken(),
        accessToken: session.getAccessToken().getJwtToken(),
      };
      // Update the token
      socket!.auth = providerData;
      // Reconnect
      socket!.connect();
    }
  });

  // ** very useful for debugging websocket **
  // socket.onAny((eventName, ...args) => {
  //   console.log('onAny: ', eventName, args);
  // });
  // socket.prependAny((eventName, ...args) => {
  //   console.log('prependAny: ', eventName, args);
  // });

  socket.on('connect_error', () => {
    dispatch(setError('subscribed_to_status_failed'));
    dispatch(setSubscription(SubscriptionState.NotSubscribed));
  });

  socket.on(transcription_launched, () => dispatch(setStatus(TranscriptState.Launched)));
  socket.on(transcription_started, () => dispatch(setStatus(TranscriptState.Started)));
  socket.on(transcription_stopped, () => dispatch(setStatus(TranscriptState.Stopped)));
  socket.on(transcription_launch_failed, () => dispatch(setError('transcription launch failed')));
  socket.on(transcript_disconnected, () => dispatch(setError('bot has left the call')));
  socket.on(start_transcription_failed, () => dispatch(setError('transcription start failed')));
  socket.on(stop_transcription_failed, () => dispatch(setError('transcription stop failed')));
  // bot has failed after connecting
  socket.on(pub_error, () => {
    dispatch(setError('Jambr bot is having issues joining the call'));
    dispatch(setStatus(TranscriptState.Errored));
    dispatch(setSubscription(SubscriptionState.NotSubscribed));
    provider.sendMessage(terminate);
  });
  // bot has failed to launch
  socket.on(pub_failed, () => {
    dispatch(setError('failed to join video conference call'));
    dispatch(setStatus(TranscriptState.Errored));
    dispatch(setSubscription(SubscriptionState.NotSubscribed));
    provider.sendMessage(terminate);
  });

  socket.on(terminated, () => dispatch(setStatus(TranscriptState.Terminated)));
  socket.on(terminate_failed, () => {
    dispatch(setError('terminated failed'));
    dispatch(setStatus(TranscriptState.Errored));
  });

  socket.on('disconnect', (reason) => {
    if (reason === 'io server disconnect' && socket) {
      socket.connect();
    } else {
      // The socket will automatically attempt to reconnect
    }

    dispatch(setStatus(TranscriptState.Disconnected));
    dispatch(setSubscription(SubscriptionState.NotSubscribed));
  });

  socket.on(transcript_disconnected, () => dispatch(setStatus(TranscriptState.Terminated)));

  return socket;
};
