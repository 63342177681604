import { combineReducers } from 'redux';
import authSlice from './authSlice';
import transcriptSlice from './transcriptSlice';
import participantSlice from './participantSlice';
import searchSlice from './searchSlice';
import awarenessSlice from './awarenessSlice';
import previewSlice from './previewSlice';
import actionSlice from './actionSlice';
import errorSlice from './errorSlice';
import shareSlice from './shareSlice';

const appReducer = combineReducers({
  transcript: transcriptSlice,
  auth: authSlice,
  participants: participantSlice,
  previews: previewSlice,
  search: searchSlice,
  awareness: awarenessSlice,
  actionItems: actionSlice,
  error: errorSlice,
  share: shareSlice,
});

/*
 *   This allows us to clear the entire app state. It covers
 *   the situation where a user logs in, changes some state,
 *   logs out, and then a new user logs in. The new user should
 *   not see the old user's state.
 */
export const rootReducer = (state: any, action: any) => {
  if (action.type === 'CLEAR_APP_STATE') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type AppState = ReturnType<typeof rootReducer>;
