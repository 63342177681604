import { useContext, useState } from 'react';
import * as encoding from 'lib0/encoding';
import { DELETE_JMR } from '../../../../utils/y-websocket';
import OutsideClickDetector from '../../../Util/OutsideClickDetector';
import { YjsSocketContext } from '../Client';

const JmrDeleteModal = () => {
  const ws = useContext(YjsSocketContext);
  const [showControls, setShowControls] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const deleteConfirmed = () => {
    const encoder = encoding.createEncoder();
    encoding.writeVarUint(encoder, DELETE_JMR);
    ws.send(encoding.toUint8Array(encoder));
    setDeleteLoading(true);
  };

  return (
    <>
      <span
        className={
          showControls ? 'material-icons garbage_can opacity_full' : 'material-icons garbage_can'
        }
        onClick={() => setShowControls(!showControls)}>
        delete_outline
      </span>
      {showControls && (
        <OutsideClickDetector callback={() => setShowControls(false)}>
          <div className='jmr_modal jmr_delete_modal'>
            <div className='jmr_modal_pointer jmr_delete_modal_pointer' />
            <div>
              <span className='jmr_modal_title'>
                Are you sure you want to <b>delete this JMR?</b>
              </span>
            </div>
            <div className='meta_text_button_container'>
              <div
                className='meta_button confirm_button_colors'
                onClick={() => setShowControls(false)}>
                No
              </div>
              {deleteLoading ? (
                <div className='spin_loader' />
              ) : (
                <div className='meta_button delete_button_colors' onClick={deleteConfirmed}>
                  Yes
                </div>
              )}
            </div>
          </div>
        </OutsideClickDetector>
      )}
    </>
  );
};

export default JmrDeleteModal;
