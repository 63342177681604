//error messages
export enum eMessage {
  yTimeoutMessage = 'Your connection to the server has timed out due to inactivity.',
  yAbnormalClosureMessage = 'There seems to be a problem connecting to the server.',
  unknownDD = 'You experienced an unknown error trying to connect to the document.',
  unauthorizedMessage = 'You are unauthorized to access this document. If you believe this is a mistake, contact the document owner.',
  empty = '',
}

//ws close codes

export const normalClosure = 1000;
export enum wsCloseCodes {
  unauthorized = 3000,
  internalError = 1011,
  serviceRestart = 1012,
  tryAgainLater = 1013,
  normalClosure = 1000,
  goingAway = 1001,
  abnormalClosure = 1006,
  noStatusReceived = 1005,
}
