export const PW_VALIDATION = {
  required: 'password is required',
  minLength: {
    value: 8,
    message: 'password must have at least 8 characters',
  },
  // pattern: {
  //   // at least one upper, lower, number, special character. min 8
  //   value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?])\S{8,256}/g,

  //   message: 'invalid password',
  // },
};

export const VCODE_VALIDATION = {
  required: 'code is required',
  minLength: {
    value: 6,
    message: 'code must be 6 numbers',
  },
  pattern: {
    value: /^[0-9]*$/,
    message: 'code must contain only numbers',
  },
};

export const EMAIL_VALIDATION = {
  required: 'email is required',
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'not a valid email',
  },
};
