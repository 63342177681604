import { useContext, useMemo } from 'react';
import { MetaContext } from '../Meta';
import MetaInput from '../MetaInput';
import { checkMeetingLinkType } from '../../../../Util/meetingProvider';

const MetaLocation = () => {
  const { getMetaData } = useContext(MetaContext);
  const location = getMetaData();
  const meetingLinkType = useMemo(() => checkMeetingLinkType(location), [location]);

  // TODO: there is some ambiguity here, if the changes are not saved, should the user goto the new location or the old location?
  return (
    <div className='meta_location'>
      {meetingLinkType && (
        <span className='meta_zoom_prompt'>
          Go to{' '}
          <a target='_blank' rel='noopener noreferrer' href={location}>
            {meetingLinkType}
          </a>
        </span>
      )}
      <MetaInput
        validation={{
          required: 'location is required',
        }}
        type='text'
        label='new location'
      />
    </div>
  );
};

export default MetaLocation;
