import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { fetchParticipants } from '../../../middlewares/participantRequests';
import Preview from './Preview';
import { clearQuery } from '../../../store/searchSlice';
import { Redirect, useLocation } from 'react-router-dom';
import { TParticipant } from "../../../types/participantTypes";
import { EntityId } from "@reduxjs/toolkit";
import { TEsResult } from "../../../types/searchTypes";

const PreviewList = ({ closeNav }: { closeNav: () => void }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { email: currentUser } = useAppSelector((state) => state.auth);
  const { entities, loading, ids } = useAppSelector((state) => state.previews);
  const {
    entities: searchResults,
    ids: searchIds,
    query,
    loading: searchLoading,
  } = useAppSelector((state) => state.search);

  // If no JMR is selected, redirect to the first one in the list of ids
  // which will be the one with the earliest startDate.
  const pathElements: string[] = location.pathname.split('/');
  const pathTerminal: string = pathElements[pathElements.length - 1];
  const redirect: boolean = pathTerminal === '' || pathTerminal === 'jmr' || pathTerminal === 'home';

  // This function returns the jmr with the date closest to right now
  const nearestJmrId = () => {
    const nowDate = new Date();
    // ids are ordered by start date, so the first participant with a start date
    // greater than the current date is the nearest to the current date.
    let nearestPart: TParticipant | undefined = entities[ids[0]];
    const nearestPartId: EntityId | undefined= ids.find((id: EntityId) => {
      const part: TParticipant | undefined = entities[id];
      if (part && part.participant === currentUser) {
        nearestPart = part;
        if (new Date(part.startDate).getTime() > nowDate.getTime()) return true;
      }
      return false;
    });

    if (nearestPartId) {
      return entities[nearestPartId]?.jmrId;
    }
    return nearestPart?.jmrId;
  };

  const scrollToSelected = useCallback(() => {
    // Scroll to the currently selected preview
    const pathElements: string[] = location.pathname.split('/');
    const pathId: string = pathElements[pathElements.length - 1];
    const listElement: HTMLElement | null = document.getElementById('preview_list');
    const selectedElement: HTMLElement | null = document.getElementById(pathId + '_preview');
    if (listElement && selectedElement) {
      listElement.scrollTo({
        top: selectedElement.offsetTop - 63,
        behavior: 'smooth',
      });
    }
  }, [location]);

  useEffect(() => {
    scrollToSelected();
  }, [location, query, scrollToSelected]);

  useEffect(() => {
    dispatch(fetchParticipants());
  }, [dispatch]);

  const renderJmrs = () => {
    const jmrPreviews: JSX.Element[] = [];

    // Set to start of the day for accurate comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayTime = today.getTime();

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowTime = tomorrow.getTime();

    type Tense = 'past' | 'today' | 'tomorrow' | 'future';
    const getTense = (date: Date): Tense => {
      const dateTime = date.getTime();
      if (dateTime < todayTime) return 'past';
      if (dateTime === todayTime) return 'today';
      if (dateTime === tomorrowTime) return 'tomorrow';
      return 'future';
    };

    // Flags to ensure headers are only added once
    type HeaderAdded = {
      past: boolean;
      today: boolean;
      tomorrow: boolean;
      future: boolean;
    };
    let headerAdded: HeaderAdded = { past: false, today: false, tomorrow: false, future: false };

    ids.forEach((id: EntityId) => {
      const participant: TParticipant | undefined = entities[id];
      if (participant && participant.participant === currentUser) {
        const startDate: Date = new Date(participant.startDate);
        startDate.setHours(0, 0, 0, 0);

        const tense: Tense = getTense(startDate);

        // Add headers for each section as needed
        if (!headerAdded[tense]) {
          jmrPreviews.push(<PreviewHeader tense={tense} key={tense} />);
          headerAdded[tense] = true;
        }

        // Add the preview component
        jmrPreviews.push(
          <Preview
            key={id}
            previewEntity={participant}
            renderCallback={scrollToSelected}
            closeNav={closeNav}
          />
        );
      }
    });
    return jmrPreviews;
  };

  const setHeaderShadow = () => {
    const headerElements: HTMLCollectionOf<Element> = document.getElementsByClassName('preview_header');
    const listElementTop: number  | undefined = document.getElementById('preview_list')?.getBoundingClientRect().top;

    if (listElementTop && headerElements) {
      let topHeader;
      for (const header of headerElements) {
        const headerTop: number = header.getBoundingClientRect().top;
        (header as HTMLElement).style.boxShadow = '';
        if (headerTop <= listElementTop + 15) {
          topHeader = header;
        } else if (headerTop < listElementTop + 60 && headerTop > listElementTop + 15) {
          (header as HTMLElement).style.boxShadow = '0px 0px 10px rgb(150, 150, 150)';
        }
      }
      if (topHeader) (topHeader as HTMLElement).style.boxShadow = '0px 5px 10px rgb(150, 150, 150)';
    }
  };

  const renderSearchResults = () => {
    const resultElements: JSX.Element[] = [
      <div key='search_header' className='preview_header'>
        Search Results
        <span
          onClick={() => dispatch(clearQuery())}
          className='material-icons'>
          arrow_back
        </span>
      </div>,
    ];

    if (searchLoading) return resultElements;

    if (searchIds.length === 0) {
      resultElements.push(
        <p key='no_results' className='search_no_results'>
          No results for "{query}"
        </p>
      );
      return resultElements;
    }

    searchIds.forEach((id: EntityId) => {
      const result:TEsResult | undefined= searchResults[id];
      if (result && result.objectType === 'JMR')
        resultElements.push(
          <Preview
            key={id}
            previewEntity={result}
            renderCallback={scrollToSelected}
            closeNav={closeNav}
          />
        );
      else if (result && result.objectType === 'AGN') {
        resultElements.push(
          <Preview
            key={id}
            previewEntity={result.jmr}
            agendaItem={result}
            renderCallback={scrollToSelected}
            closeNav={closeNav}
          />
        );
      }
    });

    return resultElements;
  };

  return (
    <div className='preview_list' id='preview_list' onScroll={setHeaderShadow}>
      {query ? renderSearchResults() : renderJmrs()}
      {((loading && ids.length === 0) || searchLoading) && (
        <div className='preview_loader_container'>
          <div className='spin_loader preview_loader' />
        </div>
      )}
      {redirect && ids.length > 0 && (
        <Redirect
          to={{
            pathname: '/home/jmr/' + nearestJmrId(),
          }}
        />
      )}
    </div>
  );
};

export default PreviewList;

interface PreviewHeaderProps {
  tense: 'past' | 'today' | 'tomorrow' | 'future' | 'search';
}

const PreviewHeader = ({ tense }: PreviewHeaderProps) => {
  const headerContent = () => {
    switch (tense) {
      case 'past':
        return 'Past Meetings';
      case 'today':
        return "Today's Meetings";
      case 'tomorrow':
        return "Tomorrow's Meetings";
      case 'future':
        return 'Future Meetings';
    }
  };

  return (
    <div key={tense} id={tense} className='preview_header'>
      {headerContent()}
    </div>
  );
};
