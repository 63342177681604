import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Editor, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { useAppSelector } from '../../../../hooks/hooks';
import { JmrPermissions } from '../../../../types/participantTypes';
import {
  CustomEditor,
  CustomElementType,
  MetaContainerElement,
  MetaElement,
  MetaField,
  MetaInputType,
  ObjectType,
} from '@jambr/collab-util';
import { JmrContext } from '../../JMR/JMR';
import Tooltip from '../../../Util/Tooltip';
import MetaModal from './MetaModal';
import { convertDate, convertTime } from '../../../Util/dateUtil';
import { IS_EXTERNAL } from '../../../../types/jmrTypes';
import { checkMeetingLinkType } from '../../../Util/meetingProvider';

interface Props {
  element: MetaElement;
}

interface TMetaApi {
  showModal: boolean;
  closeModal: () => void;
  setMetaData: (newData: string) => void;
  getMetaData: () => string;
  metaId: string;
}

export const MetaContext = createContext<TMetaApi>({
  showModal: false,
  closeModal: () => {},
  setMetaData: (newData: string) => {},
  getMetaData: () => '',
  metaId: '',
});

const EXTERNAL_DISABLED_FIELDS = [
  MetaField.StartDate,
  MetaField.EndDate,
  MetaField.Duration,
  MetaField.Location,
];

const Meta = ({ children, element }: RenderElementProps & Props) => {
  const editor: CustomEditor = useSlateStatic();
  const elementPath = ReactEditor.findPath(editor, element);
  const { type, field, id } = element.artifactMetaId;
  const { inputType } = element;
  const metaId = type + '.' + field + '.' + id;
  const { permissions, jmrParticipants, currentParticipant } = useContext(JmrContext);
  const { email: currentUser } = useAppSelector((state) => state.auth);

  const isCurrentParticipantEmpty = Object.keys(currentParticipant).length === 0;
  const { isExternal } = currentParticipant;
  const isDisabledField =
    isExternal === IS_EXTERNAL.YES && EXTERNAL_DISABLED_FIELDS.includes(field);

  const [metaContainerEl, metaContainerLoc] = useMemo(() => {
    const nodeEntry = Editor.above<MetaContainerElement>(editor, {
      at: elementPath,
      match: (node: any) => node.type === CustomElementType.MetaContainer,
    });
    if (nodeEntry) return nodeEntry;
    throw new Error('Unable to find meta container for element at path ' + elementPath.toString());
  }, [editor, elementPath]);

  const [itemElement, itemLocation] = useMemo(() => {
    const nodeEntry = Editor.above(editor, {
      at: elementPath,
      match: (node: any) =>
        node.type === CustomElementType.ActionItem || node.type === CustomElementType.AgendaItem,
    });
    if (nodeEntry) return nodeEntry;
    return [undefined, undefined];
  }, [editor, elementPath]);

  // The following constructs an API for altering meta data and controlling
  // the MetaModal which will be passed to MetaModal and MetaCustomInputs as context
  const [showModal, setShowModal] = useState(false);

  // The function for closing the modal when the user clicks away, submits, or cancels
  // the MetaCustomInput
  const closeModal = useCallback(() => {
    setShowModal(false);
    // if (itemLocation) Transforms.select(editor, itemLocation);
  }, []);

  // The function for altering the actual meta data on the meta container
  const setMetaData = useCallback(
    (newData: string) => {
      const artifactMetaData = {
        ...metaContainerEl?.artifactMetaData,
      };
      artifactMetaData[field] = newData;
      Transforms.setNodes(
        editor,
        {
          artifactMetaData,
        },
        {
          at: metaContainerLoc,
        }
      );
      // selecting and inserting text to provoke a re-render for remote edits.
      // Text is deleted and then inserted as per the Editor.insertText func.
      Transforms.select(editor, elementPath);
      Editor.insertText(editor, 'renderText');
    },
    [editor, metaContainerLoc, metaContainerEl, field, elementPath]
  );

  // The function for retrieving the current state of the meta data on the meta container.
  // This is used in the date and time pickers to generate the new date
  const getMetaData = useCallback(() => {
    const metaData = metaContainerEl.artifactMetaData[field];
    if (metaData) return metaData;
    return '';
  }, [metaContainerEl, field]);

  // The API object which will be provided as context to children
  const metaApi = useMemo(
    () => ({
      showModal,
      closeModal,
      setMetaData,
      getMetaData,
      metaId,
    }),
    [showModal, closeModal, setMetaData, getMetaData, metaId]
  );

  return (
    <>
      {(inputType !== MetaInputType.participants || !isCurrentParticipantEmpty) && (
        <div className={computeClasses()} id={metaId} onClick={selectMeta}>
          {renderMetaModal()}
          <span className='material-icons'>{element.icon}</span>
          <div className='meta_data'>{renderMetaData()}</div>
          <div className='hidden'>{children}</div>
          <Tooltip>{getTooltip()}</Tooltip>
        </div>
      )}
    </>
  );

  // A function for determining which css classes to apply to a meta chip depending
  // on the context in which it is rendered
  function computeClasses() {
    const metaCss = ['meta_base'];

    if (
      (permissions !== JmrPermissions.READ ||
        (inputType === MetaInputType.participants && !isCurrentParticipantEmpty)) &&
      inputType !== MetaInputType.none &&
      type !== ObjectType.Transcript &&
      !isDisabledField
    ) {
      metaCss.push('meta_hover');
    }

    // If the modal is open, the meta chip should appear darkened like it does
    // on hover
    if (showModal) metaCss.push('meta_darkened');

    if (type === ObjectType.Agenda) {
      metaCss.push('meta_small');

      if (permissions === JmrPermissions.READ || showModal) {
        metaCss.push('meta_colors_active');
      } else {
        metaCss.push('meta_colors_inactive');
      }
    } else if (type === ObjectType.Action || type === ObjectType.Transcript) {
      metaCss.push('meta_small meta_colors_active');
    } else if (type === ObjectType.Jmr) {
      metaCss.push('meta_large meta_colors_active');
    }

    return metaCss.join(' ');
  }

  function selectMeta() {
    if (isDisabledField) return;

    if (inputType !== MetaInputType.none && type !== ObjectType.Transcript) {
      // We don't want to show the participant modal to users who aren't participants on the JMR
      if (isCurrentParticipantEmpty && inputType === MetaInputType.participants) {
        return;
      }
      setShowModal(true);
    }

    if (!itemElement || !itemLocation) return;

    const startOfParent = Editor.start(editor, itemLocation);
    Transforms.select(editor, startOfParent);
  }

  function renderMetaData() {
    if (field === MetaField.Generated) {
      return;
    }

    const rawMetaData = metaContainerEl.artifactMetaData[field];
    if (!rawMetaData) return <span className='meta_data'>{element.placeholder}</span>;

    let processedMetaData;

    switch (inputType) {
      case MetaInputType.participants:
        processedMetaData =
          jmrParticipants.length === 1 ? '1 participant' : jmrParticipants.length + ' participants';
        break;
      case MetaInputType.location:
        const meetingCall = checkMeetingLinkType(rawMetaData)
        processedMetaData = meetingCall || rawMetaData;
        break;
      case MetaInputType.none:
      case MetaInputType.assignee:
        processedMetaData = rawMetaData === currentUser ? rawMetaData + ' (you)' : rawMetaData;
        break;
      case MetaInputType.duration:
        processedMetaData = rawMetaData + 'm';
        break;
      case MetaInputType.datePicker:
        processedMetaData = convertDate(new Date(rawMetaData));
        break;
      case MetaInputType.timePicker:
        processedMetaData = convertTime(new Date(rawMetaData));
        break;
    }

    if ((processedMetaData as string).length > 30) return (processedMetaData as string).substring(0, 30) + '...';

    return <span className='meta_data'>{processedMetaData}</span>;
  }

  function renderMetaModal() {
    // When JMRs are generated from an external calendar, we disable certain fields
    if (isDisabledField) return;

    if (permissions === JmrPermissions.READ && element.inputType !== MetaInputType.participants)
      // MetaModals should not be shown to read-only users, except the participant modal
      return;

    if (!showModal) return;

    return (
      <MetaContext.Provider value={metaApi}>
        <MetaModal inputType={element.inputType} />
      </MetaContext.Provider>
    );
  }

  function getTooltip() {
    if (isDisabledField) {
      return (
        <span style={{ fontSize: '0.8rem' }}>
          {element.tooltip} - This can only be changed
          <br />
          on the original calendar event
        </span>
      );
    }

    return element.tooltip;
  }
};

export default Meta;
