import React, { useContext, useEffect } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { MetaContext as CollabMetaContext } from './Meta';

interface Props {
  validation: RegisterOptions;
  type: 'text' | 'time' | 'date';
  label: string;
  children?: React.ReactNode;
  customSubmit?: (data: { newData: any }) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  datalistOptions?: any[];
  clearButton?: boolean;
}
const MetaInput = ({
  children,
  validation,
  type,
  label,
  customSubmit,
  onChange,
  datalistOptions,
  clearButton,
}: Props) => {
  const { closeModal, setMetaData, getMetaData, metaId } = useContext(CollabMetaContext);

  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      newData: getMetaData(),
    },
  });

  const onSubmit = (data: { newData: string }) => {
    const { newData } = data;
    if (customSubmit) {
      customSubmit({
        newData,
      });
    } else {
      setMetaData(newData);
      closeModal();
    }
  };

  const resetCustom = () => {
    reset({
      newData: '',
    });
    closeModal();
  };

  useEffect(() => {
    const metaInputElement = document.getElementById(metaId + '.input');
    if (!metaInputElement) return;
    metaInputElement.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='meta_input'>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className='meta_field'>
          <input
            id={metaId + '.input'}
            type={type}
            ref={register(validation)}
            list='datalist'
            name='newData'
            required
            autoComplete='off'
            onChange={onChange}
          />
          <label htmlFor='newData'>
            <span>{label}</span>
          </label>
        </div>
        {datalistOptions && (
          <datalist id='datalist'>
            {datalistOptions.map((option, key) => (
              <option value={option} key={key}></option>
            ))}
          </datalist>
        )}
      </form>
      {errors && errors['newData'] && <div className='error'>{errors['newData'].message}</div>}
      {children}
      <div className='meta_text_button_container'>
        {clearButton && (
          <div
            onClick={() =>
              onSubmit({
                newData: '',
              })
            }
            className='meta_button cancel_button_colors'>
            clear
          </div>
        )}
        <div onClick={resetCustom} className='meta_button cancel_button_colors'>
          cancel
        </div>
        <div onClick={handleSubmit(onSubmit)} className='meta_button confirm_button_colors'>
          confirm
        </div>
      </div>
    </div>
  );
};

export default MetaInput;
