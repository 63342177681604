import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCurrentUser } from '../middlewares/userRequests';
import { TAuthError, TSubStatus } from '../types/authType';

interface TAuthState {
  error: TAuthError;
  email: string;
  fname: string;
  lname: string;
  subStatus: TSubStatus | null;
  loading: boolean;
}

const initialState: TAuthState = {
  error: {},
  email: '',
  fname: '',
  lname: '',
  loading: false,
  subStatus: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthError(state, action: PayloadAction<TAuthError>) {
      return {
        ...state,
        error: action.payload,
      };
    },
    clearAuthError(state) {
      return {
        ...state,
        error: {} as TAuthError,
      };
    },
    setCurrentUser(
      state,
      action: PayloadAction<{
        email: string;
        fname: string;
        lname: string;
      }>
    ) {
      const { email, fname, lname } = action.payload;
      return { ...state, email, fname, lname };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        const { subscriptionStatus } = action.payload;

        if (subscriptionStatus) {
          state.subStatus = subscriptionStatus;
        }
        else {
          state.subStatus = TSubStatus.Unsubscribed;
        }

        state.loading = false;
      })
      .addCase(fetchCurrentUser.rejected, (state) => {
        state.subStatus = null;
        state.loading = false;
      });
  },
});

export const { setAuthError, clearAuthError, setCurrentUser } = authSlice.actions;
export default authSlice.reducer;
