import { isNumeric } from '../../../../../dev-utils/util';
import MetaInput from '../MetaInput';

const MetaDuration = () => {
  const renderOptions = () => {
    const options = [];

    for (let i = 5; i <= 20; i += 5) {
      options.push(i);
    }

    return options;
  };

  const validate = (data: string) => {
    if (!isNumeric(data)) return 'must be a number (or empty)';
    if (parseInt(data) < 0) return 'must be a positive number';
    if (data.includes('.')) return 'must be a whole number';
    return true;
  };

  return (
    <MetaInput
      validation={{
        required: false,
        max: {
          value: 999,
          message: "that's much too long",
        },
        validate,
      }}
      type='text'
      label='new duration'
      datalistOptions={renderOptions()}
      clearButton
    />
  );
};

export default MetaDuration;
