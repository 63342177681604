import React from 'react';
import jambr_logo from '../../assets/images/jambr_logo.png';
import ErrorNotification from '../Forms/ErrorNotification';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const AccountTemplate = ({ children, className }: Props) => {
  return (
    <div className='gradient_background'>
      <div className={'custom_form ' + className}>
        <div className='jambr_logo'>
          <img src={jambr_logo} alt='Jambr logo' />
        </div>
        {children}
        <ErrorNotification />
      </div>
    </div>
  );
};

export default AccountTemplate;
