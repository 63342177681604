import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JmrPermissions } from '../types/participantTypes';

interface TShareState {
  shareId: string;
  sharePerms: JmrPermissions;
  shareLoading: boolean;
}

const initialState: TShareState = {
  shareId: '',
  sharePerms: JmrPermissions.READ,
  shareLoading: true,
};

const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    setShareLoading(state, action: PayloadAction<boolean>) {
      return { ...state, shareLoading: action.payload };
    },
    setShareData(state, action: PayloadAction<TShareState>) {
      return {
        shareId: action.payload.shareId,
        sharePerms: action.payload.sharePerms,
        shareLoading: false,
      };
    },
  },
});

export const { setShareData, setShareLoading } = shareSlice.actions;
export default shareSlice.reducer;
