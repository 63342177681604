import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { TParticipant } from '../types/participantTypes';

export const fetchParticipants = createAsyncThunk('fetchParticipants', async () => {
  const response = await axiosInstance.get('/Participants');
  return response.data;
});

export const fetchJmrParticipants = createAsyncThunk(
  'fetchJmrParticipants',
  async (arg: { jmrId: string; refreshRequired: boolean }) => {
    const response = await axiosInstance.get(`/Jmr/${arg.jmrId}/Participants`);
    return response.data;
  }
);

export const patchParticipant = createAsyncThunk(
  'patchParticipant',
  async (args: { oldParticipant: TParticipant; newParticipant: TParticipant }) => {
    const { id, jmrId, ...withoutIds } = args.newParticipant;
    const response = await axiosInstance.patch(`/Jmr/${jmrId}/Participants/${id}`, {
      ...withoutIds,
      purpose: encodeURIComponent(withoutIds.purpose),
      title: encodeURIComponent(withoutIds.title),
      location: encodeURIComponent(withoutIds.location),
    });
    return response.data;
  }
);

export const postParticipant = createAsyncThunk(
  'postParticipant',
  async (participant: TParticipant) => {
    const { jmrId, ...withoutJmrId } = participant;
    const response = await axiosInstance.post(`/Jmr/${jmrId}/Participants`, {
      ...withoutJmrId,
      purpose: encodeURIComponent(withoutJmrId.purpose),
      title: encodeURIComponent(withoutJmrId.title),
      location: encodeURIComponent(withoutJmrId.location),
    });
    return response.data;
  }
);

export const deleteParticipant = createAsyncThunk(
  'deleteParticipant',
  async (args: { participant: TParticipant; deleteJmr?: boolean }) => {
    const { participant } = args;
    const { id, jmrId } = participant;
    const response = await axiosInstance.delete(`/Jmr/${jmrId}/Participants/${id}`);
    return response.data;
  }
);
