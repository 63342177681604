import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setCurrentUser } from '../../../store/authSlice';
import { TParticipant } from '../../../types/participantTypes';
import ErrorBoundaryWithRouter from '../../Util/ErrorBoundary';
import Client from './Client';
import { JmrContext } from './JMR';
import { positionJmrOptions } from './positionUtil';

const ShareJmr = ({ noParticipant }: { noParticipant?: boolean }) => {
  const { jmrId, shareId } = useParams<{
    jmrId: string;
    shareId: string;
  }>();
  const { sharePerms } = useAppSelector((state) => state.share);
  const { email: currentUser } = useAppSelector((state) => state.auth);
  const [authLoading, setAuthLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { error } = useAppSelector((state) => state.error);

  const handleScroll = () => {
    positionJmrOptions();
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const { email, family_name: lname, given_name: fname, name } = user.attributes;
        dispatch(setCurrentUser({ email, fname: fname ? fname : name, lname }));
        setAuthLoading(false);
      })
      .catch((e) => {
        // User is not logged in, render the ShareJmr normally
        setAuthLoading(false);
      });
  }, [dispatch]);

  const contextValue = {
    currentParticipant: {} as TParticipant,
    jmrParticipants: [],
    jmrId,
    shareId,
    permissions: sharePerms,
  };

  const throwErrors = () => {
    if (error) {
      throw new Error(error);
    }
  };

  const handleDismiss = () => {
    const promptElement = document.getElementById('sign_up_prompt');
    if (!promptElement) return;
    promptElement.classList.remove('sign_up_prompt_slide_up');
  };

  return (
    <>
      {authLoading ? (
        <div className='spin_loader spin_loader_middle_fixed' />
      ) : currentUser && !noParticipant ? (
        <Redirect
          to={{
            pathname: `/home/jmr/${jmrId}/${shareId}`,
          }}
        />
      ) : (
        <ErrorBoundaryWithRouter>
          <div className='editor_container' id='editor_container' onScroll={handleScroll}>
            {throwErrors()}
            <JmrContext.Provider value={contextValue}>
              <Client />
            </JmrContext.Provider>
            {!currentUser && (
              <div id='sign_up_prompt' className='sign_up_prompt_slide_up'>
                <span className='sign_up_prompt_dismiss material-icons' onClick={handleDismiss}>
                  close
                </span>
                <span>Enjoying Jambr? Start your free trial </span>
                <span className='material-icons sign_up_prompt_arrow'>arrow_forward</span>
                <Link to={'/signup'} target='_blank' rel='noopener noreferrer'>
                  <button>Sign up</button>
                </Link>
              </div>
            )}
          </div>
        </ErrorBoundaryWithRouter>
      )}
    </>
  );
};

export default ShareJmr;
