import { Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';

const ProtectedRoute = ({
  component: Component,
  ...rest
}: any & {
  component: any;
}) => {
  const [redirect, setRedirect] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setShowContent(true);
      })
      .catch(() => {
        setRedirect(true);
        setShowContent(true);
      });
  }, []);

  return (
    <>
      {showContent ? (
        <Route
          {...rest}
          render={(props) => {
            return redirect ? (
              <Redirect
                to={{
                  pathname: '/login',
                  state: {
                    from: props.location,
                  },
                }}
              />
            ) : (
              <Component {...props} />
            );
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProtectedRoute;
