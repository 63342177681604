import { positionEditor, positionJmrOptions, positionToolbar } from '../../JMR/positionUtil';
import { TRANSCRIPT_WIDTH } from './Transcript';
import { EditorContainerRange, getEditorContainerRange } from './transcriptBindUtil';

export const toggleTranscript = (showTranscript: boolean) => {
  // get the editor width range which determines view mode
  const ecr = getEditorContainerRange();
  switch (ecr) {
    case EditorContainerRange.Large:
      toggleTranscriptLarge(showTranscript);
      break;
    case EditorContainerRange.Medium:
      toggleTranscriptMedium(showTranscript);
      break;
    case EditorContainerRange.Small:
      toggleTranscriptSmall(showTranscript);
      break;
  }
};

export const toggleTranscriptLarge = (showTranscript: boolean) => {
  // The transcript will slide out from the right as the editor slides to the left
  // The editor width will shrink if the combined width of the transcript and the editor
  // would exceed the available space in the the editorContainer, which is the editorContainer's
  // width, minus its left and right padding.
  const editorContainerElement = document.getElementById('editor_container');
  const editorWrapperElement = document.getElementById('editor_wrapper');
  const transcriptWrapperElement = document.getElementById('transcript_wrapper');

  if (!editorContainerElement || !editorWrapperElement || !transcriptWrapperElement) return;

  if (!showTranscript) {
    // Set transcript expanded attribute to true
    transcriptWrapperElement.setAttribute('expanded', 'false');
    // Hide the transcript by moving the editor over it
    editorWrapperElement.style.marginRight = '0';
    editorWrapperElement.style.width = 'clamp(360px, 100%, 840px)';
    const editorRightOffset =
      window.innerWidth - editorWrapperElement.getBoundingClientRect().right;
    transcriptWrapperElement.style.right = editorRightOffset + 'px';
  } else {
    // Set transcript expanded attribute to false
    transcriptWrapperElement.setAttribute('expanded', 'true');
    // Determine total width of editor and transcript after transformation
    const editorWidth = editorWrapperElement.offsetWidth;
    const totalWidth = TRANSCRIPT_WIDTH + editorWidth;
    // Determine available width
    const editorContainerPadding = window.getComputedStyle(editorContainerElement).paddingLeft;
    const availableWidth =
      editorContainerElement.offsetWidth - parseInt(editorContainerPadding) * 2;
    const excessWidth = availableWidth - totalWidth;

    // Calculate half of scrollbar width
    const scrollbarWidth = editorContainerElement.offsetWidth - editorContainerElement.clientWidth;

    if (excessWidth < 0) {
      const newEditorWidth = editorWidth + excessWidth;
      editorWrapperElement.style.width = newEditorWidth + 'px';
      transcriptWrapperElement.style.right =
        parseInt(editorContainerPadding) + scrollbarWidth / 2 + 'px';
    } else {
      editorWrapperElement.style.width = 'clamp(360px, 100%, 840px)';
      transcriptWrapperElement.style.right =
        excessWidth / 2 + parseInt(editorContainerPadding) + scrollbarWidth / 2 + 'px';
    }

    editorWrapperElement.style.marginRight = TRANSCRIPT_WIDTH + 'px';
  }

  positionToolbar();
  positionJmrOptions();
};

export const toggleTranscriptMedium = (showTranscript: boolean) => {
  const editorContainerElement = document.getElementById('editor_container');
  const editorWrapperElement = document.getElementById('editor_wrapper');
  const transcriptWrapperElement = document.getElementById('transcript_wrapper');
  const toggleElement = document.getElementById('transcript_toggle');
  if (
    !editorContainerElement ||
    !editorWrapperElement ||
    !transcriptWrapperElement ||
    !toggleElement
  )
    return;

  if (showTranscript) {
    // Set transcript expanded attribute to true
    transcriptWrapperElement.setAttribute('expanded', 'true');
    editorContainerElement.setAttribute('forceFullscreen', 'true');
    positionEditor();

    editorContainerElement.style.direction = 'rtl';
    editorWrapperElement.style.direction = 'ltr';
    editorWrapperElement.style.marginRight = TRANSCRIPT_WIDTH + 'px';
    const newEditorWrapperWidth = editorContainerElement.clientWidth - 300;
    editorWrapperElement.style.width = newEditorWrapperWidth + 'px';
    transcriptWrapperElement.style.height = '100%';
    transcriptWrapperElement.style.right = '0';
    toggleElement.classList.remove('toggle_behind');
    toggleElement.classList.add('toggle_infront');
  } else {
    // Set transcript expanded attribute to false
    transcriptWrapperElement.setAttribute('expanded', 'false');
    editorContainerElement.style.direction = 'ltr';
    editorWrapperElement.style.direction = 'ltr';
    editorWrapperElement.style.marginRight = '0';
    editorWrapperElement.style.width = 'clamp(360px, 100%, 840px)';
    toggleElement.classList.remove('toggle_infront');
    toggleElement.classList.add('toggle_behind');
    editorContainerElement.setAttribute('forceFullscreen', 'false');
    positionEditor();
  }
};

export const toggleTranscriptSmall = (showTranscript: boolean) => {
  const transcriptWrapperElement = document.getElementById('transcript_wrapper');
  const editorContainerElement = document.getElementById('editor_container');
  const toolbarElement = document.getElementById('toolbar');
  const jmrOptionsElement = document.getElementById('jmr_options');
  const toggleElement = document.getElementById('transcript_toggle');
  if (
    !transcriptWrapperElement ||
    !editorContainerElement ||
    !jmrOptionsElement ||
    !toggleElement
  )
    return;

  const windowHeight = window.innerHeight;

  if (showTranscript) {
    // Set transcript expanded attribute to true
    transcriptWrapperElement.setAttribute('expanded', 'true');
    // Enable override for default jmrOptions scroll behavior
    jmrOptionsElement.setAttribute('scrollOverride', 'true');

    transcriptWrapperElement.style.transform = 'translate(0, 0)';
    editorContainerElement.style.marginTop = windowHeight + 'px';
    editorContainerElement.style.height = (windowHeight / 4) * 3 + 'px';
    jmrOptionsElement.style.position = 'fixed';
    jmrOptionsElement.style.top = windowHeight / 4 - 2 + 'px';
    jmrOptionsElement.style.right = '0';
    toggleElement.classList.add('toggle_underneath_hidden');
    if (toolbarElement) toolbarElement.style.top = (windowHeight / 8) * 5 + 'px';
  } else {
    // Set transcript expanded attribute to true
    transcriptWrapperElement.setAttribute('expanded', 'false');
    // Disable override for default jmrOptions scroll behavior
    jmrOptionsElement.setAttribute('scrollOverride', 'false');

    transcriptWrapperElement.style.transform = `translate(0, -${windowHeight}px)`;
    editorContainerElement.style.marginTop = '0';
    editorContainerElement.style.height = '100%';
    jmrOptionsElement.style.top = '0';
    toggleElement.classList.remove('toggle_underneath_hidden');
    if (toolbarElement) toolbarElement.style.top = '50vh';
  }
};
