import React, { Ref, PropsWithChildren, forwardRef, useState, useEffect } from 'react';
import { cx } from '@emotion/css';
import { useSlate } from 'slate-react';
import { CustomEditor, CustomElementType } from '@jambr/collab-util';
import { getTextField, isBlockActive, isMarkActive, toggleBlock, toggleMark } from '../Slate/util';
import { insertActionItem } from '../elements/ActionItems/util';
import { positionToolbar } from '../JMR/positionUtil';

interface BaseProps {
  className: string;
  [key: string]: unknown;
}
type OrNull<T> = T | null;

const Toolbar = forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <ToolbarComponent props={props} ref={ref as Ref<HTMLDivElement>} />
  )
);

interface ToolbarComponentProps {
  props: any;
  ref: Ref<HTMLDivElement>;
}

const ToolbarComponent = ({ props, ref }: ToolbarComponentProps) => {
  useEffect(() => positionToolbar(), []);
  return (
    <div {...props} ref={ref as Ref<HTMLDivElement>} id='toolbar'>
      <MarkButton format='bold' icon='format_bold' />
      <MarkButton format='italic' icon='format_italic' />
      <MarkButton format='underline' icon='format_underlined' />
      <MarkButton format='code' icon='code' />
      {/* <BlockButton format='heading-one' icon='looks_one' />
          <BlockButton format='heading-two' icon='looks_two' />
          <BlockButton format='block-quote' icon='format_quote' /> */}
      <BlockButton format={CustomElementType.NumberedList} icon='format_list_numbered' />
      <BlockButton format={CustomElementType.BulletedList} icon='format_list_bulleted' />
      <ActionItemButton />
    </div>
  );
};

const Icon = forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLSpanElement>) => (
    <span {...props} ref={ref} className={cx('material-icons', 'toolbar_icon', className)} />
  )
);

const Button = forwardRef(
  (
    {
      className,
      active,
      lockable,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        lockable: boolean;
        reversed: boolean;
      } & BaseProps
    >,
    ref: Ref<HTMLSpanElement>
  ) => {
    const [locked, setLocked] = useState(false);
    const editor = useSlate();
    const textEntry = getTextField(editor);

    if (textEntry) {
      const [textElement] = textEntry;
      if (textElement.type === CustomElementType.SimpleText && !locked && lockable) {
        setLocked(true);
      } else if (textElement.type === CustomElementType.RichText && locked) {
        setLocked(false);
      }
    }

    const computeClasses = () => {
      const classNames = ['toolbar_button'];
      if (locked) {
        classNames.push('toolbar_button_locked', 'noclick');
      } else if (active) {
        classNames.push('toolbar_button_active', 'pointer');
      } else {
        classNames.push('toolbar_button_inactive', 'pointer');
      }
      return classNames.join(' ');
    };

    return <span {...props} ref={ref} className={computeClasses()} />;
  }
);

interface BlockButtonProps {
  format: CustomElementType;
  icon: string;
}

const BlockButton = ({ format, icon }: BlockButtonProps) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      lockable={true}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}>
      <Icon>{icon}</Icon>
    </Button>
  );
};

interface MarkButtonProps {
  format: string;
  icon: string;
}

const MarkButton = ({ format, icon }: MarkButtonProps) => {
  const editor: CustomEditor = useSlate();

  return (
    <Button
      active={isMarkActive(editor, format)}
      lockable={true}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}>
      <Icon>{icon}</Icon>
    </Button>
  );
};

const ActionItemButton = () => {
  const editor = useSlate();
  return (
    <Button
      active={true}
      lockable={false}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        insertActionItem(editor);
      }}>
      <Icon>add_task</Icon>
    </Button>
  );
};

export default Toolbar;
