import * as encoding from 'lib0/encoding';
import { MESSAGE_PARTICIPANT } from './y-websocket';
export const updateAddMessageType = 0;
export const removeMessageType = 1;
export const leave = 2;

export const participantUpdateMessage = (): Uint8Array => {
  const encoder = encoding.createEncoder();
  encoding.writeVarUint(encoder, MESSAGE_PARTICIPANT);
  encoding.writeVarUint(encoder, updateAddMessageType);
  return encoding.toUint8Array(encoder);
};

export const participantRemoveMessage = (partId: string): Uint8Array => {
  const encoder = encoding.createEncoder();
  encoding.writeVarUint(encoder, MESSAGE_PARTICIPANT);
  encoding.writeVarUint(encoder, removeMessageType);
  encoding.writeVarString(encoder, partId);
  return encoding.toUint8Array(encoder);
};

export const participantLeft = (id: string) => {
  const encoder = encoding.createEncoder();
  encoding.writeVarUint(encoder, MESSAGE_PARTICIPANT);
  encoding.writeVarUint(encoder, leave);
  encoding.writeVarString(encoder, id);
  return encoding.toUint8Array(encoder);
};
