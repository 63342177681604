import _ from 'lodash';
import { Editor, Element, Node, NodeEntry, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { isRichNode, isSimpleNode, ListItemElement } from '@jambr/collab-util';
import {
  CustomEditor,
  CustomElement,
  CustomElementType,
  DeleteableElement,
  ItemStatus,
  MetaContainerElement,
  TextElement,
} from '@jambr/collab-util';
import React from "react";

/*
 *   CONSTANTS
 */

export const LIST_TYPES = [CustomElementType.NumberedList, CustomElementType.BulletedList];

export const ACTIONKEY = 'mod+a';

export const HOTKEYS: {
  [x: string]: string;
} = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

export const ARROWKEYS = ['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'];

export const SHORTCUTS: {
  [x: string]: CustomElementType;
} = {
  '*': CustomElementType.ListItem,
  '-': CustomElementType.ListItem,
  '1.': CustomElementType.ListItem,
  // "+": "list-item",
  // ">": "block-quote",
  // "#": "heading-one",
  // "##": "heading-two",
  // "###": "heading-three",
  // "####": "heading-four",
  // "#####": "heading-five",
  // "######": "heading-six",
};

/*
 *   RETRIEVAL FUNCTIONS
 */
// Returns the text field parent of the current selection if one exists, or
// returns false if there is no text field parent
export const getTextField = (editor: CustomEditor): NodeEntry<TextElement> | false => {
  const { selection } = editor;

  // If the selection is falsey, return false
  if (!selection) return false;

  // Handle the case that the selection is collapsed (single cursor location)
  if (Range.isCollapsed(selection)) {
    // Check if the selection has a rich text parent
    const richParent = Editor.above<TextElement>(editor, {
      match: isRichNode,
    });
    if (richParent) return richParent;

    // Check if the selection has a simple text parent
    const simpleParent = Editor.above<TextElement>(editor, {
      match: isSimpleNode,
    });
    if (simpleParent) return simpleParent;
  }

  // Handle the case that the selection spans a range
  if (Range.isExpanded(selection)) {
    const { anchor, focus } = selection;

    // Check if the expanded selection is contained by a single rich text node
    const richAnchorParent = Editor.above<TextElement>(editor, {
      at: anchor.path,
      match: isRichNode,
    });
    const richFocusParent = Editor.above<TextElement>(editor, {
      at: focus.path,
      match: isRichNode,
    });

    if (richAnchorParent && richFocusParent && _.isEqual(richAnchorParent[1], richFocusParent[1]))
      return richAnchorParent;

    // Check if the expanded selection is contained by a single simple text node
    const simpleAnchorParent = Editor.above<TextElement>(editor, {
      at: anchor.path,
      match: isSimpleNode,
    });
    const simpleFocusParent = Editor.above<TextElement>(editor, {
      at: focus.path,
      match: isSimpleNode,
    });

    if (
      simpleAnchorParent &&
      simpleFocusParent &&
      _.isEqual(simpleAnchorParent[1], simpleFocusParent[1])
    )
      return simpleAnchorParent;
  }

  // The selection is not inside a rich or simple text node
  return false;
};

/*
 *   CHECKER FUNCTIONS
 */
export const isMarkActive = (editor: CustomEditor, format: string) => {
  const marks: any = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const isBlockActive = (editor: CustomEditor, format: CustomElementType) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (node: any) => Element.isElement(node) && node.type === format && !Editor.isEditor(node),
  });

  return !!match;
};

/*
 *   TRANSFORMATION FUNCTIONS
 */

export const toggleBlock = (editor: CustomEditor, format: CustomElementType) => {
  // If the selection isn't in a rich node, don't do anything
  const textParent = getTextField(editor);
  if (!textParent || textParent[0].type === CustomElementType.SimpleText) return;

  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Editor.withoutNormalizing(editor, () => {
    Transforms.unwrapNodes(editor, {
      match: (node: any) =>
        Element.isElement(node) && LIST_TYPES.includes(node.type) && !Editor.isEditor(node),
      split: true,
    });

    const newProperties: Partial<CustomElement> = {
      type: isActive ? CustomElementType.TextBlock : isList ? CustomElementType.ListItem : format,
    };

    Transforms.setNodes<Element>(editor, newProperties);

    if (!isActive && isList) {
      const block = {
        type: format,
        children: [],
      };
      Transforms.wrapNodes(editor, block as any);
    }
  });
};

export const tabWrap = (editor: CustomEditor) => {
  const { selection } = editor;

  if (!selection) return;

  if (Range.isExpanded(selection)) {
    const [common, commonPath] = Node.common(editor, selection.anchor.path, selection.focus.path);

    if (Element.isElement(common) && !Editor.isEditor(common) && LIST_TYPES.includes(common.type)) {
      Transforms.wrapNodes(editor, { type: common.type, children: [] } as any);
    } else if (
      Element.isElement(common) &&
      !Editor.isEditor(common) &&
      common.type === CustomElementType.ListItem
    ) {
      // find the list that the common list element belongs to
      const commonList = Editor.above<CustomElement>(editor, {
        match: (node: any) => LIST_TYPES.includes(node.type),
        at: commonPath,
      });
      if (commonList) {
        Transforms.wrapNodes(editor, { type: commonList[0].type, children: [] } as any, {
          at: commonPath,
        });
      }
    } else {
      const listItemAbove = Editor.above(editor, {
        match: (node: any) => node.type === CustomElementType.ListItem,
        at: commonPath,
      });

      if (!listItemAbove) return;

      const listAbove = Editor.above<CustomElement>(editor, {
        match: (node: any) => LIST_TYPES.includes(node.type),
        at: listItemAbove[1],
      });

      if (listAbove) {
        Transforms.wrapNodes(editor, { type: listAbove[0].type, children: [] } as any, {
          at: listItemAbove[1],
        });
      }
    }
  } else {
    const listAbove = Editor.above<CustomElement>(editor, {
      match: (node: any) => LIST_TYPES.includes(node.type),
    });

    // If the selection spans many list items, there will not be a common list item parent
    const listItemAbove = Editor.above<ListItemElement>(editor, {
      match: (node: any) => node.type === CustomElementType.ListItem,
    });

    if (listAbove) {
      if (listItemAbove) {
        Transforms.wrapNodes(editor, { type: listAbove[0].type, children: [] } as any, {
          at: listItemAbove[1],
        });
      } else {
        Transforms.wrapNodes(editor, { type: listAbove[0].type, children: [] } as any);
      }
    }
  }
};

export const tabUnwrap = (editor: CustomEditor) => {
  const { selection } = editor;

  if (!selection) return;

  if (Range.isExpanded(selection)) {
    const [common, commonPath] = Node.common(editor, selection.anchor.path, selection.focus.path);
    if (Element.isElement(common) && !Editor.isEditor(common) && LIST_TYPES.includes(common.type)) {
      Transforms.liftNodes(editor);
      return;
    } else if (
      Element.isElement(common) &&
      !Editor.isEditor(common) &&
      common.type === CustomElementType.ListItem
    ) {
      // find the list that the common list element belongs to
      const commonList = Editor.above<CustomElement>(editor, {
        match: (node: any) => LIST_TYPES.includes(node.type),
        at: commonPath,
      });
      if (commonList) {
        Transforms.liftNodes(editor);
        return;
      }
    } else {
      const listItemAbove = Editor.above(editor, {
        match: (node: any) => node.type === CustomElementType.ListItem,
        at: commonPath,
      });

      if (!listItemAbove) return;

      const listAbove = Editor.above<CustomElement>(editor, {
        match: (node: any) => LIST_TYPES.includes(node.type),
        at: listItemAbove[1],
      });

      if (listAbove) {
        Transforms.liftNodes(editor, { at: listItemAbove[1] });
        return;
      }
    }
  } else {
    let parentListEntry = Editor.above<CustomElement>(editor, {
      match: (node: any) => LIST_TYPES.includes(node.type),
    });
    let listDepth = 0;

    while (parentListEntry) {
      listDepth++;
      const [, parentListPath] = parentListEntry;
      parentListEntry = Editor.above<CustomElement>(editor, {
        match: (node: any) => LIST_TYPES.includes(node.type),
        at: parentListPath,
      });
    }

    // if not in a list, this is a no-op
    if (!listDepth) return;
  }

  Transforms.liftNodes(editor);
};

export const toggleMark = (editor: any, format: string) => {
  const isActive = isMarkActive(editor, format);

  // If the selection isn't in a rich node, don't do anything
  const textParent = getTextField(editor);
  if (!textParent || textParent[0].type === CustomElementType.SimpleText) return;

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const deleteStatusElement = (editor: CustomEditor, element: DeleteableElement) => {
  const currentStatus = element.itemStatus;
  const pathToElement = ReactEditor.findPath(editor, element);

  if (currentStatus === ItemStatus.Created) {
    // Simply remove the element from the editor
    Transforms.removeNodes(editor, {
      at: [...pathToElement],
    });
  } else if (currentStatus === ItemStatus.Existed || currentStatus === ItemStatus.Changed) {
    if (element.type === CustomElementType.Utterance) {
      Transforms.setNodes(
        editor,
        {
          itemStatus: ItemStatus.Deleted,
        },
        {
          at: pathToElement,
        }
      );
      return;
    }

    // To avoid element indexing problems, move elements with status Deleted
    // to the end of the list of children
    const pathToParent = [...pathToElement].slice(0, -1);

    const [parentNode] = Editor.node(editor, pathToParent);

    if (!('children' in parentNode)) return;

    const newPath = [...pathToParent, parentNode.children.length - 1];

    Transforms.moveNodes(editor, {
      at: pathToElement,
      to: newPath,
    });

    // Set the element status to Deleted so we can delete it from the database
    // The setNodes must be performed AFTER the moveNodes so that the undoManager does not get
    // confused about relative paths if the user attempts to undo a delete
    Transforms.setNodes(
      editor,
      {
        itemStatus: ItemStatus.Deleted,
      },
      {
        at: newPath,
      }
    );
  }
};

export const getElementMetaContainer = (editor: CustomEditor, elementPath: number[]):  NodeEntry<MetaContainerElement> => {
  const [entry] = Editor.nodes<MetaContainerElement>(editor, {
    at: elementPath,
    match: (node: any) => node.type === CustomElementType.MetaContainer,
  });

  if (!entry)
    throw new Error('Unable to find meta container for element at path ' + elementPath.toString());
  return entry;
};

export const stopTripleClick = (event: React.MouseEvent) => {
  if (event.detail >= 3) {
    event.stopPropagation();
    event.preventDefault();
  }
};
