import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { searchQuery } from '../middlewares/searchRequests';
import { TEsResult } from '../types/searchTypes';

const searchAdapter = createEntityAdapter<TEsResult>({});

export const initialState = searchAdapter.getInitialState({
  loading: false,
  query: '',
});

const searchSlice = createSlice({
  name: 'searchSlice',
  initialState,
  reducers: {
    clearQuery(state) {
      state.query = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchQuery.pending, (state, action) => {
      state.loading = true;
      state.query = action.meta.arg.query;
    });
    builder.addCase(searchQuery.fulfilled, (state, action) => {
      // TODO: Change searchES lambda so that results pertaining
      // to the same JMR are collapsed into a single result
      state.loading = false;
      const { payload: hits } = action;
      const hitsJson = JSON.parse(hits);
      searchAdapter.removeAll(state);
      hitsJson.forEach((hit: any) => {
        // TODO: we need to change the id fields on publishES and searchES
        // lambdas to be jmrId and agendaItemId
        searchAdapter.upsertOne(state, {
          ...hit,
          jmrId: hit.id,
        });
      });
    });
  },
});

export const { clearQuery } = searchSlice.actions;

export default searchSlice.reducer;
