import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { clearAuthError, setAuthError } from '../../store/authSlice';
import Form from '../Forms/Form';
import { TextInput } from '../Forms/Inputs';
import { EMAIL_VALIDATION } from '../Forms/_form_util';
import AccountTemplate from './AccountTemplate';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(clearAuthError());
  }, [dispatch]);

  const onSubmit = (data: { email: string }) => {
    const { email } = data;
    setLoading(true);

    Auth.forgotPassword(email)
      .then((data) => {
        history.push({ pathname: '/reset-password', search: `?email=${email}` });
      })
      .catch((err) => {
        dispatch(setAuthError(err));
        setLoading(false);
      });
  };

  return (
    <AccountTemplate>
      <Form showButton buttonText='SEND CODE' onSubmit={onSubmit} loading={loading}>
        <TextInput name='email' label='email' validation={EMAIL_VALIDATION} />
      </Form>
      <div className='account_links'>
        Oh wait, I remember! <Link to='/login'>Login</Link>
      </div>
    </AccountTemplate>
  );
};

export default ForgotPassword;
