import { useContext, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import {
  SubscriptionState,
  clearError,
  setActionGenerationState,
  setStatus,
  setSubscription,
} from '../../../../store/transcriptSlice';
import { JmrPermissions } from '../../../../types/participantTypes';
import {
  TranscriptButtonType,
  TranscriptState,
  getTranscriptButtonType,
} from '../../../../types/transcriptTypes';
import { isSubscriptionActiveOrTrialing } from '../../../../utils/authUtil';
import OutsideClickDetector from '../../../Util/OutsideClickDetector';
import Tooltip from '../../../Util/Tooltip';
import { checkMeetingLinkType } from '../../../Util/meetingProvider';
import { JmrContext } from '../../JMR/JMR';
import {
  TranscriptSocketProvider,
  join_room,
  launch_transcription,
  start_transcription,
  stop_transcription,
} from './t-websocket';

const GenerateActionMessage = 'generate_action_items';

const TranscriptControls = () => {
  const [provider, setProvider] = useState<TranscriptSocketProvider>();
  const { currentParticipant, permissions } = useContext(JmrContext);
  const { jmrId, location, duration } = currentParticipant;

  const { email, subStatus } = useAppSelector((state) => state.auth);
  const isSubscribed = isSubscriptionActiveOrTrialing(subStatus);

  const { status, error, subscription, actionGenerationState } = useAppSelector(
    (state) => state.transcript
  );
  const { actionsLoading, actionsNoop, actionsRequester, actionsError } = actionGenerationState;
  const dispatch = useAppDispatch();

  const meetingCall = useMemo(() => checkMeetingLinkType(location), [location]);

  useEffect(() => {
    if (!provider) {
      // Create the provider only once - when the component mounts
      let tempProvider = new TranscriptSocketProvider({
        jmrId,
        location,
        email,
        duration: duration ? duration : '0',
      });

      // Immediately invoked async function
      (async () => {
        await tempProvider.init();
        setProvider(tempProvider);
      })();
    } else {
      provider.setProviderData({
        jmrId,
        location,
        email,
        duration: duration ? duration : '0',
      });
    }
  }, [duration, email, jmrId, location, provider]);

  // Disconnect TranscriptProvider on unmount in order to free up resources and prevent one client
  // from having multiple connections to the transcript server
  useEffect(() => {
    return () => {
      dispatch(clearError());
      dispatch(
        setActionGenerationState({
          actionsLoading: false,
          actionsNoop: false,
          actionsRequester: false,
        })
      );
      dispatch(setSubscription(SubscriptionState.NotSubscribed));
      if (provider) {
        provider.disconnectProvider();
      }
    };
  }, [provider, dispatch]);

  useEffect(() => {
    if (subscription === SubscriptionState.NotSubscribed && provider) {
      // this dispatch HAS to happen before provider.sendMessage()
      dispatch(setSubscription(SubscriptionState.Pending));
      provider.sendMessage(join_room);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, status, error]);

  const launchTranscription = () => {
    if (error) return;
    if (!provider) {
      return new Error('provider is not initialized');
    }

    // TODO: check location validity
    dispatch(setStatus(TranscriptState.Launching));
    provider.sendMessage(launch_transcription);
  };

  const startTranscription = () => {
    if (!provider) {
      return new Error('provider is not initialized');
    }
    dispatch(setStatus(TranscriptState.Starting));
    provider.sendMessage(start_transcription);
  };

  const stopTranscription = () => {
    if (!provider) {
      return new Error('provider is not initialized');
    }
    dispatch(setStatus(TranscriptState.Stopping));
    provider.sendMessage(stop_transcription);
  };

  const renderStatus = useMemo(() => {
    if (!meetingCall) return 'Invalid link';
    switch (status) {
      case TranscriptState.SubscribedToStatus:
      case TranscriptState.Connected:
        return 'Ready to launch';
      case TranscriptState.Disconnected:
        return 'Attempting to connect';
      case TranscriptState.Errored:
        return 'Something went wrong';
      case TranscriptState.Stopped:
        return 'Transcription paused';
      case TranscriptState.Started:
        return 'Transcribing...';
      case TranscriptState.Launched:
        return 'Ready to transcribe';
      case TranscriptState.Launching:
        return 'Transcript Engine Starting';
      case TranscriptState.Authenticating:
        return 'Authenticating...';
      case TranscriptState.Authorizing:
        return 'Authorizing...';
      case TranscriptState.Terminated:
        return 'Bot has left the call.';
      default:
        return status;
    }
  }, [meetingCall, status]);

  const generateActions = () => {
    if (!provider) {
      return new Error('provider is not initialized');
    }
    provider.sendActionMessage(GenerateActionMessage);
    dispatch(setActionGenerationState({ actionsLoading: true, actionsRequester: true }));
  };

  const renderTranscriptButton = () => {
    if (permissions === JmrPermissions.READ || !isSubscribed) return;
    if (!meetingCall)
      return (
        <div className='user_avatar' contentEditable={false}>
          <span className='material-icons'>sentiment_very_dissatisfied</span>
          <Tooltip>
            <span>
              Please set the location of this
              <br />
              meeting to a valid zoom or teams link.
            </span>
          </Tooltip>
        </div>
      );

    const buttonType = getTranscriptButtonType(status);

    switch (buttonType) {
      case TranscriptButtonType.Launch:
        return (
          <div className='user_avatar' onClick={launchTranscription} contentEditable={false}>
            <span className='material-icons'>rocket_launch</span>
            <Tooltip tooltip='launch bot' />
          </div>
        );
      case TranscriptButtonType.Start:
        return (
          <div className='user_avatar' onClick={startTranscription}>
            <span className='material-icons'>mic</span>
            <Tooltip tooltip='start transcript' />
          </div>
        );
      case TranscriptButtonType.Stop:
        return (
          <div className='user_avatar' onClick={stopTranscription}>
            <span className='material-icons stop_transcript'>fiber_manual_record</span>
            <Tooltip tooltip='stop transcript' />
          </div>
        );
      case TranscriptButtonType.Loading:
        return <div className='spin_loader' />;
      case TranscriptButtonType.Error:
        return (
          <div className='user_avatar'>
            <span className='material-icons'>sentiment_very_dissatisfied</span>
            <Tooltip tooltip='there was an error' />
          </div>
        );
    }
  };

  const renderAlertModal = () => {
    if (!actionsRequester || (!actionsNoop && !actionsError)) return;

    return (
      <OutsideClickDetector
        callback={() => {
          if (actionsNoop)
            dispatch(setActionGenerationState({ actionsRequester: false, actionsNoop: false }));
          if (actionsError)
            dispatch(setActionGenerationState({ actionsRequester: false, actionsError: '' }));
        }}>
        <div className='jmr_modal action_noop_modal'>
          <div className='jmr_modal_pointer jmr_delete_modal_pointer' />
          {actionsNoop
            ? 'Jambr has already searched for the action items in this transcript.'
            : 'We ran into a problem looking for action items:'}
          <br />
          <br />
          {actionsNoop
            ? 'If you add to it, click this button again and we will search what you added.'
            : actionsError}
        </div>
      </OutsideClickDetector>
    );
  };
  const renderTranscriptControlAlertModal = () => {
    if (error) {
      return (
        <OutsideClickDetector
          callback={() => {
            dispatch(clearError());
          }}>
          <div className='jmr_modal bot_issue_modal'>
            <div className='jmr_modal_pointer jmr_delete_modal_pointer' />
            {
              'There seems to be an issue with the transcription service. Please contact support if this issue persists'
            }
          </div>
        </OutsideClickDetector>
      );
    }
  };

  const renderActionsButton = () => {
    if (permissions === JmrPermissions.READ || !isSubscribed) return;
    if (actionsLoading) return <div className='spin_loader' />;
    return (
      <div className='user_avatar' onClick={generateActions}>
        <span className='material-icons'>add_task</span>
        <Tooltip tooltip='generate action items' />
        {renderAlertModal()}
      </div>
    );
  };

  return (
    <div className='transcript_controls' contentEditable={false}>
      <div className='transcript_status'>{renderStatus}</div>
      {renderTranscriptButton()}
      {renderActionsButton()}
      {renderTranscriptControlAlertModal()}
    </div>
  );
};

export default TranscriptControls;
