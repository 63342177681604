import { CustomEditor, CustomElement, CustomElementType } from '@jambr/collab-util';
import { Editor, Element, Node, Transforms } from 'slate';
import { LIST_TYPES } from '../Slate/util';

const withLists = (editor: CustomEditor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // Merge adjacent BulletedList, NumberedList, and DashedList elements
    if (Element.isElement(node) && !Editor.isEditor(node) && LIST_TYPES.includes(node.type)) {
      const parent = Node.parent(editor, path);
      const parentPath = path.slice(0, -1);
      let previousBulleted = false;
      let previousNumbered = false;
      // let previousDashed = false;

      // Do not use forEach loops in the normalizeNode function as we must return out of the normalize function
      // after every Transform to support multipass normalization. Return statements in forEach loops
      // do not break out of their parent function, only the callback function of the forEach, so the loop
      // will continue to run, making transforms to the editor, and possibly breaking the normalization function
      for (let i = 0; i < parent.children.length; i++) {
        const child = parent.children[i] as CustomElement;
        if (child.type === CustomElementType.BulletedList) {
          previousNumbered = false;
          // previousDashed = false;
          if (previousBulleted) {
            Transforms.mergeNodes(editor, { at: [...parentPath, i] });
            return;
          }
          previousBulleted = true;
        } else if (child.type === CustomElementType.NumberedList) {
          previousBulleted = false;
          // previousDashed = false;
          if (previousNumbered) {
            Transforms.mergeNodes(editor, { at: [...parentPath, i] });
            return;
          }
          previousNumbered = true;
        } else {
          previousBulleted = false;
          previousNumbered = false;
          // previousDashed = false;
        }
      }
    }

    // Ensure List elements only have ListItems or List elements as children
    if (Element.isElement(node) && !Editor.isEditor(node) && LIST_TYPES.includes(node.type)) {
      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i] as CustomElement;
        if (child.type !== CustomElementType.ListItem && !LIST_TYPES.includes(child.type)) {
          Transforms.liftNodes(editor, { at: [...path, i] });
          return;
        }
      }
    }

    // Ensure ListItems are only descendants of Lists
    if (
      Element.isElement(node) &&
      !Editor.isEditor(node) &&
      node.type === CustomElementType.ListItem
    ) {
      const parent = Node.parent(editor, path);
      if (!LIST_TYPES.includes((parent as CustomElement).type)) {
        Transforms.setNodes(editor, { type: CustomElementType.TextBlock }, { at: path });
        return;
      }
    }

    normalizeNode(entry);
  };

  return editor;
};

export default withLists;
