import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { TEsAgendaItem, TEsJmr } from '../../../types/searchTypes';
import { TParticipant } from '../../../types/participantTypes';
import { useEffect, useMemo } from 'react';
import { convertDate, convertTime } from '../../Util/dateUtil';
import { checkMeetingLinkType } from '../../Util/meetingProvider';

interface JmrPreviewProps {
  previewEntity: TEsJmr | TParticipant;
  agendaItem?: TEsAgendaItem;
  renderCallback?: () => void;
  closeNav?: () => void;
  renderActive?: boolean;
}

const Preview = ({
  previewEntity: participant,
  agendaItem,
  renderCallback,
  closeNav,
  renderActive,
}: JmrPreviewProps) => {
  const { url } = useRouteMatch();
  const baseUrl = url.split('/')[0] + '/home/jmr';
  const location = useLocation();

  const isSelected = useMemo((): boolean => {
    const pathElements = location.pathname.split('/');
    const pathId = pathElements[pathElements.length - 1];

    return pathId === participant.jmrId;
  }, [location, participant]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => renderCallback && renderCallback(), []);

  const trimField = (field: string, placeholder: string, lengthLimit: number) => {
    const whitespaceRemoved = field.trim();
    if (whitespaceRemoved === '') {
      return placeholder;
    }
    if (whitespaceRemoved.length >= lengthLimit) {
      return whitespaceRemoved.substring(0, lengthLimit - 1) + '...';
    }
    return whitespaceRemoved;
  };

  const parseRich = (richField: string) => {
    if (!richField) return '';
    try {
      const richJSON = JSON.parse(richField);
      return getText(richJSON).join(' ');
    } catch (error) {
      return richField;
    }
  };

  const getText = (nodes: any[]) => {
    const textNodes: any = [];
    nodes.forEach((node) => {
      if ('text' in node) {
        textNodes.push(node.text);
      } else if ('children' in node) {
        textNodes.push(...getText(node.children));
      }
    });
    return textNodes;
  };

  const meetingCall = useMemo(
    () => checkMeetingLinkType(participant.location),
    [participant.location]
  );

  return (
    <NavLink
      to={`${baseUrl}/${participant.jmrId}`}
      className={renderActive ? 'preview pointer no_shadow' : 'preview pointer'}
      activeClassName='preview_selected pointer'
      id={participant.jmrId + '_preview'}
      onClick={closeNav}>
      <h3>{trimField(participant.title, 'Untitled Meeting', 80)}</h3>
      <div className='meta_container'>
        <PreviewMeta
          icon='today'
          metaData={convertDate(new Date(participant.startDate))}
          jmrId={participant.jmrId}
          selected={renderActive || isSelected}
        />
        <PreviewMeta
          icon='schedule'
          metaData={convertTime(new Date(participant.startDate))}
          jmrId={participant.jmrId}
          selected={renderActive || isSelected}
        />
        <PreviewMeta
          icon='place'
          metaData={meetingCall ? meetingCall : participant.location}
          placeholder={'no location'}
          jmrId={participant.jmrId}
          selected={renderActive || isSelected}
        />
      </div>
      <p>{trimField(parseRich(participant.purpose), 'No meeting purpose yet', 130)}</p>
      {agendaItem && <p>{parseInt(agendaItem.seqNum) + 1 + '. ' + agendaItem.title}</p>}
    </NavLink>
  );
};

interface PreviewMetaProps {
  icon: string;
  metaData: string;
  jmrId: string;
  selected: boolean;
  placeholder?: string;
}

const PreviewMeta = ({ icon, metaData, jmrId, selected, placeholder }: PreviewMetaProps) => {
  const renderMetaData = () => {
    if (!metaData) return placeholder ? placeholder : '';
    if (metaData.length > 30) return metaData.substring(0, 30) + '...';
    return metaData;
  };

  return (
    <div
      className={
        selected
          ? 'meta_base meta_small meta_colors_active'
          : 'meta_base meta_small meta_colors_inactive'
      }>
      <span className='material-icons'>{icon}</span>
      {renderMetaData()}
    </div>
  );
};

export default Preview;
