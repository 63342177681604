import { debounceWithAnimationFrame } from "../../Util/debounceWithAnimationFrame";

export const MAX_WIDTH_FOR_FULLSCREEN_EDITOR = 660;

export const positionEditorRaw = () => {
  const editorContainerElement = document.getElementById('editor_container');

  if (!editorContainerElement) return;

  const isForceFullscreen = editorContainerElement.getAttribute('forceFullscreen');
  if (
    editorContainerElement.getBoundingClientRect().width < MAX_WIDTH_FOR_FULLSCREEN_EDITOR ||
    isForceFullscreen === 'true'
  ) {
    // This css class makes the editor take up more of the screen realestate
    // for narrow screens
    editorContainerElement.classList.add('fullscreen_editor');
  } else if (!isForceFullscreen || isForceFullscreen === 'false') {
    editorContainerElement.classList.remove('fullscreen_editor');
  }
  positionJmrOptions();
  positionToolbar();
};

export const positionEditor = debounceWithAnimationFrame(positionEditorRaw);

export const positionToolbar = () => {
  const toolbarElement = document.getElementById('toolbar');
  const editorContainerElement = document.getElementById('editor_container');
  const editorElement = document.getElementById('editor');

  if (!toolbarElement || !editorContainerElement || !editorElement) return;

  if (editorContainerElement.getBoundingClientRect().width < MAX_WIDTH_FOR_FULLSCREEN_EDITOR) {
    // When the editor is in fullscreen mode, the toolbar renders in the middle
    //  of the right padding.
    // If the editor is scrollable, move the toolbar over a touch more to the
    //  left so it isn't right up against the scrollbar
    const editorPadding = parseInt(
      window.getComputedStyle(editorElement).getPropertyValue('padding-right')
    );
    const toolbarRightOffset =
      editorPadding / 2 +
      (editorContainerElement.scrollHeight === editorContainerElement.clientHeight ? 0 : 9);
    toolbarElement.style.right = toolbarRightOffset + 'px';
    toolbarElement.style.transform = '';
  } else {
    // move the toolbar to the middle of the editor's right edge when in page mode
    const rightEditorEdge = window.innerWidth - editorElement.getBoundingClientRect().right;
    toolbarElement.style.right = rightEditorEdge + 'px';
  }
};

export const positionJmrOptions = () => {
  const editorElement = document.getElementById('editor');
  const editorContainerElement = document.getElementById('editor_container');
  const jmrOptionsElement = document.getElementById('jmr_options');
  if (!editorElement || !editorContainerElement || !jmrOptionsElement) return;

  // In small mode, the transcript overrides the jmrOptions element's default behavior
  // so that it can be positioned correctly as the transcript is opened and closed
  if (jmrOptionsElement.getAttribute('scrollOverride') === 'true') return;

  const editorTop = editorElement.getBoundingClientRect().top;

  if (editorTop <= 0) {
    const jmrOptionsRight = getEditorRightOffset();
    jmrOptionsElement.style.position = 'fixed';
    jmrOptionsElement.style.right = jmrOptionsRight + 'px';
  } else {
    jmrOptionsElement.style.position = 'absolute';
    jmrOptionsElement.style.right = '0';
  }
};

export const getEditorRightOffset = () => {
  const editorElement = document.getElementById('editor');
  const editorContainerElement = document.getElementById('editor_container');

  // If the elements don't exist for some reason, just return 0
  if (!editorElement || !editorContainerElement) return 0;

  const { right } = editorElement.getBoundingClientRect();
  return window.innerWidth - right;
};
