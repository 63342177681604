// First we need to import axios.js
import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig } from 'axios';
import store from '.';
import { setCurrentUser } from './store/authSlice';
import { API_GATEWAY_ID, REGION, STAGE } from './utils/constants';

// Next we make an 'instance' of it
const axiosInstance = axios.create({
  // .. where we make our configurations
  baseURL: `https://${API_GATEWAY_ID}.execute-api.${REGION}.amazonaws.com/${STAGE}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const axiosRequestInterceptor = async (config: AxiosRequestConfig) => {
  const user = await Auth.currentAuthenticatedUser();
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const { email: currentUser } = store.getState().auth;

  if (!currentUser) {
    const { email, family_name: lname, given_name: fname, name } = user.attributes;
    store.dispatch(setCurrentUser({ email, fname: fname ? fname : name, lname }));
  }
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
};

axiosInstance.interceptors.request.use(axiosRequestInterceptor, (error) => Promise.reject(error));

const axiosInstanceNoAuth = axios.create({
  // .. where we make our configurations
  baseURL: `https://${API_GATEWAY_ID}.execute-api.${REGION}.amazonaws.com/${STAGE}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: '',
  },
});

export { axiosInstanceNoAuth };
export default axiosInstance;
