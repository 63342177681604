import React from 'react';
import { useAppDispatch } from '../../../hooks/hooks';
import { clearError } from '../../../store/errorSlice';
import { clearFetchedId } from '../../../store/participantSlice';

type Props = {
  error: Error;
  errorInfo: React.ErrorInfo;
  handler: () => void;
};

const JmrMessage = ({ error, errorInfo, handler }: Props) => {
  const dispatch = useAppDispatch();
  const retryError = () => {
    dispatch(clearError());
    dispatch(clearFetchedId());
    handler();
  };
  // You can render any custom fallback UI
  return (
    <div className='error_message'>
      <section>
        <h1 className='error_uhoh'>Session Timeout</h1>
        <div className='error_details'>
          <span className='error_text'>
            {error.message}
            <br />
            <button type='button' className='message_button' onClick={() => retryError()}>
              <div>Reconnect</div>
              <span className='material-icons'>refresh</span>
            </button>
          </span>
          <details
            style={{
              whiteSpace: 'pre-wrap',
            }}>
            <summary>Details</summary>
            <br />
            {error && error.toString()}
            {errorInfo.componentStack}
          </details>
        </div>
      </section>
    </div>
  );
};
export default JmrMessage;
