import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/hooks';
import { fetchActionItems } from '../../middlewares/actionRequests';
import ActionList from './ActionItems/ActionList';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  // const { entities, fetchComplete } = useAppSelector((state) => state.actionItems);

  useEffect(() => {
    dispatch(fetchActionItems());
  }, [dispatch]);

  return (
    <div className='dashboard_container'>
      <ActionList />
    </div>
  );
};

export default Dashboard;
