// JmrElement is called for every node in the slate node array

import { ReactEditor, RenderElementProps, RenderLeafProps, useSlateStatic } from 'slate-react';
import ActionItem from '../elements/ActionItems/ActionItem';
import ActionList from '../elements/ActionItems/ActionList';
import Agenda from '../elements/Agenda/Agenda';
import AgendaItem from '../elements/Agenda/AgendaItem';
import JmrAccessTab from '../JMR/JmrOptions/JmrAccessTab';
import {
  CustomElementType,
  JmrHeaderElement,
  PseudoElementType,
  ItemStatus,
} from '@jambr/collab-util';
import RestrictedElement from './RestrictedElement';
import { useCallback, useEffect, useMemo } from 'react';
import Meta from '../elements/Meta/Meta';
import { isEmptyTextField } from '@jambr/collab-util';
import Transcript from '../elements/Transcript/Transcript';
import Utterance from '../elements/Transcript/Utterance';
import TextField from '../elements/TextField';

// and determines how that node should be rendered
const JmrElement = (props: any) => {
  const { element, attributes, children } = props;

  if (element.itemStatus && element.itemStatus === ItemStatus.Deleted) {
    return null;
  }

  // Render custom elements
  switch (element.type) {
    case PseudoElementType.JmrOptions:
      return <JmrAccessTab {...props} />;
    case CustomElementType.JmrTitle:
      return <JmrTitle {...props} />;
    case CustomElementType.JmrHeader:
      return <JmrHeader {...props} />;
    case CustomElementType.RichText:
      return <TextField {...props} />;
    case CustomElementType.SimpleText:
      return <TextField {...props} />;
    case CustomElementType.MetaContainer:
      return (
        <div
          {...attributes}
          className='meta_container'
          contentEditable={false}
          style={{
            userSelect: 'none',
          }}>
          {children}
        </div>
      );
    case CustomElementType.Meta:
      return <Meta {...props}>{children}</Meta>;
    case CustomElementType.ActionList:
      return <ActionList {...props} />;
    case CustomElementType.ActionItem:
      return <ActionItem {...props} />;
    case CustomElementType.Agenda:
      return <Agenda {...props} />;
    case CustomElementType.AgendaItem:
      return <AgendaItem {...props} />;
    case CustomElementType.Transcript:
      return <Transcript {...props} />;
    case CustomElementType.Utterance:
      return <Utterance {...props} />;
    case CustomElementType.BulletedList:
      return (
        <ul {...attributes} className='unordered_list'>
          {children}
        </ul>
      );
    case CustomElementType.NumberedList:
      return (
        <ol {...attributes} className='ordered_list'>
          {children}
        </ol>
      );
    case CustomElementType.ListItem:
      return <li {...attributes}>{children}</li>;
    case CustomElementType.Hidden:
      return <Hidden {...props} />;
    default:
      return <div {...attributes}>{children}</div>;
  }
};

interface JmrHeaderProps {
  element: JmrHeaderElement;
}

export const JmrHeader = ({
  attributes,
  children,
  element,
}: RenderElementProps & JmrHeaderProps) => {
  return (
    <h2
      {...attributes}
      contentEditable={false}
      style={{
        userSelect: 'none',
      }}
      className='jmr_header'>
      {element.header}
      {children}
    </h2>
  );
};

export const JmrTitle = ({ children, element, attributes }: RenderElementProps) => {
  const editor = useSlateStatic();

  useEffect(() => {
    calcTitleHeight();
    const editorContainer = document.getElementById('editor_container');
    if (editorContainer) new ResizeObserver(calcTitleHeight).observe(editorContainer);
  }, []);

  useEffect(() => calcTitleHeight(), [element]);

  const calcTitleHeight = useCallback(() => {
    const titleElement = document.getElementById('jmr_title');
    const titlePlaceholderElement = document.getElementById('jmr_title_placeholder');
    // If the title element doesn't exist, just return (should never happen)
    if (!titleElement) return;
    // If the titlePlaceholderElement doesn't exist, the field isn't empty, so
    // the height should be set to auto
    if (!titlePlaceholderElement) {
      titleElement.style.height = 'auto';
    } else {
      titleElement.style.height = titlePlaceholderElement.offsetHeight + 'px';
    }
  }, []);

  const isEmpty = useMemo(
    () => isEmptyTextField([...ReactEditor.findPath(editor, element), 0], editor),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element]
  );

  return (
    <RestrictedElement
      element={
        <div {...attributes} id='jmr_title'>
          {children}
          {isEmpty && (
            <div
              contentEditable={false}
              style={{
                userSelect: 'none',
              }}
              className='placeholder_base placeholder_field'
              id='jmr_title_placeholder'>
              Untitled Meeting
            </div>
          )}
        </div>
      }
    />
  );
};

const Hidden = ({ children, attributes }: RenderElementProps) => {
  return (
    <div {...attributes} className='invisible' contentEditable={false}>
      {children}
    </div>
  );
};

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export default JmrElement;
