import React, { InputHTMLAttributes, useState } from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { useAppSelector } from '../../hooks/hooks';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  validation?: RegisterOptions;
  password?: boolean;
  confirmPassword?: boolean;
  value?: string;
  options?: {
    label: string;
    value: string | number;
  }[];
  onSelect?: () => void;
}

export const TextInput = (props: InputProps) => {
  const { validation, name, label, password, confirmPassword, ...rest } = props;

  const { register, errors, watch } = useFormContext();

  let confirmPasswordValidation = {};

  if (confirmPassword) {
    confirmPasswordValidation = {
      required: 'confirm password is required',
      validate: (value: string) => value === watch('password') || 'The passwords do not match',
    };
  }

  // TODO: password tooltip should change location depending on viewport width
  const checkRegister = () => {
    return (
      <div className='form_field_container'>
        <div hidden={!password} className='password_info'>
          <span className='material-icons'>info</span>
          <div className='password_tooltip'>
            Your password must contain:
            <ul>
              <li>at least 8 characters</li>
              <li>at least one uppercase letter</li>
              <li>at least one lowercase letter</li>
              <li>at least one number</li>
              <li>at least one symbol</li>
            </ul>
          </div>
        </div>
        <div className='form_field'>
          <input
            className='form_input'
            id={name}
            name={name}
            ref={confirmPassword ? register(confirmPasswordValidation) : register(validation)}
            type={password ? 'password' : 'text'}
            {...rest}
            required
            autoComplete='off'
          />
          <label htmlFor={name} className='form_label'>
            <span className='label_content'>{label}</span>
          </label>
        </div>
        {errors && errors[name] && <div className='error'>{errors[name].message}</div>}
      </div>
    );
  };
  return checkRegister();
};

TextInput.defaultProps = {
  password: false,
  confirmPassword: false,
};

export const DateInput = (props: InputProps) => {
  const { name, label, type: inputType, validation, ...rest } = props;
  const { register, errors } = useFormContext();

  // Hides placeholder text yyyy-mm-dd if the date picker is empty or not focused
  const toggleFieldType = (event: React.FocusEvent<HTMLInputElement>) => {
    const { target, type } = event;

    if (type === 'focus' && inputType) target.type = inputType;

    if (type === 'blur' && !target.value) target.type = 'text';

    if (inputType === 'date') target.min = calcMinDate();
  };

  const calcMinDate = () => {
    const dtToday = new Date();

    let month: any = dtToday.getMonth() + 1;
    let day: any = dtToday.getDate();
    const year = dtToday.getFullYear();
    if (month < 10) month = '0' + month.toString();
    if (day < 10) day = '0' + day.toString();

    return year + '-' + month + '-' + day;
  };

  return (
    <div className='form_field_container'>
      <div className='form_field'>
        <input
          ref={register(validation)}
          name={name}
          type='text'
          className='form_input'
          required
          onFocus={toggleFieldType}
          onBlur={toggleFieldType}
          {...rest}
        />
        <label htmlFor={name} className='form_label'>
          <span className='label_content'>{label}</span>
        </label>
      </div>
      {errors && errors[name] && <div className='error'>{errors[name].message}</div>}
    </div>
  );
};

export const RadioGroup = (props: InputProps) => {
  const { name, value, validation, options, onSelect, ...rest } = props;
  const { register, errors } = useFormContext();

  const renderRadioButtons = () => {
    if (options)
      return options.map((option, index) => {
        return (
          <div className='form_radio' key={index} onClick={onSelect}>
            <input
              ref={register(validation)}
              name={name}
              type='radio'
              className='form_radio_input'
              value={option.value}
              required
              {...rest}
            />
            <div className='form_radio_label'>{option.label}</div>
          </div>
        );
      });
  };

  return (
    <>
      {renderRadioButtons()}
      {errors && errors[name] && <div className='error'>{errors[name].message}</div>}
    </>
  );
};

export const MultiEmail = (props: InputProps) => {
  const { validation, name, label } = props;
  const { email: currentUser } = useAppSelector((state) => state.auth);
  const [emails, setEmails] = useState<string[]>([]);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const isEmail = (email: string) => {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
  };

  const isValid = (email: string) => {
    let error = null;

    if (currentUser === email) {
      error = 'You will be invited by default.';
    }

    if (emails.includes(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address`;
    }

    if (error) {
      setError(error);
      return false;
    }
    return true;
  };

  const checkForSeparator = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Tab', ',', ';', ' '].includes(event.key) && value !== '') {
      event.preventDefault();
      if (value && isValid(value)) {
        setValue('');
        setEmails([...emails, value]);
      }
    }
  };

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setError('');
  };

  const deleteEmail = (email: string) => {
    setEmails(emails.filter((address) => address !== email));
  };

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {};

  // We have two input tags here:
  //  - One that the user sees and uses to provide email addresses
  //  - Another that is invisible and stores the list of emails
  //     as its read-only value

  // We handle validation/errors manually rather than let
  //  react-hook-form do it, because the input tag being validated
  //  is not registered with react-hook-form
  return (
    <div className='form_field_container'>
      <div className='form_field'>
        <input
          className='form_input'
          type='text'
          value={value}
          onChange={updateValue}
          onKeyDown={checkForSeparator}
          onPaste={onPaste}
          required
        ></input>
        <label htmlFor={name} className='form_label'>
          <span className='label_content'>{label}</span>
        </label>
        <HiddenField name={name} validation={validation} value={emails.join(';')} />
      </div>
      {error && <div className='error'>{error}</div>}
      {emails.map((email) => {
        return (
          <div key={email} className='chip'>
            {email}
            <button type='button' className='chip_delete' onClick={() => deleteEmail(email)}>
              {' '}
              &times;{' '}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export const HiddenField = (props: InputProps) => {
  const { validation, name, label, value, ...rest } = props;
  const { register } = useFormContext();
  return (
    <input
      className='hidden'
      id={name}
      name={name}
      ref={register(validation)}
      type='text'
      {...rest}
      required
      autoComplete='off'
      value={value}
      readOnly
    />
  );
};
