import { CustomEditor, ItemStatus, TranscriptElement, UtteranceElement } from '@jambr/collab-util';
import { UIEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { JmrContext } from '../../JMR/JMR';
import { positionEditor } from '../../JMR/positionUtil';
import { getElementMetaContainer } from '../../Slate/util';
import TranscriptControls from './TranscriptControls';
import {
  EditorContainerRange,
  getEditorContainerRange,
  setTranscriptViewMode,
} from './transcriptBindUtil';
import { toggleTranscript } from './transcriptToggleUtil';

export const TRANSCRIPT_WIDTH = 300;
export const EDITOR_MIN_WIDTH = 360;

interface Props {
  element: TranscriptElement;
}

const Transcript = ({ children, element }: RenderElementProps & Props) => {
  const [showTranscript, setShowTranscript] = useState(false);
  const editor = useSlateStatic();
  const { currentParticipant } = useContext(JmrContext);
  const isCurrentParticipantEmpty = Object.keys(currentParticipant).length === 0;

  const [isEditorContainerSmall, setIsEditorContainerSmall] = useState(false);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const isScrollToBottomManuallyRef = useRef<boolean>(true);

  // When the children change, if the scroll container is at the bottom before adding a new child, scroll to the bottom.
  useEffect(() => {
    if (!children || children.length <= 1) {
      return;
    }
    if (isScrollToBottomManuallyRef.current) {
      scrollContainer.current?.scrollTo(0, scrollContainer.current.scrollHeight);
    }
  }, [children]);

  const scrollHandler = useCallback((e: UIEvent<HTMLDivElement>) => {
    const element = e.target as HTMLDivElement;
    // if user scroll to 10px from the bottom, we will consider it as user is scrolling to the bottom
    const threshold = 10;
    isScrollToBottomManuallyRef.current = element.scrollTop > -threshold;
  }, []);

  useEffect(() => {
    setTranscriptViewMode(true);
    // Set transcript styles on resize events for the editorContainer and editor
    const editorContainerElement = document.getElementById('editor_container');
    const editorElement = document.getElementById('editor');
    if (!editorContainerElement || !editorElement) return;
    new ResizeObserver(() => {
      setIsEditorContainerSmall(getEditorContainerRange() === EditorContainerRange.Small);
    }).observe(editorElement);
    new ResizeObserver(() => setTranscriptViewMode(false)).observe(editorContainerElement);
    new ResizeObserver(() => setTranscriptViewMode(false)).observe(editorElement);


    editorContainerElement.setAttribute('forceFullscreen', 'false');
    positionEditor();
  }, []);

  const renderResult = (
    <div id='transcript_wrapper'>
      <div id='transcript'>
        <div
          id='transcript_toggle'
          onClick={() => {
            toggleTranscript(!showTranscript);
            setShowTranscript(!showTranscript);
          }}
          contentEditable={false}>
          <span className='material-icons mic_show_hide'>
            {showTranscript ? 'chevron_right' : 'chevron_left'}
          </span>
          <span className='material-icons'>mic</span>
        </div>
        {!isCurrentParticipantEmpty && <TranscriptControls />}
        <div id='utterance_container' ref={scrollContainer} onScroll={scrollHandler}>
          {isTranscriptEmpty(editor, element.children) ? (
            <div
              className='utterance_placeholder'
              contentEditable={false}
              style={{
                userSelect: 'none',
              }}>
              No transcript yet
              {children}
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );

  // Transcript must be rendered in the app_content div when the editor container is small, otherwise the transcript will not be visible in safari browser
  return isEditorContainerSmall
    ? createPortal(renderResult, document.getElementById('app_content')!)
    : renderResult;
};

export default Transcript;

const isTranscriptEmpty = (editor: CustomEditor, utterances: UtteranceElement[]) => {
  const validUtterances: UtteranceElement[] = utterances.filter(
    utt => utt.itemStatus !== ItemStatus.Deleted
  );
  if (validUtterances.length > 1) return false;
  const elementPath = ReactEditor.findPath(editor, validUtterances[0]);
  const [metaElement] = getElementMetaContainer(editor, elementPath);
  const { artifactMetaData: { startDate }} = metaElement;
  return startDate === '';
};
