import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useAppSelector } from '../../hooks/hooks';
import useInterval from '../../hooks/useInterval';
import { fetchCurrentUser } from '../../middlewares/userRequests';
import SubmitButton from '../Forms/SubmitButton';
import AccountTemplate from './AccountTemplate';
import { isSubscriptionActiveOrTrialing } from "../../utils/authUtil";

export default function CheckoutComplete() {
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  const { subStatus } = useAppSelector((state) => state.auth);
  const dispatch = useDispatch();

  useInterval(
    () => {
      dispatch(fetchCurrentUser());

      if (isSubscriptionActiveOrTrialing(subStatus)) {
        setLoading(false);
      }
    },
    loading ? 500 : null
  );

  return (
    <AccountTemplate>
      <h1>Thank you</h1>

      <SubmitButton loading={loading} buttonText={'Go to Jambr'} onClick={handleClick} />

      {redirect && (
        <Redirect
          to={{
            pathname: '/home',
          }}
        />
      )}
    </AccountTemplate>
  );

  function handleClick() {
    setRedirect(true);
  }
}
