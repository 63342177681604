import React, { ReactNode } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { eMessage } from '../../types/errorTypes';
import AppError from './Errors/AppError';
import JmrError from './Errors/JmrError';
import JmrMessage from './Errors/JmrMessage';

interface State {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

type Props = RouteComponentProps & {
  children: ReactNode;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
    this.clearErrorState = this.clearErrorState.bind(this);
  }

  clearErrorState() {
    this.setState({
      error: null,
      errorInfo: null,
    });
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.location !== this.props.location) {
      this.clearErrorState();
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo,
    });
  }

  render() {
    if (this.state.error && this.state.errorInfo) {
      switch (this.state.error.message) {
        case eMessage.yAbnormalClosureMessage:
        case eMessage.unauthorizedMessage:
          return (
            <JmrError
              error={this.state.error!}
              errorInfo={this.state.errorInfo}
              handler={this.clearErrorState}></JmrError>
          );
        case eMessage.yTimeoutMessage:
          return (
            <JmrMessage
              error={this.state.error!}
              errorInfo={this.state.errorInfo}
              handler={this.clearErrorState}></JmrMessage>
          );
        default:
          return <AppError error={this.state.error!} errorInfo={this.state.errorInfo}></AppError>;
      }
    }

    // Normally, just render children
    return this.props.children;
  }
}

const ErrorBoundaryWithRouter = withRouter(ErrorBoundary);

export default ErrorBoundaryWithRouter;
