import { Descendant, Editor, Range } from 'slate';
import { Slate, Editable } from 'slate-react';
import { CustomEditor, CustomElementType } from '@jambr/collab-util';
import React, { useCallback, useContext, useEffect } from 'react';
import _ from 'lodash';
import {
  ACTIONKEY,
  ARROWKEYS,
  getTextField,
  HOTKEYS,
  isBlockActive,
  stopTripleClick,
  tabUnwrap,
  tabWrap,
  toggleMark,
} from './util';
import isHotkey from 'is-hotkey';
import JmrElement, { Leaf } from './JmrElement';
import { EDITOR_NODE_LENGTH, YjsSocketContext } from '../JMR/Client';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  participantRemoveMessage,
  participantUpdateMessage,
} from '../../../utils/participantMessage';
import { setYjsParticipantRemoved, setYjsUpdateRequired } from '../../../store/participantSlice';
import Toolbar from '../components/Toolbar';
import { JmrContext } from '../JMR/JMR';
import { JmrPermissions } from '../../../types/participantTypes';
import { isRichNode } from '@jambr/collab-util';
import { insertActionItem } from '../elements/ActionItems/util';
import { positionEditor } from '../JMR/positionUtil';
import { RemoteCursorOverlay } from '../components/Overlay';

export interface CollabEditorI {
  editor: CustomEditor;
  value: Descendant[];
  onChange: (value: Descendant[]) => void;
}

const JmrEditor = ({ editor, value, onChange }: CollabEditorI) => {
  const ws = useContext(YjsSocketContext);
  const { permissions } = useContext(JmrContext);
  const { yjsUpdateRequired, yjsParticipantRemoved } = useAppSelector(
    (state) => state.participants
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (yjsUpdateRequired) {
      //send update
      ws.send(participantUpdateMessage());
      dispatch(setYjsUpdateRequired());
    }
  }, [yjsUpdateRequired, dispatch, ws]);

  useEffect(() => {
    if (yjsParticipantRemoved) {
      //send update
      ws.send(participantRemoveMessage(yjsParticipantRemoved));
      dispatch(setYjsParticipantRemoved());
    }
  }, [yjsParticipantRemoved, dispatch, ws]);

  useEffect(() => {
    // The editor moves between fullscreen and page mode as the editor window
    // grows and shrinks
    const editorContainerEl = document.getElementById('editor_container');
    if (editorContainerEl) new ResizeObserver(() => positionEditor()).observe(editorContainerEl);
  }, []);

  // This will cause the toolbar to be rendered correctly if the user is promoted
  // from viewer to editor
  useEffect(() => positionEditor(), [permissions]);

  const renderElement = useCallback((props) => <JmrElement {...props} />, []);
  const renderLeaf = useCallback(
    (props: any) => <Leaf {...props} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Slate editor={editor} value={value} onChange={onChange}>
      {value.length !== EDITOR_NODE_LENGTH ? (
        <div className='spin_loader spin_loader_middle_fixed' />
      ) : (
        <div id='editor_wrapper'>
          <RemoteCursorOverlay>
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder='Enter some text...'
              onKeyDown={(event) => handleKeyDown(event, editor)}
              onPaste={(event) => handlePaste(event, editor)}
              onClick={stopTripleClick}
              id='editor'
            />
          </RemoteCursorOverlay>
          {permissions !== JmrPermissions.READ && <Toolbar />}
        </div>
      )}
    </Slate>
  );
};

export default JmrEditor;

export const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, editor: CustomEditor) => {
  // To avoid unexpected behavior, only process key presses when there is a valid selections
  const { selection } = editor;
  if (!selection) {
    event.preventDefault();
    return;
  }

  // For now, the tab key is only used to control list depth
  if (event.key === 'Tab') {
    event.preventDefault();
    if (event.shiftKey) {
      tabUnwrap(editor);
    } else {
      tabWrap(editor);
    }
  }

  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();
      const mark = HOTKEYS[hotkey];
      toggleMark(editor, mark);
    }
  }

  if (isHotkey(ACTIONKEY, event)) {
    event.preventDefault();
    insertActionItem(editor);
  }

  // If the parent of the current selection is not a text node, don't process
  // any keypresses.
  const textParentEntry = getTextField(editor);
  if (!textParentEntry) {
    if (!ARROWKEYS.includes(event.key)) event.preventDefault();
    return;
  }

  const [textParentNode, textParentPath] = textParentEntry;
  const [start, end] = Editor.edges(editor, textParentPath);
  const { anchor, focus } = selection;

  // Do not allow backspaces at the starts of text fields
  if (
    event.key === 'Backspace' &&
    Range.isCollapsed(selection) &&
    _.isEqual(start, anchor) &&
    !isBlockActive(editor, CustomElementType.ListItem)
  ) {
    event.preventDefault();
    return;
  }

  // Do not allow deletes at the ends of text fields
  if (event.key === 'Delete' && Range.isCollapsed(selection) && _.isEqual(end, focus)) {
    event.preventDefault();
    return;
  }

  // Only allow line breaks in rich text fields
  if (event.key === 'Enter' && !isRichNode(textParentNode)) {
    event.preventDefault();
    return;
  }
};

export const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>, editor: CustomEditor) => {
  const textField = getTextField(editor);
  if (textField && textField[0].type !== CustomElementType.RichText) {
    event.preventDefault();
  }
};
