/**
 * Converts a Date object into a human-readable time string in 12-hour format.
 * @param {Date} date - The input date.
 * @returns {string} - A human-readable time string (e.g., "3:45 PM").
 */
export const convertTime = (date: Date): string => {
  const hour =
    date.getHours() > 12 ? date.getHours() - 12 : date.getHours() === 0 ? 12 : date.getHours();
  const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  return hour + ':' + minute + ' ' + ampm;
};

/**
 * Converts a Date object into a human-readable date string.
 * If the date is today, tomorrow, or yesterday, it returns a corresponding string.
 * Otherwise, it returns the date in 'en-us' locale format.
 * @param {Date} date - The input date.
 * @returns {string} - A human-readable date string.
 */
export const convertDate = (date: Date): string => {
  const today = new Date();
  const tomorrow = new Date(today);
  const yesterday = new Date(today);

  tomorrow.setDate(today.getDate() + 1);
  yesterday.setDate(today.getDate() - 1);

  if (isSameDay(date, today)) return 'Today';
  if (isSameDay(date, tomorrow)) return 'Tomorrow';
  if (isSameDay(date, yesterday)) return 'Yesterday';

  return date.toLocaleDateString('en-us');
};

/**
 * Checks if two Date objects represent the same day.
 * @param {Date} date1 - The first date to compare.
 * @param {Date} date2 - The second date to compare.
 * @returns {boolean} - True if the dates represent the same day, otherwise false.
 */
const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
  );
};
