import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  deleteParticipant,
  patchParticipant,
} from '../../../../../middlewares/participantRequests';
import {
  JmrPermissions,
  ParticipantType,
  TParticipant,
} from '../../../../../types/participantTypes';
import { JmrContext } from '../../../JMR/JMR';

interface Props {
  participant: TParticipant;
}

export const MetaParticipantRow = ({ participant }: Props) => {
  // Gives access to the JMR and the current user's participant
  const { currentParticipant, permissions } = useContext(JmrContext);

  const dispatch = useAppDispatch();
  // Allows us to render a spin loader when the user makes changes
  const { loading } = useAppSelector((state) => state.participants);
  // Allows us to only load a spin loader in this row rather than all of them
  const [localLoading, setLocalLoading] = useState(false);
  // Turns off local loading when the participant slice is done loading
  if (!loading && localLoading) {
    setLocalLoading(false);
  }
  // Allow us to change the "remove" button to "update" if the user changes
  // a participant's permissions
  const [dirty, setDirty] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      perms: participant.jmrPerms,
    },
  });

  const initPerm = participant.jmrPerms;

  // Patch the participant with the new permissions
  const onSubmit = (data: { perms: JmrPermissions }) => {
    dispatch(
      patchParticipant({
        oldParticipant: participant,
        newParticipant: {
          ...participant,
          jmrPerms: data.perms,
        },
      })
    );
    setDirty(false);
    setLocalLoading(true);
  };

  // Change state based on the new value to show the correct button (remove or update)
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    if (initPerm === value) {
      setDirty(false);
    } else {
      setDirty(true);
    }
  };

  const handleRemove = () => {
    dispatch(
      deleteParticipant({
        participant,
        deleteJmr: currentParticipant.participant === participant.participant,
      })
    );
  };

  const renderButton = () => {
    if (localLoading) {
      return (
        <div className='spin_loader_container'>
          <div className='spin_loader'></div>
        </div>
      );
    }
    if (dirty) {
      return (
        <div
          className='control_button_base control_button_confirm'
          onClick={handleSubmit(onSubmit)}>
          update
        </div>
      );
    } else if (
      participant.participantType !== ParticipantType.OWNER &&
      permissions !== JmrPermissions.READ
    ) {
      return (
        <div className='control_button_base control_button_remove' onClick={handleRemove}>
          remove
        </div>
      );
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault}>
      <div className='participant_row' key={participant.id}>
        <div className='participant_email'>
          {participant.participant === currentParticipant.participant
            ? participant.participant + ' (you)'
            : participant.participant}
        </div>
        <div className='participant_permission_selector'>
          {
            // If this user is the owner, simply show "organizer"
            participant.participantType === ParticipantType.OWNER ? (
              ParticipantType.OWNER_LONG
            ) : permissions === JmrPermissions.READ ? (
              // If the current user has read-only permissions,
              // they may not change permissions of other users.
              (() => {
                switch (participant.jmrPerms) {
                  case JmrPermissions.FULL:
                    return JmrPermissions.FULL_LONG;
                  case JmrPermissions.READ:
                    return JmrPermissions.READ_LONG;
                }
              })()
            ) : (
              // Allow the user to change permissions of participants
              // (including themselves) up to their own permission level
              <select
                onChange={handleChange}
                name='perms'
                ref={register}
                className='permission_selector'>
                <option value={JmrPermissions.FULL}>{JmrPermissions.FULL_LONG}</option>
                <option value={JmrPermissions.READ}>{JmrPermissions.READ_LONG}</option>
              </select>
            )
          }
        </div>
        {renderButton()}
      </div>
    </form>
  );
};
