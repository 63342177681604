import { MAX_WIDTH_FOR_FULLSCREEN_EDITOR, positionEditor } from '../../JMR/positionUtil';
import { EDITOR_MIN_WIDTH, TRANSCRIPT_WIDTH } from './Transcript';
import {
  toggleTranscriptLarge,
  toggleTranscriptMedium,
  toggleTranscriptSmall,
} from './transcriptToggleUtil';
import { debounceWithAnimationFrame } from '../../../Util/debounceWithAnimationFrame';

export enum EditorContainerRange {
  Small,
  Medium,
  Large,
}

export const setTranscriptViewModeRaw = (mounting: boolean) => {
  setTimeout(() => {
    const ecr = getEditorContainerRange();
    switch (ecr) {
      case EditorContainerRange.Large:
      case EditorContainerRange.Medium:
        setTranscriptBindRight(mounting, ecr);
        break;
      case EditorContainerRange.Small:
        setTranscriptBindTop(mounting);
        break;
    }
  });
};

export const setTranscriptViewMode = debounceWithAnimationFrame(setTranscriptViewModeRaw);

export const getEditorContainerRange = () => {
  // In large mode, the transcript can be expanded and the editor will still be
  // displayed in page mode.
  const largeThreshold = MAX_WIDTH_FOR_FULLSCREEN_EDITOR + TRANSCRIPT_WIDTH;
  // In medium mode, the transcript expanding will always cause the editor to transition
  // from page mode to fullsscreen mode.
  // In small mode, the editor is displayed in fullscreen mode without the transcript open,
  // and the transcript will slide down from the top. (smallThreshold is implicit)
  const mediumThreshold = EDITOR_MIN_WIDTH + TRANSCRIPT_WIDTH;
  // Determine the available editor container width
  const editorContainerElement = document.getElementById('editor_container');
  if (!editorContainerElement) return;
  const editorContainerWidth = editorContainerElement.getBoundingClientRect().width;
  if (editorContainerWidth >= largeThreshold) {
    return EditorContainerRange.Large;
  }
  if (editorContainerWidth >= mediumThreshold) {
    return EditorContainerRange.Medium;
  }
  return EditorContainerRange.Small;
};

export const setTranscriptBindRight = (isMounting: boolean, ecr: EditorContainerRange) => {
  // Find requisite elements
  const editorContainerElement = document.getElementById('editor_container');
  const editorWrapperElement = document.getElementById('editor_wrapper');
  const jmrOptionsElement = document.getElementById('jmr_options');
  const transcriptWrapperElement = document.getElementById('transcript_wrapper');
  const toggleElement = document.getElementById('transcript_toggle');
  const toolbarElement = document.getElementById('toolbar');

  if (
    !editorContainerElement ||
    !editorWrapperElement ||
    !jmrOptionsElement ||
    !transcriptWrapperElement ||
    !toggleElement
  )
    return;

  // remove conflicting styles
  transcriptWrapperElement.classList.remove('transcript_bound_top');
  transcriptWrapperElement.style.transform = 'translateY(-50%)';
  toggleElement.classList.remove('toggle_underneath', 'toggle_underneath_hidden');
  toggleElement.classList.remove('toggle_infront');
  toggleElement.classList.add('toggle_behind');
  editorContainerElement.style.marginTop = '0';
  editorContainerElement.style.height = '100vh';
  if (toolbarElement) toolbarElement.style.top = '50vh';
  // Disable override for default jmrOptions scroll behavior
  jmrOptionsElement.setAttribute('scrollOverride', 'false');
  jmrOptionsElement.style.top = '0';

  if (ecr === EditorContainerRange.Large) {
    editorContainerElement.setAttribute('forceFullscreen', 'false');
    positionEditor();
    editorContainerElement.style.direction = 'ltr';
    editorWrapperElement.style.direction = 'ltr';
    editorWrapperElement.style.width = 'clamp(360px, 100%, 840px)';
  }

  // Position the transcript
  //  If the editorContainer is scrollable, the transcript pane's height and position
  //  should be based on the window height. If the editorContainer is not scrollable,
  //  the transcript pane's height and position should be based on the editor's height.
  const editorContainerClientHeight = editorContainerElement.clientHeight;
  const editorContainerScrollHeight = editorContainerElement.scrollHeight;
  const editorWrapperRect = editorWrapperElement.getBoundingClientRect();
  if (editorContainerScrollHeight > editorContainerClientHeight) {
    // editorContainer is scrollable
    // Position the transcript vertically from the middle of the window
    const windowHeight = window.innerHeight;
    transcriptWrapperElement.style.top = windowHeight / 2 + 'px';
    // Size the transcript based on the height of the window, the
    // editorContainer's padding, and the jmrOptions' height
    const editorContainerPadding = parseInt(
      window.getComputedStyle(editorContainerElement).paddingTop
    );
    const jmrOptionsHeight = jmrOptionsElement.clientHeight;
    const transcriptHeightOffset = (editorContainerPadding + jmrOptionsHeight) * 2;
    transcriptWrapperElement.style.height = windowHeight - transcriptHeightOffset + 'px';
    transcriptWrapperElement.style.width = TRANSCRIPT_WIDTH + 'px';
  } else {
    // editorContainer is not scrollable
    // Position the transcript vertically from the middle of the editor
    const editorTopOffset = editorWrapperRect.top;
    const editorHeight = editorWrapperElement.clientHeight;
    const editorMiddle = editorTopOffset + editorHeight / 2;
    transcriptWrapperElement.style.top = editorMiddle + 'px';
    // Size the transcript based on the height of the editor and jmrOptions
    const jmrOptionsHeight = jmrOptionsElement.clientHeight;
    const transcriptHeight = editorHeight - 2 * jmrOptionsHeight;
    transcriptWrapperElement.style.height = transcriptHeight + 'px';
    transcriptWrapperElement.style.width = TRANSCRIPT_WIDTH + 'px';
  }

  const isTranscriptExpanded = transcriptWrapperElement.getAttribute('expanded') === 'true';

  if (isMounting || !isTranscriptExpanded) {
    const editorRight = editorWrapperRect.right;
    const editorRightOffset = window.innerWidth - editorRight;
    transcriptWrapperElement.style.right = editorRightOffset + 'px';
    transcriptWrapperElement.setAttribute('expanded', 'false');
    editorContainerElement.setAttribute('forceFullscreen', 'false');
    editorContainerElement.style.direction = 'ltr';
    editorWrapperElement.style.direction = 'ltr';
  } else if (isTranscriptExpanded && ecr === EditorContainerRange.Large) {
    toggleTranscriptLarge(isTranscriptExpanded);
  } else if (isTranscriptExpanded && ecr === EditorContainerRange.Medium) {
    toggleTranscriptMedium(isTranscriptExpanded);
  }
  // apply large classes to transcript
};

export const setTranscriptBindTop = (mounting: boolean) => {
  const toggleElement = document.getElementById('transcript_toggle');
  const transcriptWrapperElement = document.getElementById('transcript_wrapper');
  const editorContainerElement = document.getElementById('editor_container');
  const editorWrapperElement = document.getElementById('editor_wrapper');
  if (
    !toggleElement ||
    !transcriptWrapperElement ||
    !editorContainerElement ||
    !editorWrapperElement
  )
    return;

  if (mounting) {
    transcriptWrapperElement.setAttribute('expanded', 'false');
  }

  // remove conflicting styles
  editorWrapperElement.style.marginRight = '0';
  editorWrapperElement.style.width = '100%';
  editorWrapperElement.style.direction = 'ltr';
  editorContainerElement.style.direction = 'ltr';
  editorContainerElement.setAttribute('forceFullscreen', 'false');

  const editorContainerWidth = editorContainerElement.getBoundingClientRect().width;

  toggleElement.classList.remove('toggle_infront', 'toggle_behind');
  toggleElement.classList.add('toggle_underneath');
  transcriptWrapperElement.classList.add('transcript_bound_top');
  transcriptWrapperElement.style.width = editorContainerWidth + 'px';
  transcriptWrapperElement.style.height = window.innerHeight + 'px';

  const isTranscriptExpanded = transcriptWrapperElement.getAttribute('expanded') === 'true';

  toggleTranscriptSmall(isTranscriptExpanded);
};
