export const STAGE = process.env.REACT_APP_STAGE || 'dev';
export const REGION = process.env.REACT_APP_REGION || 'us-east-1';
export const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID || 'us-east-1_cuBYqqVkm';
export const USER_POOL_WEB_CLIENT_ID =
	process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || 'jiqvvvosi7f6c03sqmmvld0f';
export const API_GATEWAY_ID = process.env.REACT_APP_API_GATEWAY_ID || 'dmf8i9v1hj';
export const STRIPE_PK =
	process.env.REACT_APP_STRIPE_PK ||
	'pk_test_51OeJ7kKxDoGbWbbekkQknjNk1BMtcMzcU3WaBT1g1YBRsyb93r2izbZgDDI1oxIuLt0vDHCdAHfJIraLyFfVW5UK00GuxNRMHv';
export const STRIPE_SUB_URL =
	process.env.REACT_APP_STRIPE_SUB_URL ||
	'https://billing.stripe.com/p/login/test_eVa2aO5Eh0LZ0Le3cc';

export const WEBSOCKET_ENDPOINT = `wss://yjs.${STAGE}.jambr.app`;
export const TRANSCRIPT_ENDPOINT = `wss://transcript.${STAGE}.jambr.app`;
export const STRIPE_PAYMENT_LINK = process.env.REACT_APP_STRIPE_PAYMENT_LINK || 
'https://buy.stripe.com/test_28og0I5Dab6IeUU7ss'