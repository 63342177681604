import { Auth } from '@aws-amplify/auth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { setAuthError } from '../../store/authSlice';
import PreviewList from './Previews/PreviewList';
// import Search from '../Search/Search';
import { postJmrItems } from '../../middlewares/jmrRequests';
import OutsideClickDetector from '../Util/OutsideClickDetector';
import { clearPostedId } from '../../store/participantSlice';
import Tooltip from '../Util/Tooltip';
import { fetchCurrentUser } from '../../middlewares/userRequests';
import { STRIPE_SUB_URL, STRIPE_PAYMENT_LINK } from '../../utils/constants';
import { isSubscriptionActiveOrTrialing } from "../../utils/authUtil";

export const MAX_WIDTH_FOR_OVERLAPPING_NAV = 685;
export const NAV_BAR_WIDTH = 315;

const NavBar = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const baseUrl = url.split('/')[0] + '/home';
  const dispatch = useAppDispatch();
  const { loading: previewsLoading, postedId } = useAppSelector((state) => state.previews);
  const {
    loading: authLoading,
    fname,
    subStatus,
    lname,
    email,
  } = useAppSelector((state) => state.auth);

  const [showNav, setShowNav] = useState(
    window.innerWidth > MAX_WIDTH_FOR_OVERLAPPING_NAV
  );
  const [showSettings, setShowSettings] = useState(false);

  const { jmrButtonClasses, jmrCreateTooltip } = useMemo(() => {
    const jmrButtonClasses = ['nav_button', 'nav_jmr_button'];

    if (!isSubscriptionActiveOrTrialing(subStatus)) {
      jmrButtonClasses.push('nav_button_disabled');
    }

    const jmrCreateTooltip =
        !isSubscriptionActiveOrTrialing(subStatus) ? 'cannot create meetings (unsubscribed)' : 'create meeting';

    return {
      jmrButtonClasses: jmrButtonClasses.join(' '),
      jmrCreateTooltip,
    };
  }, [subStatus]);

  const closeNav = useCallback(() => {
    if (window.innerWidth <= MAX_WIDTH_FOR_OVERLAPPING_NAV) setShowNav(false);
  }, []);

  useEffect(() => {
    if (postedId) {
      history.push(`/home/jmr/${postedId}`);
      dispatch(clearPostedId());
    }
  }, [postedId, history, dispatch]);

  useEffect(() => {
    toggleNav();
    window.addEventListener('resize', () => toggleNav());
    return () => window.removeEventListener('resize', () => toggleNav());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNav]);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  const toggleNav = () => {
    const navBarElement = document.getElementById('nav_bar');
    const appContentElement = document.getElementById('app_content');
    const greyWallClass = 'nav_grey_wall';
    if (!navBarElement) return;
    if (showNav) {
      // Show the nav bar
      navBarElement.style.left = '0';

      // If the window is narrow, darken the content pane
      const narrowWindow = window.innerWidth <= MAX_WIDTH_FOR_OVERLAPPING_NAV;
      if (narrowWindow) {
        navBarElement.classList.add(greyWallClass);
      } else {
        navBarElement.classList.remove(greyWallClass);
      }

      // Either squish the content pane to make room for the nav bar, or keep it
      // where it is and have the nav bar slide over it
      if (appContentElement)
        appContentElement.style.marginLeft = narrowWindow ? '0' : NAV_BAR_WIDTH + 'px';
    } else {
      // hide the nav bar and un-squish the content pane
      navBarElement.style.left = '-' + NAV_BAR_WIDTH + 'px';
      navBarElement.classList.remove(greyWallClass);
      if (appContentElement) appContentElement.style.marginLeft = '0';
    }
  };

  const handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        history.push('/login');
        dispatch({
          type: 'CLEAR_APP_STATE',
        });
      })
      .catch((error) => {
        dispatch(setAuthError(error));
      });
  };

  const handleCreate = () => {
    if (!isSubscriptionActiveOrTrialing(subStatus)) {
      window.location.href = `${STRIPE_PAYMENT_LINK}?prefilled_email=${email}`;
      return;
    }

    dispatch(postJmrItems());
    if (window.innerWidth <= MAX_WIDTH_FOR_OVERLAPPING_NAV) setShowNav(false);
  };

  const handleOutsideClick = () => {
    if (window.innerWidth <= MAX_WIDTH_FOR_OVERLAPPING_NAV && showNav) setShowNav(false);
  };

  return (
    <OutsideClickDetector callback={handleOutsideClick}>
      <div id='nav_bar'>
        <div className='show_hide_nav' onClick={() => setShowNav(!showNav)}>
          <span className='material-icons show_hide_icon'>
            {showNav ? 'chevron_right' : 'chevron_left'}
          </span>
        </div>
        <div className='nav_elements'>
          <div
            className={
              showSettings
                ? 'nav_button nav_account_button nav_button_active'
                : 'nav_button nav_account_button'
            }
            onClick={() => setShowSettings(true)}>
            <span className={showSettings ? 'hidden' : 'nav_initial'}>{fname.substring(0, 1)}</span>
            <span
              className={
                showSettings
                  ? 'material-icons nav_settings block'
                  : 'material-icons nav_settings hidden'
              }>
              settings
            </span>
            <Tooltip showInstantly tooltip='account' />
            {showSettings && (
              <OutsideClickDetector
                callback={() => setShowSettings(false)}
                className='signout_modal'>
                <span className='tooltip_name'>{`${fname} ${lname}`}</span>
                <span>{email}</span>
                <div className='signout_button_wrapper'>
                  <button
                    className='meta_base meta_small meta_colors_active meta_hover signout_button'
                    onClick={handleSignOut}>
                    sign out
                  </button>
                  {isSubscriptionActiveOrTrialing(subStatus) && (
                    <a
                      href={STRIPE_SUB_URL}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='meta_base meta_small meta_colors_active meta_hover signout_button'>
                      manage subscription
                    </a>
                  )}
                </div>
              </OutsideClickDetector>
            )}
          </div>
          <div className='nav_button nav_feedback_button'>
            <a
              href='https://jambr.freshdesk.com/support/home'
              target='_blank'
              rel='noopener noreferrer'>
              <span className='material-icons nav_icon'>question_mark</span>
              <Tooltip tooltip='submit feedback and get support' showInstantly />
            </a>
          </div>
          <NavLink
            to={`${baseUrl}/actions`}
            className='nav_button nav_action_button'
            activeClassName='nav_action_button_active'>
            <span className='material-icons nav_icon'>checklist</span>
            <Tooltip tooltip='see all action items' showInstantly />
          </NavLink>
          {previewsLoading || authLoading ? (
            <div className='spin_loader nav_loader' />
          ) : (
            <div className={jmrButtonClasses} onClick={handleCreate} id='nav_plan'>
              <span className='material-icons nav_icon'>post_add</span>
              <Tooltip tooltip={jmrCreateTooltip} showInstantly />
            </div>
          )}
          {/*<Search />*/}
        </div>
        <PreviewList closeNav={closeNav} />
      </div>
    </OutsideClickDetector>
  );
};

export default NavBar;