import { useContext, useEffect, useState } from 'react';
import { YjsSocketContext } from '../Client';
import OutsideClickDetector from '../../../Util/OutsideClickDetector';
import { useAppSelector } from '../../../../hooks/hooks';
import { JmrPermissions } from '../../../../types/participantTypes';
import { JmrContext } from '../JMR';
import { sendShareIdControl } from '../../../../utils/y-websocket';

export enum ShareMessageType {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

const JmrShareModal = () => {
  const ws = useContext(YjsSocketContext);
  const { jmrId } = useContext(JmrContext);
  const [showControls, setShowControls] = useState(false);
  const { shareId, sharePerms, shareLoading } = useAppSelector((state) => state.share);
  const [formSharePerms, setFormSharePerms] = useState(
    sharePerms ? sharePerms : JmrPermissions.READ
  );

  // We include a counter on the copy alert so that it will restart the animation if the
  // user presses the copy button multiple times. Otherwise, there won't be a rerender
  // because the text itself doesn't change after successive copies.
  const [copyAlert, setCopyAlert] = useState({ copyAlertCount: 0, copyAlertText: '' });
  const { copyAlertCount, copyAlertText } = copyAlert;

  const sendShareMessage = (type: ShareMessageType) => {
    switch (type) {
      case ShareMessageType.CREATE:
      case ShareMessageType.UPDATE: {
        sendShareIdControl(ws, type, formSharePerms);
        break;
      }
      case ShareMessageType.DELETE: {
        sendShareIdControl(ws, type);
        setFormSharePerms(JmrPermissions.READ);
        break;
      }
    }
  };

  useEffect(() => {
    const alertElement = document.getElementById('share_link_copy_alert');
    if (!alertElement) return;
    alertElement.classList.add('share_link_copy_animation');
  }, [copyAlert]);

  const handleCopy = () => {
    const alertElement = document.getElementById('share_link_copy_alert');
    if (!alertElement) return;
    alertElement.classList.remove('share_link_copy_animation');
    navigator.clipboard
      .writeText(`${window.location.origin}/share/${jmrId}/${shareId}`)
      .then((r) =>
        setCopyAlert({
          copyAlertCount: copyAlertCount + 1,
          copyAlertText: 'link copied to clipboard',
        })
      )
      .catch((e) =>
        setCopyAlert({
          copyAlertCount: copyAlertCount + 1,
          copyAlertText: 'there was a problem copying the link',
        })
      );
  };

  const renderModalContent = () => {
    if (!shareId)
      return (
        <>
          <span className='jmr_share_title'>Create a shareable link</span>
          <div className='jmr_share_dialogue'>
            <span>Anyone with this link can</span>
            <span>
              <select
                onChange={(e) => setFormSharePerms(e.target.value as JmrPermissions)}
                value={formSharePerms}
                className='permission_selector'>
                <option value={JmrPermissions.MODIFY}>edit</option>
                <option value={JmrPermissions.READ}>view</option>
              </select>
            </span>
            {shareLoading ? (
              <div className='spin_loader' />
            ) : (
              <div
                className='control_button_base control_button_confirm control_button_share'
                onClick={() => sendShareMessage(ShareMessageType.CREATE)}>
                create
              </div>
            )}
          </div>
        </>
      );
    return (
      <>
        <span className='jmr_share_title'>
          Revoke or change access level of your shareable link
        </span>
        <div className='jmr_share_dialogue'>
          <span>Anyone with this link can</span>
          <span>
            <select
              onChange={(e) => {
                setFormSharePerms(e.target.value as JmrPermissions);
              }}
              value={formSharePerms}
              className='permission_selector'>
              <option value={JmrPermissions.MODIFY}>edit</option>
              <option value={JmrPermissions.READ}>view</option>
            </select>
          </span>
          {shareLoading ? (
            <div className='spin_loader' />
          ) : formSharePerms === sharePerms ? (
            <div
              className='control_button_base control_button_remove control_button_share'
              onClick={() => sendShareMessage(ShareMessageType.DELETE)}>
              revoke
            </div>
          ) : (
            <div
              className='control_button_base control_button_confirm control_button_share'
              onClick={() => sendShareMessage(ShareMessageType.UPDATE)}>
              update
            </div>
          )}
        </div>
        <div className='share_link'>
          <input
            type='text'
            value={`${window.location.origin}/share/${jmrId}/${shareId}`}
            className='share_link_input'
            readOnly
          />
          <div className='share_link_copy_container'>
            <span className='material-icons share_link_copy' onClick={() => handleCopy()}>
              content_copy
            </span>
            <div id='share_link_copy_alert'>{copyAlertText}</div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <span
        className={
          showControls ? 'material-icons garbage_can opacity_full' : 'material-icons garbage_can'
        }
        onClick={() => setShowControls(!showControls)}>
        share
      </span>
      {showControls && (
        <OutsideClickDetector callback={() => setShowControls(false)}>
          <div className='jmr_modal jmr_share_modal'>
            <div className='jmr_modal_pointer jmr_share_modal_pointer' />
            {renderModalContent()}
          </div>
        </OutsideClickDetector>
      )}
    </>
  );
};

export default JmrShareModal;
